<template>
  <div class="grow">
    <platform-modal
      :value="confirmReleaseModal"
      icon="document"
      :title="$t('page.viewSurvey.release.releaseQuestionnaire')"
      :width="600"
      :loading="releasing || dispatching || initialising"
      :confirm-disabled="releasing || dispatching || initialising"
      @confirm="release"
      @close="confirmReleaseModal = false"
    >
      <h2 v-if="questionnaire" class="primary-header sans-serif text-center mb-3">{{ questionnaire.name }}</h2>
      <v-row justify="center">
        <v-col :cols="10" :sm="6">
          <platform-entity-card
            entity-type="company"
            align="start"
            row
            flat
            compact
            :avatar-size="56"
            :entity="selectedCompany"
          />
        </v-col>
      </v-row>

      <p class="mb-8">
        <template v-if="hasMultipleVariants">
          {{ $t('page.questionnaires.release.confirmReleaseModal.headerVariants') }}
        </template>
        <template v-else>
          {{ $t('page.questionnaires.release.confirmReleaseModal.header') }}
        </template>
      </p>
      <platform-entity-card
        v-for="({ individual, variantName, variantCode, variantLanguage }, index) in selectedConfigurationsData"
        :key="`${individual.id}-${index}`"
        entity-type="individual"
        align="start"
        row
        flat
        compact
        :entity="individual"
      >
        <template v-if="hasMultipleVariants && variantName && variantCode && variantLanguage">
          <span class="text-body-2 grey--text text--darken-1">
            <span>
              {{ variantLanguage }}
              (<strong>{{ variantCode }}</strong>)
            </span>
            <span>
              - {{ variantName }}
            </span>
          </span>
        </template>
      </platform-entity-card>
    </platform-modal>
    <platform-modal
      :value="releasedModal"
      icon="check-outline"
      close-button
      :title="$t('page.questionnaires.release.releasedModal.title')"
      :width="600"
      :cancel="false"
      @close="releasedModal = false"
    >
      <h2 v-if="questionnaire" class="primary-header sans-serif text-center mb-3">{{ questionnaire.name }}</h2>
      <v-row justify="center">
        <v-col :cols="10" :sm="6">
          <platform-entity-card
            entity-type="company"
            align="start"
            row
            flat
            compact
            :avatar-size="56"
            :entity="selectedCompany"
          />
        </v-col>
      </v-row>
      <platform-entity-card
        v-for="({ individual, variantName, variantCode, variantLanguage }, index) in selectedConfigurationsData"
        :key="`${individual.id}-${index}`"
        entity-type="individual"
        align="start"
        row
        flat
        compact
        :avatar-size="48"
        :entity="individual"
      >
        <template v-if="hasMultipleVariants && variantName && variantCode && variantLanguage">
            <span class="text-body-2 grey--text text--darken-1">
              <span>
                {{ variantLanguage }}
                (<strong>{{ variantCode }}</strong>)
              </span>
              <span>
                - {{ variantName }}
              </span>
            </span>
        </template>
      </platform-entity-card>
      <template slot="actions">
        <platform-button
          primary
          icon="return"
          :to="{ name: 'QuestionnaireList' }"
        >
          {{ $t('page.questionnaires.release.releasedModal.returnAction') }}
        </platform-button>
      </template>
    </platform-modal>
    <v-row no-gutters class="flex-column fill-height">
      <v-col class="grow flex-basis-0 overflow-y-auto">
        <v-container class="pt-4">
          <v-row align="start" no-gutters>
            <v-card class="grow px-2 pt-2 pb-2">
              <v-card-text class="px-4 mb-4 text-center text-body-1">
                <template v-if="hasMultipleVariants">
                  {{ $t('page.questionnaires.release.instructionsVariants') }}
                </template>
                <template v-else>
                  {{ $t('page.questionnaires.release.instructions') }}
                </template>
              </v-card-text>
              <v-row justify="center" class="px-2 pb-8">
                <platform-spinner v-if="loading" class="mt-3 mx-auto" />
                <template v-else>
                  <v-col :cols="12" class="text-center">
                    <v-row>
                      <v-col :cols="hasMultipleVariants ? 6 : 12" :md="2"  class="text-center">
                        <div class="mb-2 text-body-2 grey--text text--darken-1 text-no-wrap">
                          {{ $t('page.questionnaires.release.selectAllHeader') }}
                        </div>
                        <v-checkbox
                          :value="allSelected"
                          hide-details
                          class="mx-auto mt-0 d-inline-block"
                          @click="toggleSelectAllIndividuals"
                        />
                      </v-col>
                      <v-col v-if="hasMultipleVariants" :cols="6" :md="2" class="text-center">
                        <div class="mb-2 text-body-2 grey--text text--darken-1 text-no-wrap">
                          {{ $t('page.questionnaires.release.updateAllHeader') }}
                        </div>
                        <variant-picker
                          stateless
                          entity-type="questionnaire"
                          :tooltip="false"
                          :variants="availableVariants"
                          @input="(val) => updateAllIndividualConfigurations(val)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-for="(config, index) in individualConfigurations"
                    :key="config.individual.id"
                    :cols="12"
                  >
                    <v-row
                      justify="start"
                      align="center"
                      class="flex-md-nowrap"
                      :no-gutters="$vuetify.breakpoint.smAndDown"
                      :class="{
                        'mt-4': $vuetify.breakpoint.smAndDown
                      }"
                    >
                      <v-col :cols="2" :order="1" :order-md="1" class="text-center">
                        <v-checkbox
                          hide-details
                          class="mx-auto mt-0 d-inline-block"
                          :value="config.selected"
                          :disabled="releasing || !!config.dispatchedAt"
                          @click="toggleIndividualSelected(config.individual.id)"
                        />
                      </v-col>
                      <v-col v-if="hasMultipleVariants" :cols="12" :sm="7" :md="2" :order="3" :order-md="2" class="text-center">
                        <variant-picker
                          entity-type="questionnaire"
                          class="mt-2"
                          :value="config.variantCode"
                          :variants="availableVariants"
                          :disabled="!individualConfigurations[index].selected || !!individualConfigurations[index].releasedAt || !!individualConfigurations[index].dispatchedAt"
                          @input="(variantCode) => setIndividualVariant(config.individual.id, variantCode)"
                        />
                      </v-col>
                      <v-col
                        class="cursor-pointer"
                        v-ripple
                        :cols="10"
                        :md="anyReleased ? 5 : 8"
                        :order="2"
                        :order-md="3"
                        @click="toggleIndividualSelected(config.individual.id)"
                      >
                        <platform-entity-card
                          row
                          compact
                          flat
                          align="start"
                          entity-type="individual"
                          :inner-margin="0"
                          :entity="config.individual"
                        >
                          <template #default>
                            <span
                              v-tooltip:bottom="config.individual.email"
                              class="text-body-2 grey--text text--darken-1 overflow-ellipses text-no-wrap"
                            >
                              {{ config.individual.email }}
                            </span>
                          </template>
                        </platform-entity-card>
                      </v-col>
                      <v-col :cols="12" :sm="5" :md="2" :order="4" class="text-center">
                        <div v-if="config.releasedAt && config.variantCode" class="text-body-2 grey--text text--darken-1">
                          <span>{{ $t(`global.languages.${config.variantCode}`) }} </span>
                          <strong>({{ config.variantCode.toUpperCase() }}) </strong>
                        </div>
                        <template v-if="config.releasedAt">
                          <span class="text-caption grey--text text--darken-1 text-no-wrap">
                            {{ $t('page.questionnaires.release.releasedAtHeader') }}
                          </span>
                          <span class="d-block text-body-2 grey--text text--darken-2">
                            {{ config.releasedAt }}
                          </span>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-card>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="shrink">
        <v-toolbar
          class="grey lighten-5"
          bottom
          :style="{
            borderTop: '1px solid var(--v-grey-lighten1)'
          }"
          :height="60"
        >
          <v-row no-gutters justify="space-between">
            <v-col class="grow">
              <platform-button
                secondary
                :to="{ name: 'QuestionnaireList' }"
              >
                {{ $t('page.questionnaires.release.returnAction') }}
              </platform-button>
            </v-col>

            <v-col class="shrink">
              <platform-button
                primary
                icon="document"
                :loading="releasing || dispatching || initialising"
                :disabled="!isValid || releasing || dispatching || initialising || !canRelease"
                @click="confirmReleaseModal = true"
              >
                {{ $t('page.questionnaires.release.releaseAction') }}
              </platform-button>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { CompanyIndividual, Individual, PackageItemTypes, Questionnaire, QuestionnaireStatus } from '@/API'
import { ShortDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformModal from '@/components/PlatformModal.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import VariantPicker from '@/components/shared/VariantPicker.vue'
import { getQuestionnaireObject } from '@/graphql/custom/getQuestionnaireObject'
import { getCompanyPackageItems } from '@/helpers/packages'
import { getSurveyGroup } from '@/helpers/surveyGroup'
import { PlatformPackageItem, PlatformQuestionnaireVariant, PlatformSurvey, QuestionnaireVariantCode } from '@/types/Platform'
import { SurveyConfiguration } from '@/types/Survey'
import { PlatformSurveyGroup } from '@/types/SurveyGroup'
import API from '@/services/API'
import dayjs from 'dayjs'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { getPreferredName } from '@betterboards/shared/helpers/entities/Individual/getPreferredName'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformTeamIndividual } from '@betterboards/shared/types/Team'
import { createReportGroup } from '@/helpers/reportGroup'

/** Config for a given Individual, includes data from their Survey if already released **/
interface IndividualConfiguration {
  individual: Individual
  variantCode: QuestionnaireVariantCode
  selected: boolean
  surveyId?: string
  releasedAt?: string
  dispatchedAt?: string
}

/** Config to Release a Survey to an Individual **/
interface ReleaseConfiguration {
  variantCode: QuestionnaireVariantCode
  individualId: string
}

interface ReleaseSurveyResponse {
  surveyGroupId: string
}

export default Vue.extend({
  name: 'ReleaseQuestionnaire',
  components: {
    VariantPicker,
    PlatformSpinner,
    PlatformEntityCard,
    PlatformModal
  },
  data () {
    return {
      questionnaire: null as Questionnaire | null,
      availableIndividuals: [] as any[],
      confirmReleaseModal: false,
      releasedModal: false,
      loading: false,
      releasing: false,
      dispatching: false,
      allDispatched: false,
      initialising: false,
      surveyGroupId: <string | undefined>undefined,
      surveyGroup: <PlatformSurveyGroup | null>null,
      surveyConfiguration: <SurveyConfiguration | null>null,
      selectedReleaseConfigurations: <ReleaseConfiguration[]>[]
    }
  },
  mounted () {
    this.init()
    this.fetchSurveys()
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    surveys (): PlatformSurvey[] | undefined {
      return this.surveyGroup?.surveys.items
    },
    defaultVariant (): QuestionnaireVariantCode {
      return this.questionnaire?.variants?.[0]?.variantCode as QuestionnaireVariantCode
    },
    individualConfigurations (): IndividualConfiguration[] {
      const companyHasTeams: boolean = this.availableIndividuals.some(
        (individual: PlatformIndividual) => !!individual.teams?.items?.length
      )
      const targetIndividualIds = this.surveyGroup?.targetIndividualIds
      const filteredIndividuals = this.availableIndividuals.filter((individual: PlatformIndividual) => {
        if (targetIndividualIds) {
          return targetIndividualIds.includes(individual.id)
        }
        const targetTeams = this.surveyConfiguration?.targetTeams
        if (targetTeams && companyHasTeams) {
          return targetTeams.some(
            (team) => individual.teams?.items.some(
              (ti: PlatformTeamIndividual) => ti.teamId === team
            )
          )
        }
        return true
      })

      return filteredIndividuals.map<IndividualConfiguration>((individual) => {
        const survey = this.surveys ? this.surveys.find((s) => s.individualId === individual.id) : undefined
        const releaseConfig = this.getIndividualReleaseConfiguration(individual.id)
        const selected = !!releaseConfig
        console.info('individual', individual, survey, releaseConfig)
        return {
          variantCode: releaseConfig?.variantCode ?? this.defaultVariant,
          surveyId: survey?.id,
          releasedAt: survey?.createdAt ? dayjs(survey.createdAt).format(ShortDateTimeDisplayFormat) : undefined,
          dispatchedAt: survey?.dispatchedAt ? dayjs(survey.dispatchedAt).format(ShortDateTimeDisplayFormat) : undefined,
          individual,
          selected
        } as IndividualConfiguration
      })
    },
    selectedIndividualConfigurations (): IndividualConfiguration[] {
      return this.individualConfigurations
        .filter((c) => this.isIndividualSelected(c.individual.id))
    },
    selectedConfigurationsData (): any[] {
      return this.selectedIndividualConfigurations.map((config) => {
        const variantCode = config.variantCode
        const variant = this.availableVariants.find((v) => v.variantCode === variantCode)
        return {
          individual: config.individual,
          variantCode: variantCode?.toUpperCase(),
          variantName: variant?.name,
          variantLanguage: variantCode ? this.$t(`global.languages.${variantCode}`) : undefined
        }
      })
    },
    isValid (): boolean {
      if (!this.selectedIndividualConfigurations.length) {
        return false
      }
      if (!this.hasMultipleVariants) {
        return true
      }
      return this.selectedIndividualConfigurations.every((c) => !!c.variantCode)
    },
    canRelease (): boolean {
      return this.selectedIndividualConfigurations.every((c) => !c.releasedAt && !c.dispatchedAt)
    },
    canDispatch (): boolean {
      return this.selectedIndividualConfigurations.every((c) => !!c.releasedAt && !c.dispatchedAt)
    },
    availableVariants (): PlatformQuestionnaireVariant[] {
      const questionnaire = this.questionnaire
      if (!questionnaire?.variants?.length) {
        return []
      }
      return questionnaire.variants as PlatformQuestionnaireVariant[]
    },
    hasMultipleVariants (): boolean {
      if (!this.questionnaire?.variants) {
        return false
      }
      return this.questionnaire.variants.length > 1
    },
    allSelected (): boolean {
      return this.individualConfigurations.every((c) => c.selected || !!c.dispatchedAt)
    },
    anyReleased (): boolean {
      return !!this.surveys?.length
    },
    undispatchedSurveys (): string[] {
      if (!this.surveys) {
        return []
      }
      return this.surveys
        .filter((s) => s.id && !s.dispatchedAt)
        .map(({ id }) => id!)
    },
    targetSurveyGroupId (): string | undefined {
      return this.surveyGroupId ?? this.$route.params.surveyGroupId
    }
  },
  methods: {
    ...mapActions('Survey', ['fetchReleases']),
    getPreferredName,
    async init () {
      this.loading = true
      try {
        const questionnaireId = this.$route.params.id
        const questionnaireResp = await API.graphql({
          query: getQuestionnaireObject,
          variables: {
            questionnaireId
          }
        })
        const questionnaire = questionnaireResp.data.getQuestionnaire as Questionnaire
        this.questionnaire = questionnaire
        if (!this.questionnaire) {
          console.error('Failed to fetch questionnaire.')
          return
        }
        if (this.questionnaire.status === QuestionnaireStatus.NEW) {
          await this.fetchSurveyConfiguration()
        }
        if (this.questionnaire.company?.individuals?.items) {
          const individuals: CompanyIndividual[] = this.questionnaire.company.individuals.items.filter(
            (companyIndividual: CompanyIndividual | null) => !!companyIndividual?.individual && !companyIndividual.individual.deletedAt
          ) as CompanyIndividual[]
          this.availableIndividuals = individuals
            .map((companyIndividual: CompanyIndividual) => ({
              ...companyIndividual.individual,
              id: companyIndividual.individualId,
              companyId: questionnaire.companyId
            }))
        }
      } catch (e) {
        console.error('unable to fetch questionnaire', e)
        this.$toasted.error(this.$t('page.questionnaires.release.fetchError') as string)
      } finally {
        this.loading = false
      }
    },
    async release (): Promise<void> {
      if (!this.questionnaire) {
        this.$toasted.error(this.$t('page.questionnaires.release.releaseError') as string)
        console.error('Questionnaire not set, failed to release.')
        return
      }
      this.releasing = true
      try {
        const releaseResponse: ReleaseSurveyResponse = await API.post(
          'backendfunctions',
          `/questionnaire/${this.questionnaire.id}/release`,
          {
            body: {
              action: 'release',
              recipients: this.selectedIndividualConfigurations
                .map(({ individual, variantCode }) => ({
                  individualId: individual.id,
                  variantCode
                }))
            }
          }
        )
        if (!releaseResponse?.surveyGroupId) {
          void this.handleError()
          console.error('Failed to get released SurveyGroup ID:', { questionnaire: this.questionnaire, releaseResponse })
          throw new Error('Failed to get ID of released SurveyGroup.')
        }
        this.surveyGroupId = releaseResponse.surveyGroupId
        await this.fetchSurveys()
        await this.dispatch()
        await this.initialiseReportGroup()
      } catch (err) {
        this.confirmReleaseModal = false
        this.handleError()
        console.error('Failed to release questionnaire:', { questionnaire: this.questionnaire, err })
        throw err
      } finally {
        this.releasing = false
      }
    },
    async dispatch (): Promise<void> {
      if (!this.questionnaire || !this.undispatchedSurveys.length) {
        this.$toasted.error(this.$t('page.questionnaires.release.dispatchError') as string)
        console.error('Questionnaire not set, failed to dispatch.')
        return
      }
      this.dispatching = true
      let response: { success: boolean, dispatchComplete: boolean } | undefined
      try {
        response = await API.post('backendfunctions', `/questionnaire/${this.questionnaire.id}/release`, {
          body: {
            action: 'dispatch',
            surveys: this.undispatchedSurveys
          }
        })
      } catch (err) {
        this.$toasted.error(this.$t('page.questionnaires.release.dispatchError') as string)
        console.error('Failed to dispatch questionnaire:', { questionnaire: this.questionnaire, err })
        throw err
      } finally {
        this.dispatching = false
        await this.fetchSurveys()
        this.allDispatched = !!response?.dispatchComplete
      }
    },
    async initialiseReportGroup (): Promise<void> {
      if (!this.surveyGroup) {
        this.$toasted.error(this.$t('page.questionnaires.release.initialiseError') as string)
        console.error('Survey Group not set, failed to initialise Report Group.')
        return
      }
      if (!this.allDispatched) {
        console.debug('Not all Surveys have been dispatched, skipping Report Group initialisation.')
        this.releasedModal = true
        this.confirmReleaseModal = false
        return
      }
      this.initialising = true
      try {
        await createReportGroup(this.surveyGroup.id, this.surveyGroup.name, this.surveyGroup.variants)
        this.releasedModal = true
      } catch (err) {
        this.$toasted.error(this.$t('page.questionnaires.release.initialiseError') as string)
        console.error('Failed to initialise Report Group:', { surveyGroup: this.surveyGroup, err })
        throw err
      } finally {
        this.initialising = false
        this.confirmReleaseModal = false
      }
    },
    async fetchSurveys (): Promise<void> {
      const surveyGroupId = this.targetSurveyGroupId
      const questionnaireId = this.$route.params.id
      const surveyGroup = await getSurveyGroup(questionnaireId, surveyGroupId)
      if (!surveyGroup) {
        return
      }
      this.surveyGroup = surveyGroup
      if (!surveyGroup.surveys.items) {
        this.$toasted.error(this.$t('page.questionnaires.release.fetchError') as string)
        console.error('No Surveys found for SurveyGroup:', { surveyGroup })
      }
    },
    async fetchSurveyConfiguration (): Promise<void> {
      const companyId = this.questionnaire?.companyId ?? this.selectedCompany.id
      const packageId = this.questionnaire?.packageId
      if (!packageId || !companyId) {
        return
      }
      const companyPackage = await getCompanyPackageItems(
        companyId,
        packageId,
        PackageItemTypes.SurveyConfiguration
      )
      const packageItems = companyPackage.package.packageItems?.items
      if (!packageItems) {
        return
      }
      const surveyConfiguration = packageItems.find<PlatformPackageItem<SurveyConfiguration | null>>(
        (pi: PlatformPackageItem): pi is PlatformPackageItem<SurveyConfiguration> => pi.type === PackageItemTypes.SurveyConfiguration
      )
      if (!surveyConfiguration?.content) {
        return
      }
      this.surveyConfiguration = surveyConfiguration.content
    },
    getIndividualReleaseConfiguration (individualId: string): ReleaseConfiguration | undefined {
      return this.selectedReleaseConfigurations.find((i) => i.individualId === individualId)
    },
    isIndividualSelected (individualId: string): boolean {
      return !!this.getIndividualReleaseConfiguration(individualId)
    },
    setIndividualSelected (individualId: string): void {
      const alreadySelected = this.getIndividualReleaseConfiguration(individualId)
      if (alreadySelected) {
        return
      }
      const releaseConfiguration = this.individualConfigurations.find((c) => c.individual.id === individualId)
      if (releaseConfiguration?.dispatchedAt) {
        return
      }
      const individualConfig: ReleaseConfiguration = {
        individualId: individualId,
        variantCode: releaseConfiguration?.variantCode ?? this.defaultVariant
      }
      this.selectedReleaseConfigurations.push(individualConfig)
    },
    setIndividualVariant (individualId: string, variantCode: QuestionnaireVariantCode): void {
      const index = this.selectedReleaseConfigurations.findIndex((i) => i.individualId === individualId)
      if (index === -1) {
        console.info('setIndividualVariant', -1)
        return
      }
      console.info('setIndividualVariant', individualId, variantCode)
      this.selectedReleaseConfigurations[index].variantCode = variantCode
    },
    toggleIndividualSelected (individualId: string): void {
      const index = this.selectedReleaseConfigurations.findIndex((i) => i.individualId === individualId)
      if (index === -1) {
        this.setIndividualSelected(individualId)
        return
      }
      this.selectedReleaseConfigurations.splice(index, 1)
    },
    toggleSelectAllIndividuals (): void {
      if (this.allSelected) {
        // De-select all
        this.selectedReleaseConfigurations.splice(0, this.selectedReleaseConfigurations.length)
        return
      }
      // Select all
      this.individualConfigurations.forEach((c) => {
        if (this.isIndividualSelected(c.individual.id)) {
          // Ignore already selected
          return
        }
        if (c.dispatchedAt) {
          // Ignore already dispatched
          return
        }
        this.toggleIndividualSelected(c.individual.id)
      })
    },
    updateAllIndividualConfigurations (variantCode: QuestionnaireVariantCode) {
      this.selectedReleaseConfigurations.forEach((c) => {
        c.variantCode = variantCode
      })
    },
    async handleError (): Promise<void> {
      this.$toasted.error(this.$t('page.questionnaires.release.releaseError') as string)
      this.loading = true
      try {
        await this.fetchSurveys()
      } finally {
        this.loading = false
      }
    }
  }
})
</script>

<style lang="scss">

</style>
