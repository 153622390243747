<template>
  <div class="pb-2">
    <confirmation-modal
      v-if="confirmDeleteBlock"
      entity-type="block"
      action="delete"
      :image="false"
      :entity="confirmDeleteBlock"
      @confirm="deleteBlock(confirmDeleteBlock)"
      @cancel="confirmDeleteBlock = undefined"
    >
      <span
        v-for="variant in confirmDeleteBlockVariants"
        :key="variant.variantCode"
        class="my-2 text-center"
      >
        <span class="font-weight-bold">{{ variant.variantCode.toUpperCase() }}:</span>  {{ variant.text }}
      </span>
    </confirmation-modal>
    <h3 class="mb-3">
      {{ $t('form.questionnaire.questions.title') }}
      <template v-if="baseBlocks && baseBlocks.length">
        ({{ baseBlocks.length }})
      </template>
    </h3>
    <platform-button
      primary
      icon="plus"
      v-tooltip:bottom="$t('form.questionnaire.questions.addQuestionTopTooltip')"
      :disabled="disabled"
      @click="addBlock(true)"
    >
      {{ $t('form.questionnaire.questions.addQuestionTopAction') }}
    </platform-button>
    <v-expansion-panels multiple class="mb-4" readonly>
      <v-expansion-panel
        v-for="(block, index) in baseBlocks"
        :key="block.id"
        :ref="`block-${block.id}`"
        class="platform--expansion-panel mt-4 rounded"
      >
        <v-expansion-panel-header @click="toggleBlockExpansionPanel(block.id)">
          <div class="min-width-0">
            <v-row no-gutters align="center" class="flex-nowrap">
              <platform-icon
                v-if="invalidBlocks.includes(block.id)"
                name="alert-circle-outline"
                color="warning"
                class="pr-1"
                :size="16"
              />
              <v-col class="flex-grow-1 min-width-0">
                <div class="block-header pr-6">
                  <span :class="{
                    'warning--text': invalidBlocks.includes(block.id)
                  }">
                    <template v-if="block.data">
                      <template v-if="!block.data.variant && !block.data.criteria && !block.data.questionText">
                        <strong v-if="block.variant === BlockType.Question">
                          Blank Question
                        </strong>
                        <strong v-else-if="block.variant === BlockType.Markdown">
                          Information Block
                        </strong>
                      </template>
                      <template v-else-if="block.variant === BlockTypes.Question">
                        <strong v-if="blockVariantsMap[block.id][selectedQuestionnaireVariant]">
                          <template v-if="block.data.criteria && block.data.variant === QuestionTypes.Scale">
                            {{ ScaleCriteriaFriendlyNames[blockVariantsMap[block.id][selectedQuestionnaireVariant].data.criteria] }}
                          </template>
                          {{ QuestionFriendlyNamesMap[blockVariantsMap[block.id][selectedQuestionnaireVariant].data.variant] }}
                        </strong>
                        <template v-if="block.data.questionText || (blockVariantsMap[block.id] && blockVariantsMap[block.id][selectedQuestionnaireVariant])">
                          <template v-if="block.data.variant">- </template>
                          <span
                            class="pr-1"
                            :class="{
                              'warning--text': invalidBlocks.includes(block.id),
                              'text--secondary': !invalidBlocks.includes(block.id)
                            }"
                          >
                            <template v-if="blockVariantsMap[block.id][selectedQuestionnaireVariant].data.questionText">
                              "{{ blockVariantsMap[block.id][selectedQuestionnaireVariant].data.questionText }}"
                            </template>
                             <template v-else-if="block.data.questionText">"{{ block.data.questionText }}"</template>
                          </span>
                        </template>
                      </template>
                      <template v-else-if="block.variant === BlockTypes.Text || block.variant === BlockTypes.Header">
                        -
                        <span
                          class="pr-4"
                          :class="{
                            'warning--text': invalidBlocks.includes(block.id),
                            'text--secondary': !invalidBlocks.includes(block.id)
                          }"
                        >"
                          {{ block.data.content }}"
                        </span>
                      </template>
                    </template>
                  </span>
                </div>
              </v-col>
              <v-col cols="auto" class="pr-6">
                <order-controls
                  entity="Question"
                  :position="block.displayOrder"
                  :max="numBlocks"
                  :disabled="disabled"
                  @moveUp="() => moveBlockUp(block)"
                  @moveDown="() => moveBlockDown(block)"
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="blockVariantsMap[block.id][selectedQuestionnaireVariant].variant === BlockType.Markdown"
            no-gutters
            class="justify-end"
          >
            <platform-button
              warning
              icon="delete"
              :disabled="disabled"
              v-tooltip:bottom="$t('form.questionnaire.questions.deleteQuestionAction')"
              @click="confirmDeleteBlock = block"
            />
          </v-row>
          <block-form
            v-else-if="blockVariantsMap[block.id]"
            type="BlockForm"
            :block-variants="blockVariantsMap[block.id]"
            :selected-questionnaire-variant="selectedQuestionnaireVariant"
            :variants="variants"
            :section-id="sectionId"
            :allowed="allowedQuestionTypesPerGroup[block.groupId || null]"
            :disabled="disabled"
            @input="updateBlock"
            @valid="(val) => updateBlockValidity(block.id, val)"
          >
            <template v-slot:actions>
              <v-row justify="space-between" align="start" class="flex-wrap">
                <platform-button
                  secondary
                  icon="duplicate"
                  class="pointer-events--auto"
                  :disabled="!canDuplicateBlock(block) || disabled"
                  v-tooltip:bottom="canDuplicateBlock(block)
                        ? $t('form.questionnaire.questions.duplicateQuestionAction')
                        : $t('form.questionnaire.questions.questionTypeLimitReachedMessage')"
                  @click="duplicateBlock(block, index)"
                />
                <platform-button
                  warning
                  icon="delete"
                  :disabled="disabled"
                  v-tooltip:bottom="$t('form.questionnaire.questions.deleteQuestionAction')"
                  @click="confirmDeleteBlock = block"
                />
              </v-row>
            </template>
          </block-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template v-if="baseBlocks.length > 0">
      <platform-button
        primary
        icon="plus"
        v-tooltip="$t('form.questionnaire.questions.addQuestionBottomTooltip')"
        :disabled="disabled"
        @click="addBlock(false)"
      >
        {{ $t('form.questionnaire.questions.addQuestionBottomAction') }}
      </platform-button>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'
import { PartialBlock, PlatformBlock, PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '@/types/Platform'
import { BlockTypes } from '@/helpers/questionnaire/blocks'
import {
  QuestionFriendlyNamesMap,
  QuestionTypes,
  ScaleCriteriaFriendlyNames,
  ScaleCriteriaOptions
} from '@/helpers/questionnaire/questions'
import OrderControls from '@/components/shared/OrderControls.vue'
import BlockForm from '@/components/forms/BlockForm.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import sortByDisplayOrder from '@betterboards/shared/helpers/util/sortByDisplayOrder'
import { BlockType } from '@betterboards/shared/types/API'

const DefaultBlock = {
  variant: null,
  displayOrder: null,
  data: {
    variant: null,
    questionText: null,
    questionIntro: null
  }
}

export default Vue.extend({
  name: 'BlocksList',
  components: {
    ConfirmationModal,
    OrderControls,
    BlockForm
  },
  props: {
    value: { type: Array as PropType<PlatformBlock[]>, required: true },
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    sectionId: { type: String, required: true },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    invalidBlocks: <string[]>[],
    baseBlocks: <PlatformBlock[]>[], // Blocks with variantCode === this.defaultVariant
    blockVariantsMap: <{ [sectionId: string]: { [variantCode: string]: PlatformBlock<any> | null } | undefined }>{},
    confirmDeleteBlock: <PlatformBlock | undefined>undefined,
    BlockTypes,
    QuestionTypes,
    QuestionFriendlyNamesMap,
    ScaleCriteriaFriendlyNames
  }),
  mounted () {
    this.initBlocks()
    this.checkValidity()
  },
  watch: {
    value: 'initBlocks',
    variants: 'initBlockVariants',
    invalidBlocks: 'checkValidity'
  },
  computed: {
    BlockType () {
      return BlockType
    },
    numBlocks (): number {
      return this.baseBlocks.length
    },
    /**
     * Note the string 'null' used to index questions with a null groupId here as using null as an index is bad practice.
     */
    groupQuestionTypeLimitsReached (): { [groupId: string]: { [questionType: string]: boolean } } {
      const groupLimits = this.baseBlocks.reduce((groups, b) => {
        const groupId = b?.groupId || 'null'
        if (!groups[groupId]) {
          groups[groupId] = {
            [QuestionTypes.Scale]: 0
          }
        }
        if (b?.data?.variant && b.data.variant === QuestionTypes.Scale) {
          groups[groupId][QuestionTypes.Scale] += 1
        }
        return groups
      }, {})

      const groups = {}
      Object.keys(groupLimits).forEach((groupId) => {
        groups[groupId] = {}
        Object.keys(groupLimits[groupId]).forEach((questionType) => {
          if (questionType === QuestionTypes.Scale && groupLimits[groupId][questionType] >= 10) {
            groups[groupId][questionType] = true
          }
        })
      })
      return groups
    },
    allowedQuestionTypesPerGroup (): void {
      return Object.keys(this.groupQuestionTypeLimitsReached).reduce<any>((groups, groupId) => {
        const allowed = Object.values(QuestionTypes)
        if (this.groupQuestionTypeLimitsReached[groupId][QuestionTypes.Scale]) {
          const index = allowed.indexOf(QuestionTypes.Scale)
          allowed.splice(index, 1)
        }
        groups[groupId] = allowed
        return groups
      }, {})
    },
    defaultVariant (): QuestionnaireVariantCode | undefined {
      return this.variants?.[0]?.variantCode as QuestionnaireVariantCode | undefined
    },
    confirmDeleteBlockVariants (): Array<{ variantCode: String, text: String }> | undefined {
      if (!this.confirmDeleteBlock) {
        return undefined
      }
      const variantTexts: Array<{ variantCode: String, text: String }> = []
      const blockVariantsData = this.blockVariantsMap[this.confirmDeleteBlock.id]
      for (const variant in blockVariantsData) {
        const variantBlockData = blockVariantsData[variant]?.data
        if (!variantBlockData) {
          continue
        }
        const questionVariant = variantBlockData.variant
        const questionCriteria = variantBlockData.criteria
        const isAgreeScale: boolean = questionVariant === QuestionTypes.Scale &&
          questionCriteria === ScaleCriteriaOptions.Agreement
        const isTextQuestion: boolean = questionVariant === QuestionTypes.Text ||
          questionVariant === QuestionTypes.MultiText
        const variantText = isAgreeScale || isTextQuestion
          ? variantBlockData.questionText
          : variantBlockData.questionIntro

        variantTexts.push({
          variantCode: variant,
          text: variantText
        })
      }
      return variantTexts
    }
  },
  methods: {
    initBlocks (): void {
      if (!this.value?.length) {
        return
      }
      const blocks: PlatformBlock<any>[] = []
      this.value.forEach((block: PlatformBlock) => {
        block.variantCode = block.variantCode ?? this.defaultVariant
        this.setBlockVariant(block)
        if (block.variantCode === this.defaultVariant) {
          blocks.push(block)
        }
      })
      const newBlocks = this.orderBlocks(blocks)
      this.baseBlocks.splice(
        0,
        this.baseBlocks.length,
        ...newBlocks
      )
    },
    /**
     * initBlockVariants - Sets a default value for each variant on any block that is missing it in this.blockVariantsMap
     */
    initBlockVariants (): void {
      Object.values(this.blockVariantsMap).forEach((blocks) => {
        const defaultVariant = this.defaultVariant
        if (!blocks || !defaultVariant) {
          console.warn('Failed to initialize blocks for new variant due to no defaultVariant.')
          return
        }
        const defaultBlock = blocks[defaultVariant]
        if (!defaultBlock) {
          console.warn('Failed to initialize blocks for new variant as a default block was not found.')
          return
        }
        this.variants.forEach((variant) => {
          if (blocks[variant.variantCode]) {
            return
          }
          const blockVariant = this.getBlockVariantData(defaultBlock, variant.variantCode)
          this.setBlockVariant(blockVariant)
        })
      })
    },
    getBlockVariantData (block: PlatformBlock, variantCode: QuestionnaireVariantCode): PlatformBlock & any {
      const clonedBlock = cloneDeep(block)
      return {
        ...clonedBlock,
        variantCode,
        data: {
          ...clonedBlock.data,
          variant: clonedBlock.data.variant ?? null,
          questionText: clonedBlock.data.questionText ?? null,
          questionIntro: clonedBlock.data.questionIntro ?? null
        },
        sectionId: this.sectionId,
        companyId: this.$store.state.Company.selectedCompany.id,
        groupId: undefined,
        __typename: undefined,
        createdAt: undefined,
        updatedAt: undefined
      }
    },
    setBlockVariant (blockVariant: PlatformBlock): void {
      if (!this.blockVariantsMap[blockVariant.id]) {
        Vue.set(this.blockVariantsMap, blockVariant.id, this.variants.reduce((map, v) => {
          map[v.variantCode] = this.getBlockVariantData(blockVariant, v.variantCode)
          return map
        }, {}))
      }
      Vue.set(this.blockVariantsMap[blockVariant.id]!, blockVariant.variantCode, blockVariant)
    },
    setBlocks (blocks: PlatformBlock[]): void {
      this.baseBlocks = blocks.map((block, index) => {
        const newBlock = {
          ...block,
          displayOrder: index + 1
        }
        if (newBlock.displayOrder !== block.displayOrder) {
          this.updateBlock(newBlock)
        }
        return newBlock
      })
    },
    toggleBlockExpansionPanel (id: string): void {
      const block = this.$refs[`block-${id}`]?.[0]
      if (block) {
        block.toggle()
      }
    },
    addBlock (atStart: boolean): void {
      if (this.disabled || !this.defaultVariant) {
        return
      }
      const tempBlocks: PartialBlock<any>[] = this.baseBlocks
      const block: PartialBlock<any> = {
        ...cloneDeep(DefaultBlock),
        id: uuid(),
        sectionId: this.sectionId,
        companyId: this.$store.state.Company.selectedCompany.id,
        variantCode: this.defaultVariant,
        variant: BlockTypes.Question
      }
      if (atStart) {
        tempBlocks.unshift(block)
      } else {
        tempBlocks.push(block)
      }
      this.setBlocks(tempBlocks as PlatformBlock<any>[])
      const orderedBlock = this.baseBlocks.find((b) => b.id === block.id && b.variantCode === block.variantCode)
      if (orderedBlock) {
        this.setBlockVariant(orderedBlock as PlatformBlock<any>)
      }

      this.$nextTick(() => {
        this.toggleBlockExpansionPanel(block.id)
      })
    },
    updateBlock (block: PlatformBlock): void {
      if (this.disabled || this.invalidBlocks.includes(block.id)) {
        return
      }
      this.setBlockVariant(block)
      this.$emit('updateBlock', block)
    },
    updateBlockValidity (id: string | undefined, valid: boolean): void {
      if (id === undefined) {
        console.error('Failed to updateBlockValidity, block has no id!', id)
        return
      }
      const index = this.invalidBlocks.indexOf(id)
      if (index !== -1) {
        if (!valid) {
          // Block already marked as invalid, ignore.
          return
        }
        // No longer invalid
        this.invalidBlocks.splice(index, 1)
      }
      if (valid) {
        return
      }
      this.invalidBlocks.push(id)
    },
    duplicateBlock (block: PlatformBlock<any>, index: number): void {
      if (this.disabled || !this.defaultVariant) {
        return
      }
      const tempBlocks = this.baseBlocks
      const newIndex = index + 1
      const blockData: PartialBlock = {
        id: uuid(),
        sectionId: this.sectionId,
        companyId: this.$store.state.Company.selectedCompany.id,
        variantCode: this.defaultVariant,
        variant: block.variant,
        data: {
          ...block.data,
          masterBlockId: uuid()
        },
        groupId: block.groupId
      }
      const newBlock = JSON.parse(JSON.stringify(blockData))
      tempBlocks.splice(newIndex, 0, newBlock)
      this.setBlockVariant(blockData as PlatformBlock<any>)
      this.setBlocks(tempBlocks)

      this.$nextTick(() => {
        this.toggleBlockExpansionPanel(blockData.id)
      })
    },
    deleteBlock (block: PlatformBlock): void {
      this.confirmDeleteBlock = undefined
      const variantsMap = this.blockVariantsMap[block.id]
      if (this.disabled || !variantsMap) {
        return
      }
      const tempBlocks = this.baseBlocks
      Object.keys(variantsMap).forEach((variantCode) => {
        if (variantsMap[variantCode]) {
          this.$emit('deleteBlock', {
            ...block,
            variantCode
          })
          const index = tempBlocks.findIndex((b) => b.id === block.id && b.variantCode === block.variantCode)
          if (index !== -1) {
            tempBlocks.splice(index, 1)
          }
        }
      })
      this.updateBlockValidity(block.id, true)
      this.setBlocks(tempBlocks)
      this.blockVariantsMap[block.id] = undefined
    },
    orderBlocks (blocks: PlatformBlock[]): PlatformBlock[] {
      return blocks.sort(sortByDisplayOrder)
    },
    moveBlockUp (block: PlatformBlock): void {
      if (this.disabled) {
        return
      }
      const tempBlocks = this.baseBlocks
      const currentIndex = tempBlocks.indexOf(block)
      if (currentIndex === 0) {
        return
      }
      const newIndex = currentIndex - 1
      tempBlocks.splice(currentIndex, 1)
      tempBlocks.splice(newIndex, 0, block)
      this.setBlocks(tempBlocks)
    },
    moveBlockDown (block: PlatformBlock) {
      if (this.disabled) {
        return
      }
      const tempBlocks = this.baseBlocks
      const currentIndex = tempBlocks.indexOf(block)
      if (currentIndex === tempBlocks.length) {
        return
      }
      const newIndex = currentIndex + 1
      tempBlocks.splice(currentIndex, 1)
      tempBlocks.splice(newIndex, 0, block)
      this.setBlocks(tempBlocks)
    },
    canDuplicateBlock (block: PlatformBlock): boolean {
      if (!block.data || !block.data.variant) {
        return true
      }
      // Get current limits for this question's group
      const limits = this.groupQuestionTypeLimitsReached[block.groupId ?? 'null']
      if (!limits) {
        return true
      }
      return !limits[block.data.variant]
    },
    checkValidity (): void {
      if (this.invalidBlocks.length) {
        this.$emit('valid', false)
        return
      }
      this.$emit('valid', true)
    }
  }
})
</script>

<style lang="scss" scoped>

.block-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
