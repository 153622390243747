<template>
  <v-row class="flex-column" no-gutters>
    <file-upload
      title="Display Picture"
      icon="user"
      object-type="userAvatar"
      :object-id="individual ? individual.id : undefined"
      :company-id="selectedCompany ? selectedCompany.id : undefined"
      @pending="$emit('pendingFile')"
      @uploaded="uploadedFile"
    />
    <v-form ref="form" v-model="formValid" @submit.prevent="">
      <v-form-base
        :model="individual"
        :schema="formSchema"
        @input="(ev) => $emit('input', ev)"
      />
    </v-form>
  </v-row>
</template>

<script lang="ts">
import { invalidateCachedImage } from '@/helpers/forms'
import { Validators } from '@/helpers/validation'
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import VFormBase from 'vuetify-form-base'
import FileUpload from '@/components/FileUpload.vue'
import {
  IndividualEthnicDescriptionGroupItems,
  IndividualReligionOptions,
  IndividualRoleOptions
} from '@/helpers/individual'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'

const TitleOptions = [
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Prof.',
  'Sir',
  'Lord',
  'Lady',
  'Baron',
  'Baroness',
  'Duke',
  'Duchess',
  'Other'
]

export default Vue.extend({
  name: 'IndividualForm',
  components: {
    FileUpload,
    VFormBase
  },
  props: {
    individual: { type: Object as PropType<Partial<PlatformIndividual>>, required: true },
    saving: { type: Boolean, required: false }
  },
  data: () => ({
    formValid: false
  }),
  watch: {
    formValid (val) {
      this.$emit('valid', val)
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    ...mapState('Individual', {
      individualsList: 'list'
    }),
    isUpdating (): boolean {
      return !!this.individual?.id
    },
    individualRoleOptionsTranslated (): { value: string, text: string }[] {
      return IndividualRoleOptions.map((data) => ({
        value: data.value,
        text: this.$t(`shared.individual.roles.${data.value}`) as string
      }))
    },
    formSchema () {
      const config: any = {}

      const sharedProps = {
        disabled: this.saving
      }

      config.title = { type: 'select', items: TitleOptions, label: this.$t('form.individual.title') as string, col: { xs: 12, sm: 2 }, ...sharedProps }
      config.firstName = { type: 'text', label: this.$t('form.individual.firstName') as string, col: { xs: 12, sm: 4 }, ...sharedProps }
      config.familyName = { type: 'text', label: this.$t('form.individual.familyName') as string, col: { xs: 12, sm: 6 }, ...sharedProps }
      config.preferredName = {
        type: 'BBTextField',
        required: true,
        label: this.$t('form.individual.preferredName') as string,
        'data-test-target': 'boardMemberPreferredNameField',
        col: { xs: 12, sm: 6 },
        ...sharedProps,
        rules: [this.validateUniquePreferredName(this.individualsList)],
        tooltip: this.$t('form.individual.preferredNameTooltip')
      }
      config.email = { type: 'BBTextField', required: true, label: this.$t('form.individual.email') as string, 'data-test-target': 'boardMemberEmailField', col: { xs: 12, sm: 6 }, ...sharedProps, disabled: this.isUpdating || this.saving, rules: [Validators.email, Validators.allowedEmailCharacters], tooltip: this.$t('form.individual.emailTooltip') }
      config.role = {
        type: 'BBSelectField',
        required: true,
        label: this.$t('form.individual.role') as string,
        items: this.individualRoleOptionsTranslated,
        col: { xs: 12, sm: 6 },
        'data-test-target': 'boardMemberRoleField',
        menuProps: { contentClass: 'board-member__role-dropdown' },
        tooltip: this.$t('form.individual.roleTooltip'),
        ...sharedProps
      }
      config.jobTitle = { type: 'text', label: this.$t('form.individual.jobTitle') as string, col: { xs: 12, sm: 6 }, tooltip: this.$t('form.individual.jobTitleTooltip'), ...sharedProps }
      config.joinDate = { type: 'BBDatePicker', required: true, month: true, label: this.$t('form.individual.boardJoinDate') as string, ext: 'date', locale: 'en', col: { xs: 12, sm: 6 }, tooltip: this.$t('form.individual.boardJoinDateTooltip'), ...sharedProps }
      config.appointments = { type: 'BBIndividualAppointmentsField', col: { xs: 12, sm: 6 }, ...sharedProps }
      // =defaultLanguage: { type: 'select', label: 'Default Language', col: { xs: 12, sm: 3 }, items: ['en'], ...sharedProps }
      config.gender = { type: 'select', label: this.$t('form.individual.gender') as string, items: ['Male', 'Female'], col: { xs: 12, sm: 6 }, ...sharedProps }
      config.religion = { type: 'select', label: this.$t('form.individual.religion') as string, items: IndividualReligionOptions, col: { xs: 12, sm: 6 }, ...sharedProps }
      config.ethnicDesignation = { type: 'BBLayeredSelectField', label: this.$t('form.individual.ethnicBackground') as string, items: IndividualEthnicDescriptionGroupItems, col: { xs: 12, sm: 6 }, ...sharedProps }

      return config
    }
  },
  methods: {
    uploadedFile (): void {
      this.$emit('uploadedFile')
      if (this.individual?.id && this.selectedCompany?.id) {
        invalidateCachedImage('userAvatar', this.individual.id, this.selectedCompany.id)
      }
    },
    validate (): void {
      const form: any = this.$refs.form
      form.validate()
    },
    validateUniquePreferredName (individuals: PlatformIndividual[]) {
      return (val?: string): string | boolean => {
        const nonUniquePreferredName: boolean = individuals
          .filter((i: PlatformIndividual) => i.id !== this.individual.id)
          .some(
            (i: PlatformIndividual): boolean => {
              return i.preferredName.trim().toLowerCase() === val?.trim().toLowerCase()
            }
          )
        if (nonUniquePreferredName) {
          return this.$t('form.validation.preferredNameErrorMessage') as string
        }
        return true
      }
    }
  }
})
</script>
