<template>
  <div v-if="config.legend" class="shrink text-center">
    <scale-graph :config="{ ...config, legend: true }" />
  </div>
  <v-col v-else class="variance-table pt-5">
    <v-row class="table-header highlight--text">
      <v-col :cols="criteriaColWidth" class="text-right">
        {{ $t(isImportanceResults ? 'report.results.competenceAreaHeader' : 'shared.graphs.variance.questionHeader') }}
      </v-col>
      <v-col :cols="graphColWidth" class="text-center">
        {{ $t(isImportanceResults ? 'shared.graphs.variance.importance' : 'shared.graphs.variance.committeeSelfEvaluationHeader') }}
      </v-col>
      <v-col :cols="graphColWidth" class="text-center">
        {{ $t(isImportanceResults ? 'shared.graphs.variance.development' : 'shared.graphs.variance.committeeExternalEvaluationHeader') }}
      </v-col>
      <v-col v-if="hasVotes" :cols="voteColWidth">
        {{ $t('report.results.votesHeader') }}*
      </v-col>
    </v-row>
    <v-row v-if="data.length > 1">
      <v-col :cols="criteriaColWidth" class="py-1" />
      <v-col :cols="graphColWidth" class="text-center py-1">
        <scale-graph :config="{ ...config, tickLabels: true }" />
      </v-col>
      <v-col :cols="graphColWidth" class="text-center py-1">
        <scale-graph :config="{ ...config, tickLabels: true }" />
      </v-col>
      <v-col :cols="voteColWidth" class="py-1" />
    </v-row>
    <v-row
      v-for="(graph, index) in graphs"
      :key="index"
      align="center"
      class="pb-2"
      :class="{ 'pb-3': !isImportanceResults }"
    >
      <v-col :cols="criteriaColWidth" class="pr-3 text-right py-1" :class="{
        'text-body-2': !isImportanceResults
      }">
        {{ graph.repeatForCriteriaValue || graph.questionTextReport || graph.questionText }}
      </v-col>
      <v-col :cols="graphColWidth" class="shrink py-1">
        <scale-graph
          :data="graph.Importance || graph.self"
          :config="{ ...config }"
          :comparison-data="graph.comparison ? (isImportanceResults ? graph.comparison.Importance : graph.comparison.self) : undefined"
        />
      </v-col>
      <v-col :cols="graphColWidth" class="shrink py-1">
        <scale-graph
          :data="graph.Development || graph.avg"
          :config="{ ...config }"
          :comparison-data="graph.comparison ? (isImportanceResults ? graph.comparison.Development : graph.comparison.avg) : undefined"
        />
      </v-col>
      <v-col v-if="hasVotes" :cols="voteColWidth" class="highlight--text">
        <div
          v-for="(votes, item) in graph.votes"
          :key="item"
          class="mr-1 d-inline-block"
        >
          <span class="font-weight-bold">{{ votes.option }}</span>
          &nbsp;
          <span>({{ votes.value }})</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="criteriaColWidth" class="py-1" />
      <v-col :cols="graphColWidth" class="text-center py-1">
        <scale-graph :config="{ ...config, tickLabels: true }" />
      </v-col>
      <v-col :cols="graphColWidth" class="text-center py-1">
        <scale-graph :config="{ ...config, tickLabels: true }" />
      </v-col>
      <v-col :cols="voteColWidth" class="py-1" />
    </v-row>
  </v-col>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ScaleGraphConfig } from '@betterboards/graphs/variants/ScaleGraph'
import ScaleGraph from '@/components/graphs/ScaleGraph.vue'
import { GraphVote } from '@betterboards/shared/types/Graph'
import { normalizeVoteResult } from '@betterboards/shared/helpers/entities/Result/normalizeVoteResult'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { CommitteeVarianceResultData, ImportanceVarianceResultData, MultipleChoiceVoteResult } from '@betterboards/graphs/types/Graph'
import { ResultSet } from '@betterboards/shared/types/ResultSet'
import { findVarianceComparisonDataPoint } from '@betterboards/graphs/helpers/findVarianceComparisonDataPoint'

export default Vue.extend({
  name: 'VarianceGraph',
  components: {
    ScaleGraph
  },
  props: {
    data: {
      type: Array as PropType<ImportanceVarianceResultData[] | CommitteeVarianceResultData[]>,
      required: false
    },
    comparisonData: {
      type: Array as PropType<ImportanceVarianceResultData[] | CommitteeVarianceResultData[] | undefined>,
      required: false
    },
    config: {
      type: Object as PropType<Partial<ScaleGraphConfig>>,
      required: false
    }
  },
  computed: {
    isImportanceResults (): boolean {
      return this.data.some((r) => r.Importance || r.Development)
    },
    criteriaColWidth (): number {
      return this.hasVotes ? 3 : 4
    },
    graphColWidth (): number {
      return this.hasVotes ? 3 : 4
    },
    voteColWidth (): number {
      return 3
    },
    hasVotes (): boolean {
      return this.data.some((r) => r.votes)
    },
    graphs (): ImportanceVarianceResultData[] | CommitteeVarianceResultData[] {
      const data: any[] = this.data
      if (!data) {
        return []
      }
      return data.map((graph: ImportanceVarianceResultData | CommitteeVarianceResultData) => {
        return {
          ...graph,
          comparison: this.getComparisonData(graph),
          votes: graph.votes?.map(
            (v: MultipleChoiceVoteResult): GraphVote => normalizeVoteResult(this.companyIndividuals, v)
          )
        }
      }) as ImportanceVarianceResultData[] | CommitteeVarianceResultData[]
    },
    companyIndividuals (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    }
  },
  methods: {
    getComparisonData (graph: any): any | undefined {
      if (!this.comparisonData) {
        return undefined
      }
      return findVarianceComparisonDataPoint(graph, this.comparisonData)
    }
  }
})
</script>

<style lang="scss">
.variance-table .scale-graph {
  width: auto;
  height: auto;
}
</style>
