<template>
  <v-row no-gutters class="flex-column">
    <v-row
      v-for="(release, index) in releases"
      :key="index"
      no-gutters
      align="center"
      justify="center"
      class="mb-3 cursor-pointer"
      @click="$emit('input', release.groupId)"
    >
      <v-col>
        <v-card
          class="px-3 py-2"
          hover
          :elevation="release.groupId === value ? 4 : undefined"
          :to="targetRoute ? getTargetRouteObject(release.groupId) : undefined"
        >
          <v-row no-gutters align="center">
            <div style="width: 44px" class="mr-2 text-center">
              <platform-icon
                v-if="release.icon"
                :name="release.icon"
                :size="32"
              />
              <platform-avatar
                v-else
                entity-type="company"
                :pad-bottom="false"
                :entity="targetCompany"
                :size="44"
              />
            </div>
            <div class="grow">
              <div>
                <span class="text-body-1 grey--text text--darken-3">
                  {{ release.name }}
                </span>
              </div>
              <span v-if="release.releasedAt" class="text-body-2 grey--text text--darken-1 pl-2">
                {{ $t('modal.questionnaire.releases.releasedText') }} <strong>{{ release.releasedAt }}</strong>.
              </span>
              <v-progress-linear
                v-if="release.completion !== undefined"
                :value="release.completion"
                :height="12"
                rounded
                class="mt-1"
              >
                <span class="grey--text text--lighten-3" style="font-size: 8px;">
                  {{ release.displayCompletion }}
                </span>
              </v-progress-linear>
            </div>
            <div class="shrink pl-2">
              <platform-spinner
                v-if="release.groupId === loadingGroup"
                :size="35"
                color="secondary"
              />
              <platform-icon
                v-else
                color="secondary"
                :name="(value ? release.groupId === value : !release.groupId) ? 'check-circle' : 'caret-right'"
                :size="35"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-row>
</template>

<script lang="ts">
import { SortSurveyGroups } from '@/helpers/surveyGroup'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import dayjs from 'dayjs'
import Vue, { PropType } from 'vue'
import { Company } from '@/API'
import { PlatformSurvey } from '@/types/Platform'
import { calculateCompletion } from '@/helpers/survey'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { LongDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import { RawLocation } from 'vue-router'

interface QuestionnaireRelease {
  name: string
  groupId: string | null
  icon?: string
  releasedAt?: string
  completion?: number
  displayCompletion?: string
}

export default Vue.extend({
  name: 'SurveyGroupSelector',
  components: {
    PlatformSpinner,
    PlatformAvatar
  },
  props: {
    value: { type: String, required: false },
    surveyGroups: { type: Array as PropType<SurveyGroupProgress[]>, required: true },
    loadingGroup: { type: String, required: false },
    targetRoute: { type: String, required: false },
    noneOption: { type: Boolean, default: false }
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    targetCompany (): Company {
      const companyId = this.surveyGroups[0].companyId
      return this.$store.state.Company.list.find((c: Company) => c.id === companyId)
    },
    releases (): QuestionnaireRelease[] {
      const surveyGroups: SurveyGroupProgress[] = [...this.surveyGroups]
        .sort(SortSurveyGroups)
      const options = [
        ...surveyGroups.map((surveyGroup: SurveyGroupProgress): QuestionnaireRelease => ({
          groupId: surveyGroup.id,
          name: surveyGroup.name,
          releasedAt: surveyGroup.releasedAt
            ? dayjs(surveyGroup.releasedAt).format(LongDateTimeDisplayFormat)
            : undefined,
          createdAt: surveyGroup.createdAt
            ? dayjs(surveyGroup.createdAt).format(LongDateTimeDisplayFormat)
            : undefined,
          completion: this.calculateCompletion(surveyGroup.surveys.items),
          displayCompletion: this.getCompletionDisplayVal(surveyGroup.surveys.items)
        } as QuestionnaireRelease))
      ]
      if (this.noneOption) {
        options.unshift(
          {
            icon: 'cancel',
            name: this.$t('modal.questionnaire.releases.noneOption') as string,
            groupId: null
          }
        )
      }
      return options
    }
  },
  methods: {
    calculateCompletion (group: PlatformSurvey[]): number | undefined {
      const totalCompletion = group.reduce((total, survey) => total + (calculateCompletion(survey as any) ?? 0), 0)
      return totalCompletion / group.length
    },
    getCompletionDisplayVal (group: PlatformSurvey[]): string | undefined {
      const val = this.calculateCompletion(group)
      if (!val || isNaN(val as any)) {
        return '0%'
      }
      return `${Math.floor(val)}%`
    },
    getTargetRouteObject (surveyGroupId: string): RawLocation | undefined {
      if (!this.targetRoute) {
        return undefined
      }
      return {
        name: this.targetRoute,
        params: {
          surveyId: surveyGroupId
        }
      }
    }
  }
})
</script>
