/* tslint:disable */
/* eslint-disable */
import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullCompany = /* GraqhQL */ `
  query GetFullCompany($id: ID!) {
    getCompany(id: $id) {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      address {
        line1
        line2
        city
        region
        postalCode
        country
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        report
        admin
      }
      updatedAt
      tags
      individuals {
        items {
          individualId
          companyId
          individual {
            id
            companyId
            createdAt
            deletedAt
            preferredName
            firstName
            familyName
            title
            role
            teams {
              items {
                companyId
                individualId
                teamId
                createdAt
              }
            }
          }
        }
        nextToken
      }
      committees {
        items {
          id
          companyId
          name
          tags
          createdAt
          individuals {
            items {
              individualId
              companyId
              createdAt
            }
            nextToken
          }
        }
      }
    }
  }
` as GeneratedQuery<
  APITypes.GetFullCompanyQueryVariables,
  APITypes.GetFullCompanyQuery
>
