/* tslint:disable */
/* eslint-disable */
import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const listFullCompanyIndividuals = /* GraphQL */ `
  query ListFullCompanyIndividuals(
    $companyId: ID!
    $individualId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyIndividualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    individualsByCompanyId(
      companyId: $companyId
      individualId: $individualId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        companyId
        individualId
        individual {
          id
          createdAt
          deletedAt
          email
          companyId
          dateOfBirth
          firstName
          gender
          contactNumber
          preferredName
          familyName
          title
          role
          jobTitle
          religion
          ethnicDesignation
          joinDate
          accountStatus
          appointments {
            Chair
            SID
            NonExecutive
            CommitteeChair
            Other
          }
          updatedAt
          teams {
            items {
              companyId
              individualId
              teamId
              createdAt
            }
          }
        }
        company {
          id
          owner
          parentId
          name
          createdAt
          deletedAt
          companyType
          phoneNumber
          registrationNumber
          numberOfEmployees
          brandLogo
          sector
          ownershipStructure
          country
          employeeCount
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ListFullCompanyIndividualsQueryVariables,
  APITypes.ListFullCompanyIndividualsQuery
>
