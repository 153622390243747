import {
  Block,
  BlockType,
  CognitoIdentity,
  Company,
  CompanyPackage,
  CompanyUser,
  LanguageCode,
  Package,
  Questionnaire,
  QuestionnaireVariant,
  Section,
  Survey,
  Totals
} from './API'
import { PlatformCompany } from './Company'
import { PlatformIndividual } from './Individual'
import { PlatformPackageItem } from './Package'
import { MarkdownBlockData, QuestionBlockData } from './Question'
import {
  QuestionnaireCustomContent,
  QuestionnaireGroup
} from './Questionnaire'

export interface PlatformImageConfig {
  id: string
  objectType: string
  companyId: string
}

export enum PlatformEntityTypes {
  User = 'user',
  Individual = 'individual',
  TeamIndividual = 'teamIndividual',
  Company = 'company',
  CompanyPackage = 'companyPackage',
  CompanyUser = 'companyUser',
  CompanyIndividual = 'companyIndividual',
  Committee = 'committee',
  CommitteeIndividual = 'committeeIndividual',
  Questionnaire = 'questionnaire',
  Survey = 'survey',
  SurveyGroup = 'surveyGroup',
  Section = 'section',
  Block = 'block',
  Result = 'result', // Not technically an entity but a datatype (objects in the ResultSetGenerator response)
  Package = 'package',
  ReportGroup = 'reportGroup',
  Report = 'report',
  ReportSection = 'reportSection',
  ReportDocument = 'reportDocument'
}

export enum PlatformEntityStatuses {
  Versions = 'versions', // Represents the "editions" of a Questionnaire, linking this Company's Questionnaires on Package ID
  Team = 'team', // Represents a Team an Individual belongs to
}

export interface EntityStatus {
  status: PlatformEntityStatuses
  text?: string
  value: number | string
  hideValue?: boolean
  tooltip?: string
  icon?: string
  clickEvent?: string // Name of the event to emit from the component if this status is clicked.
  clickTooltip?: string // Only shown if a listener is defined for this status's clickEvent
  primary?: boolean // Use Primary style
  secondary?: boolean // Use Secondary style
}

export type QuestionnaireVariantCode = LanguageCode

export interface PlatformSurvey extends Omit<Partial<Survey>, 'individual' | 'totals' | 'response' | 'questionnaire' | 'variantCode'> {
  id: string
  individualId: string
  individual: PlatformIndividual
  groupId: string
  totals: Omit<Totals, '__typename'>
  response: any[]
  questionnaire: PlatformQuestionnaire
  variantCode: QuestionnaireVariantCode
}

/**
 * Used only to typehint a Survey object returned from the API before it has been validated to contain a Questionnaire
 */
export interface PlatformSurveyNoQuestionnaire extends Omit<PlatformSurvey, 'questionnaire'> {
  questionnaire: PlatformQuestionnaire | null
}

export interface BaseBlock<
  T extends BlockType = BlockType,
  D extends QuestionBlockData | MarkdownBlockData | undefined = QuestionBlockData
> extends Omit<Block, 'data' | 'variantCode'> {
  type: T
  data: D
  variantCode: QuestionnaireVariantCode
}

export type QuestionBlock = BaseBlock<BlockType.Question, QuestionBlockData>
export type MarkdownBlock = BaseBlock<BlockType.Markdown, MarkdownBlockData>

export type PlatformBlock = QuestionBlock | MarkdownBlock

/** Partial version of PlatformBlock for usage in forms referring to the data which is still being constructed. */
export interface PartialBlock
  extends Omit<QuestionBlock, '__typename' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'variantCode'> {
  variantCode: QuestionnaireVariantCode
}

/** Partial version of PlatformBlock for usage in forms referring to the data which is still being constructed. */
export interface PartialSection
  extends Omit<PlatformSection, '__typename' | 'companyId' | 'questionnaireId' | 'createdAt' | 'updatedAt' | 'variantCode' | 'displayOrder' | 'name'> {
  name: string | null
  displayOrder: number | null
  companyId?: string | null
  questionnaireId?: string | null
  variantCode?: QuestionnaireVariantCode
}

export interface PlatformSection extends Omit<Section, 'blocks' | 'variantCode'> {
  blocks: {
    items: PlatformBlock[]
  }
  variantCode: QuestionnaireVariantCode
}

export interface PlatformQuestionnaire extends Omit<Questionnaire, 'company' | 'sections' | 'variants' | '__typename' | 'groups' | 'customContent'> {
  company?: PlatformCompany | undefined
  sections: {
    items: PlatformSection[]
  }
  variants: PlatformQuestionnaireVariant[]
  groups?: QuestionnaireGroup[]
  customContent?: QuestionnaireCustomContent
}

export interface PlatformQuestionnaireVariant extends Omit<QuestionnaireVariant, '__typename' | 'variantCode'> {
  variantCode: QuestionnaireVariantCode
}

export interface PlatformCompanyPackage extends Omit<CompanyPackage, 'package'> {
  package: PlatformPackage
}

export interface PlatformUser extends Omit<CognitoIdentity, 'companies'> {
  companies: {
    items: PlatformCompanyUser[]
  }
}

export interface PlatformCompanyUser extends Omit<CompanyUser, 'company' | 'user'> {
  company: Company
  user: PlatformUser
}

export interface PlatformPackage extends Omit<Package, 'variants' | 'packageItems'> {
  variants: QuestionnaireVariantCode[]
  packageItems: {
    items: PlatformPackageItem[]
  }
}

export interface PlatformTranslationOverrides {
  [key: string]: any
}

export enum PlatformEntityAction {
  Create = 'create',
  Update = 'update',
  Delete = 'Delete',
}

export enum InputFields {
  SectionIntroduction = 'SectionIntroduction',
  SectionIntroductionCompact = 'SectionIntroductionCompact',
  SectionSummary = 'SectionSummary',
  EvaluationInformation = 'EvaluationInformation',
  FocusAreas = 'FocusAreas',
  KeyFindingsSummary = 'KeyFindingsSummary',
  KeyFindingsValue = 'KeyFindingsValue',
  TextQuestionText = 'TextQuestionText',
  QuestionTextReportText = 'QuestionTextReportText',
  TextQuestionSelfEvaluation = 'TextQuestionSelfEvaluation',
  ScaleQuestionText = 'ScaleQuestionText',
  ScaleQuestionIntro = 'ScaleQuestionIntro',
  ScaleQuestionTextSelfEvaluation = 'ScaleQuestionTextSelfEvaluation',
  ScaleQuestionIntroSelfEvaluation = 'ScaleQuestionIntroSelfEvaluation',
  MultiTextQuestionHeader = 'MultiTextQuestionHeader',
  MultiTextQuestionResponse = 'MultiTextQuestionResponse',
  TextQuestionResponse = 'TextQuestionResponse',
}

export interface PlatformSectionCondition {
  id: string
  variantCode: QuestionnaireVariantCode
}

export interface PlatformBlockCondition {
  id: string
  variantCode: QuestionnaireVariantCode
}
