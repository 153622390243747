/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getBlock = /* GraphQL */ `query GetBlock($id: ID!, $variantCode: String!) {
  getBlock(id: $id, variantCode: $variantCode) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBlockQueryVariables, APITypes.GetBlockQuery>;
export const listBlocks = /* GraphQL */ `query ListBlocks(
  $id: ID
  $variantCode: ModelStringKeyConditionInput
  $filter: ModelBlockFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBlocks(
    id: $id
    variantCode: $variantCode
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      variantCode
      sectionId
      groupId
      companyId
      createdAt
      variant
      data
      displayOrder
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBlocksQueryVariables,
  APITypes.ListBlocksQuery
>;
export const getCognitoIdentity = /* GraphQL */ `query GetCognitoIdentity($id: ID!) {
  getCognitoIdentity(id: $id) {
    id
    cognitoId
    userCreatedBy
    createdAt
    updatedAt
    displayName
    email
    defaultLanguage
    avatar {
      bucket
      region
      key
      __typename
    }
    companies {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCognitoIdentityQueryVariables,
  APITypes.GetCognitoIdentityQuery
>;
export const listCognitoIdentities = /* GraphQL */ `query ListCognitoIdentities(
  $filter: ModelCognitoIdentityFilterInput
  $limit: Int
  $nextToken: String
) {
  listCognitoIdentities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCognitoIdentitiesQueryVariables,
  APITypes.ListCognitoIdentitiesQuery
>;
export const getUserByCognitoId = /* GraphQL */ `query GetUserByCognitoId(
  $cognitoId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCognitoIdentityFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByCognitoId(
    cognitoId: $cognitoId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByCognitoIdQueryVariables,
  APITypes.GetUserByCognitoIdQuery
>;
export const userByEmail = /* GraphQL */ `query UserByEmail(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCognitoIdentityFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailQueryVariables,
  APITypes.UserByEmailQuery
>;
export const getCommittee = /* GraphQL */ `query GetCommittee($id: ID!) {
  getCommittee(id: $id) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommitteeQueryVariables,
  APITypes.GetCommitteeQuery
>;
export const listCommittees = /* GraphQL */ `query ListCommittees(
  $filter: ModelCommitteeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommittees(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommitteesQueryVariables,
  APITypes.ListCommitteesQuery
>;
export const listCommitteesByCompanyId = /* GraphQL */ `query ListCommitteesByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCommitteeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommitteesByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommitteesByCompanyIdQueryVariables,
  APITypes.ListCommitteesByCompanyIdQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const companyByOwnerId = /* GraphQL */ `query CompanyByOwnerId(
  $owner: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companyByOwnerId(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyByOwnerIdQueryVariables,
  APITypes.CompanyByOwnerIdQuery
>;
export const getCompanyIndividual = /* GraphQL */ `query GetCompanyIndividual($individualId: ID!, $companyId: ID!) {
  getCompanyIndividual(individualId: $individualId, companyId: $companyId) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      deletedAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyIndividualQueryVariables,
  APITypes.GetCompanyIndividualQuery
>;
export const listCompanyIndividuals = /* GraphQL */ `query ListCompanyIndividuals(
  $individualId: ID
  $companyId: ModelIDKeyConditionInput
  $filter: ModelCompanyIndividualFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyIndividuals(
    individualId: $individualId
    companyId: $companyId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      companyId
      individualId
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyIndividualsQueryVariables,
  APITypes.ListCompanyIndividualsQuery
>;
export const individualsByCompanyId = /* GraphQL */ `query IndividualsByCompanyId(
  $companyId: ID!
  $individualId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  individualsByCompanyId(
    companyId: $companyId
    individualId: $individualId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      createdAt
      companyId
      individualId
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IndividualsByCompanyIdQueryVariables,
  APITypes.IndividualsByCompanyIdQuery
>;
export const individualsByIndividualId = /* GraphQL */ `query IndividualsByIndividualId(
  $individualId: ID!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  individualsByIndividualId(
    individualId: $individualId
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      createdAt
      companyId
      individualId
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IndividualsByIndividualIdQueryVariables,
  APITypes.IndividualsByIndividualIdQuery
>;
export const getCompanyPackage = /* GraphQL */ `query GetCompanyPackage($companyId: ID!, $packageId: ID!) {
  getCompanyPackage(companyId: $companyId, packageId: $packageId) {
    companyId
    packageId
    inherited
    public
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyPackageQueryVariables,
  APITypes.GetCompanyPackageQuery
>;
export const listCompanyPackages = /* GraphQL */ `query ListCompanyPackages(
  $companyId: ID
  $packageId: ModelIDKeyConditionInput
  $filter: ModelCompanyPackageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyPackages(
    companyId: $companyId
    packageId: $packageId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      packageId
      inherited
      public
      package {
        id
        categories
        name
        description
        variants
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyPackagesQueryVariables,
  APITypes.ListCompanyPackagesQuery
>;
export const getCompanyRelation = /* GraphQL */ `query GetCompanyRelation(
  $parentId: ID!
  $childId: ID!
  $relationship: CompanyRelationship!
) {
  getCompanyRelation(
    parentId: $parentId
    childId: $childId
    relationship: $relationship
  ) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyRelationQueryVariables,
  APITypes.GetCompanyRelationQuery
>;
export const listCompanyRelations = /* GraphQL */ `query ListCompanyRelations(
  $parentId: ID
  $childIdRelationship: ModelCompanyRelationPrimaryCompositeKeyConditionInput
  $filter: ModelCompanyRelationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyRelations(
    parentId: $parentId
    childIdRelationship: $childIdRelationship
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      parentId
      childId
      relationship
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyRelationsQueryVariables,
  APITypes.ListCompanyRelationsQuery
>;
export const getCompanyChildren = /* GraphQL */ `query GetCompanyChildren(
  $parentId: ID!
  $relationship: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  getCompanyChildren(
    parentId: $parentId
    relationship: $relationship
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      parentId
      childId
      relationship
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyChildrenQueryVariables,
  APITypes.GetCompanyChildrenQuery
>;
export const getCompanyParents = /* GraphQL */ `query GetCompanyParents(
  $childId: ID!
  $relationship: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  getCompanyParents(
    childId: $childId
    relationship: $relationship
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      parentId
      childId
      relationship
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyParentsQueryVariables,
  APITypes.GetCompanyParentsQuery
>;
export const getCompanyUser = /* GraphQL */ `query GetCompanyUser($cognitoIdentityId: ID!, $companyId: ID!) {
  getCompanyUser(cognitoIdentityId: $cognitoIdentityId, companyId: $companyId) {
    cognitoIdentityId
    companyId
    accountType
    user {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyUserQueryVariables,
  APITypes.GetCompanyUserQuery
>;
export const listCompanyUsers = /* GraphQL */ `query ListCompanyUsers(
  $cognitoIdentityId: ID
  $companyId: ModelIDKeyConditionInput
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyUsers(
    cognitoIdentityId: $cognitoIdentityId
    companyId: $companyId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cognitoIdentityId
      companyId
      accountType
      user {
        id
        cognitoId
        userCreatedBy
        createdAt
        updatedAt
        displayName
        email
        defaultLanguage
        status
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyUsersQueryVariables,
  APITypes.ListCompanyUsersQuery
>;
export const companyUserByCognitoIdentityId = /* GraphQL */ `query CompanyUserByCognitoIdentityId(
  $cognitoIdentityId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  companyUserByCognitoIdentityId(
    cognitoIdentityId: $cognitoIdentityId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      cognitoIdentityId
      companyId
      accountType
      user {
        id
        cognitoId
        userCreatedBy
        createdAt
        updatedAt
        displayName
        email
        defaultLanguage
        status
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyUserByCognitoIdentityIdQueryVariables,
  APITypes.CompanyUserByCognitoIdentityIdQuery
>;
export const companyUserByCompanyId = /* GraphQL */ `query CompanyUserByCompanyId(
  $companyId: ID!
  $cognitoIdentityId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  companyUserByCompanyId(
    companyId: $companyId
    cognitoIdentityId: $cognitoIdentityId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      cognitoIdentityId
      companyId
      accountType
      user {
        id
        cognitoId
        userCreatedBy
        createdAt
        updatedAt
        displayName
        email
        defaultLanguage
        status
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyUserByCompanyIdQueryVariables,
  APITypes.CompanyUserByCompanyIdQuery
>;
export const getCompanyUserPackage = /* GraphQL */ `query GetCompanyUserPackage(
  $companyId: ID!
  $cognitoIdentityId: ID!
  $packageId: ID!
) {
  getCompanyUserPackage(
    companyId: $companyId
    cognitoIdentityId: $cognitoIdentityId
    packageId: $packageId
  ) {
    companyId
    cognitoIdentityId
    packageId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyUserPackageQueryVariables,
  APITypes.GetCompanyUserPackageQuery
>;
export const listCompanyUserPackages = /* GraphQL */ `query ListCompanyUserPackages(
  $companyId: ID
  $cognitoIdentityIdPackageId: ModelCompanyUserPackagePrimaryCompositeKeyConditionInput
  $filter: ModelCompanyUserPackageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyUserPackages(
    companyId: $companyId
    cognitoIdentityIdPackageId: $cognitoIdentityIdPackageId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      cognitoIdentityId
      packageId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyUserPackagesQueryVariables,
  APITypes.ListCompanyUserPackagesQuery
>;
export const listCompanyUserPackagesByCompanyUser = /* GraphQL */ `query ListCompanyUserPackagesByCompanyUser(
  $cognitoIdentityId: ID!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserPackageFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyUserPackagesByCompanyUser(
    cognitoIdentityId: $cognitoIdentityId
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      companyId
      cognitoIdentityId
      packageId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyUserPackagesByCompanyUserQueryVariables,
  APITypes.ListCompanyUserPackagesByCompanyUserQuery
>;
export const getDefaultContent = /* GraphQL */ `query GetDefaultContent($id: ID!) {
  getDefaultContent(id: $id) {
    id
    type
    key
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDefaultContentQueryVariables,
  APITypes.GetDefaultContentQuery
>;
export const listDefaultContents = /* GraphQL */ `query ListDefaultContents(
  $filter: ModelDefaultContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDefaultContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      key
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDefaultContentsQueryVariables,
  APITypes.ListDefaultContentsQuery
>;
export const defaultContentByType = /* GraphQL */ `query DefaultContentByType(
  $type: ContentType!
  $sortDirection: ModelSortDirection
  $filter: ModelDefaultContentFilterInput
  $limit: Int
  $nextToken: String
) {
  defaultContentByType(
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      key
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DefaultContentByTypeQueryVariables,
  APITypes.DefaultContentByTypeQuery
>;
export const defaultContentByIdentifier = /* GraphQL */ `query DefaultContentByIdentifier(
  $type: ContentType!
  $key: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDefaultContentFilterInput
  $limit: Int
  $nextToken: String
) {
  defaultContentByIdentifier(
    type: $type
    key: $key
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      key
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DefaultContentByIdentifierQueryVariables,
  APITypes.DefaultContentByIdentifierQuery
>;
export const getIndividual = /* GraphQL */ `query GetIndividual($id: ID!) {
  getIndividual(id: $id) {
    id
    createdAt
    deletedAt
    email
    companyId
    dateOfBirth
    firstName
    gender
    contactNumber
    preferredName
    familyName
    title
    role
    jobTitle
    religion
    ethnicDesignation
    joinDate
    accountStatus
    appointments {
      Chair
      SID
      NonExecutive
      CommitteeChair
      Other
      __typename
    }
    ownedCompanies {
      items {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    companies {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    teams {
      items {
        companyId
        individualId
        teamId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndividualQueryVariables,
  APITypes.GetIndividualQuery
>;
export const listIndividuals = /* GraphQL */ `query ListIndividuals(
  $filter: ModelIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      deletedAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualsQueryVariables,
  APITypes.ListIndividualsQuery
>;
export const getIndividualByEmail = /* GraphQL */ `query GetIndividualByEmail(
  $email: String!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  getIndividualByEmail(
    email: $email
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      deletedAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndividualByEmailQueryVariables,
  APITypes.GetIndividualByEmailQuery
>;
export const getPackage = /* GraphQL */ `query GetPackage($id: ID!) {
  getPackage(id: $id) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPackageQueryVariables,
  APITypes.GetPackageQuery
>;
export const listPackages = /* GraphQL */ `query ListPackages(
  $filter: ModelPackageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPackagesQueryVariables,
  APITypes.ListPackagesQuery
>;
export const getPackageItem = /* GraphQL */ `query GetPackageItem($packageId: ID!, $type: PackageItemTypes!, $key: String!) {
  getPackageItem(packageId: $packageId, type: $type, key: $key) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPackageItemQueryVariables,
  APITypes.GetPackageItemQuery
>;
export const listPackageItems = /* GraphQL */ `query ListPackageItems(
  $packageId: ID
  $typeKey: ModelPackageItemPrimaryCompositeKeyConditionInput
  $filter: ModelPackageItemFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPackageItems(
    packageId: $packageId
    typeKey: $typeKey
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      packageId
      type
      key
      content
      package {
        id
        categories
        name
        description
        variants
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPackageItemsQueryVariables,
  APITypes.ListPackageItemsQuery
>;
export const packageItemsByPackageId = /* GraphQL */ `query PackageItemsByPackageId(
  $packageId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPackageItemFilterInput
  $limit: Int
  $nextToken: String
) {
  packageItemsByPackageId(
    packageId: $packageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      packageId
      type
      key
      content
      package {
        id
        categories
        name
        description
        variants
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PackageItemsByPackageIdQueryVariables,
  APITypes.PackageItemsByPackageIdQuery
>;
export const getQuestionnaire = /* GraphQL */ `query GetQuestionnaire($id: ID!) {
  getQuestionnaire(id: $id) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionnaireQueryVariables,
  APITypes.GetQuestionnaireQuery
>;
export const listQuestionnaires = /* GraphQL */ `query ListQuestionnaires(
  $filter: ModelQuestionnaireFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionnairesQueryVariables,
  APITypes.ListQuestionnairesQuery
>;
export const questionnairesByCompanyId = /* GraphQL */ `query QuestionnairesByCompanyId(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuestionnaireFilterInput
  $limit: Int
  $nextToken: String
) {
  questionnairesByCompanyId(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuestionnairesByCompanyIdQueryVariables,
  APITypes.QuestionnairesByCompanyIdQuery
>;
export const getReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    companyId
    surveyGroupId
    reportGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    variantCode
    owner
    summary
    focusAreas
    configuration
    sections {
      items {
        id
        companyId
        reportId
        sectionId
        variantCode
        summary
        introduction
        results
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    documents {
      items {
        id
        reportId
        requesterId
        companyId
        targetIndividualId
        variantCode
        surveyGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        queuedAt
        deletedAt
        startedProcessingAt
        finishedAt
        failedAt
        key
        template
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const reportBySurveyGroupId = /* GraphQL */ `query ReportBySurveyGroupId(
  $surveyGroupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportBySurveyGroupId(
    surveyGroupId: $surveyGroupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportBySurveyGroupIdQueryVariables,
  APITypes.ReportBySurveyGroupIdQuery
>;
export const listReportsByReportGroup = /* GraphQL */ `query ListReportsByReportGroup(
  $reportGroupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportsByReportGroup(
    reportGroupId: $reportGroupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsByReportGroupQueryVariables,
  APITypes.ListReportsByReportGroupQuery
>;
export const getReportDocument = /* GraphQL */ `query GetReportDocument($id: ID!) {
  getReportDocument(id: $id) {
    id
    reportId
    requesterId
    companyId
    targetIndividualId
    variantCode
    surveyGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    queuedAt
    deletedAt
    startedProcessingAt
    finishedAt
    failedAt
    key
    template
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportDocumentQueryVariables,
  APITypes.GetReportDocumentQuery
>;
export const listReportDocuments = /* GraphQL */ `query ListReportDocuments(
  $filter: ModelReportDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reportId
      requesterId
      companyId
      targetIndividualId
      variantCode
      surveyGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      queuedAt
      deletedAt
      startedProcessingAt
      finishedAt
      failedAt
      key
      template
      report {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportDocumentsQueryVariables,
  APITypes.ListReportDocumentsQuery
>;
export const documentsByReportId = /* GraphQL */ `query DocumentsByReportId(
  $reportId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReportDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  documentsByReportId(
    reportId: $reportId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reportId
      requesterId
      companyId
      targetIndividualId
      variantCode
      surveyGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      queuedAt
      deletedAt
      startedProcessingAt
      finishedAt
      failedAt
      key
      template
      report {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DocumentsByReportIdQueryVariables,
  APITypes.DocumentsByReportIdQuery
>;
export const reportDocumentsByDate = /* GraphQL */ `query ReportDocumentsByDate(
  $surveyGroupId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  reportDocumentsByDate(
    surveyGroupId: $surveyGroupId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reportId
      requesterId
      companyId
      targetIndividualId
      variantCode
      surveyGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      queuedAt
      deletedAt
      startedProcessingAt
      finishedAt
      failedAt
      key
      template
      report {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportDocumentsByDateQueryVariables,
  APITypes.ReportDocumentsByDateQuery
>;
export const getReportGroup = /* GraphQL */ `query GetReportGroup($id: ID!) {
  getReportGroup(id: $id) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportGroupQueryVariables,
  APITypes.GetReportGroupQuery
>;
export const listReportGroups = /* GraphQL */ `query ListReportGroups(
  $id: ID
  $filter: ModelReportGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReportGroups(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      surveyGroupId
      companyId
      name
      variants {
        name
        variantCode
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      configuration {
        hideComparisonResponses
        combineComparisonResponses
        __typename
      }
      scores
      quartiles
      reports {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportGroupsQueryVariables,
  APITypes.ListReportGroupsQuery
>;
export const listReportGroupsBySurveyGroup = /* GraphQL */ `query ListReportGroupsBySurveyGroup(
  $surveyGroupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReportGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportGroupsBySurveyGroup(
    surveyGroupId: $surveyGroupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      surveyGroupId
      companyId
      name
      variants {
        name
        variantCode
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      configuration {
        hideComparisonResponses
        combineComparisonResponses
        __typename
      }
      scores
      quartiles
      reports {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportGroupsBySurveyGroupQueryVariables,
  APITypes.ListReportGroupsBySurveyGroupQuery
>;
export const listReportGroupsByCompany = /* GraphQL */ `query ListReportGroupsByCompany(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportGroupsByCompany(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      surveyGroupId
      companyId
      name
      variants {
        name
        variantCode
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      configuration {
        hideComparisonResponses
        combineComparisonResponses
        __typename
      }
      scores
      quartiles
      reports {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportGroupsByCompanyQueryVariables,
  APITypes.ListReportGroupsByCompanyQuery
>;
export const getReportSection = /* GraphQL */ `query GetReportSection($id: ID!) {
  getReportSection(id: $id) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportSectionQueryVariables,
  APITypes.GetReportSectionQuery
>;
export const listReportSections = /* GraphQL */ `query ListReportSections(
  $filter: ModelReportSectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      reportId
      sectionId
      variantCode
      summary
      introduction
      subSectionIntroductions {
        slug
        value
        reviewedAt
        __typename
      }
      results
      report {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportSectionsQueryVariables,
  APITypes.ListReportSectionsQuery
>;
export const reportSectionsBySectionId = /* GraphQL */ `query ReportSectionsBySectionId(
  $reportId: ID!
  $sectionId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportSectionFilterInput
  $limit: Int
  $nextToken: String
) {
  reportSectionsBySectionId(
    reportId: $reportId
    sectionId: $sectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      reportId
      sectionId
      variantCode
      summary
      introduction
      subSectionIntroductions {
        slug
        value
        reviewedAt
        __typename
      }
      results
      report {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportSectionsBySectionIdQueryVariables,
  APITypes.ReportSectionsBySectionIdQuery
>;
export const getSection = /* GraphQL */ `query GetSection($id: ID!, $variantCode: String!) {
  getSection(id: $id, variantCode: $variantCode) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSectionQueryVariables,
  APITypes.GetSectionQuery
>;
export const listSections = /* GraphQL */ `query ListSections(
  $id: ID
  $variantCode: ModelStringKeyConditionInput
  $filter: ModelSectionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSections(
    id: $id
    variantCode: $variantCode
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      masterSectionId
      variantCode
      questionnaireId
      companyId
      createdAt
      name
      slug
      displayOrder
      blocks {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSectionsQueryVariables,
  APITypes.ListSectionsQuery
>;
export const sectionsByQuestionnaireId = /* GraphQL */ `query SectionsByQuestionnaireId(
  $questionnaireId: ID!
  $variantCode: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSectionFilterInput
  $limit: Int
  $nextToken: String
) {
  sectionsByQuestionnaireId(
    questionnaireId: $questionnaireId
    variantCode: $variantCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      masterSectionId
      variantCode
      questionnaireId
      companyId
      createdAt
      name
      slug
      displayOrder
      blocks {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SectionsByQuestionnaireIdQueryVariables,
  APITypes.SectionsByQuestionnaireIdQuery
>;
export const getStatistic = /* GraphQL */ `query GetStatistic($collection: String!, $category: String!, $companyId: ID!) {
  getStatistic(
    collection: $collection
    category: $category
    companyId: $companyId
  ) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatisticQueryVariables,
  APITypes.GetStatisticQuery
>;
export const listStatistics = /* GraphQL */ `query ListStatistics(
  $collection: String
  $categoryCompanyId: ModelStatisticPrimaryCompositeKeyConditionInput
  $filter: ModelStatisticFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStatistics(
    collection: $collection
    categoryCompanyId: $categoryCompanyId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      collection
      category
      companyId
      results {
        key
        name
        value
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStatisticsQueryVariables,
  APITypes.ListStatisticsQuery
>;
export const getStatisticTotal = /* GraphQL */ `query GetStatisticTotal($collection: String!, $category: String!) {
  getStatisticTotal(collection: $collection, category: $category) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatisticTotalQueryVariables,
  APITypes.GetStatisticTotalQuery
>;
export const listStatisticTotals = /* GraphQL */ `query ListStatisticTotals(
  $collection: String
  $category: ModelStringKeyConditionInput
  $filter: ModelStatisticTotalFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStatisticTotals(
    collection: $collection
    category: $category
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      collection
      category
      resultCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStatisticTotalsQueryVariables,
  APITypes.ListStatisticTotalsQuery
>;
export const getSurvey = /* GraphQL */ `query GetSurvey($id: ID!) {
  getSurvey(id: $id) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      deletedAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSurveyQueryVariables, APITypes.GetSurveyQuery>;
export const listSurveys = /* GraphQL */ `query ListSurveys(
  $id: ID
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSurveys(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      groupId
      companyId
      questionnaireId
      individualId
      owner
      key
      variantCode
      dispatchedAt
      finishedAt
      deletedAt
      totals {
        questions
        questionsCompleted
        __typename
      }
      response
      status
      tags
      invitation
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      questionnaire {
        id
        createdAt
        deletedAt
        companyId
        name
        status
        groups
        customContent
        packageId
        editDisabled
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveysQueryVariables,
  APITypes.ListSurveysQuery
>;
export const surveysByGroupId = /* GraphQL */ `query SurveysByGroupId(
  $groupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  surveysByGroupId(
    groupId: $groupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      groupId
      companyId
      questionnaireId
      individualId
      owner
      key
      variantCode
      dispatchedAt
      finishedAt
      deletedAt
      totals {
        questions
        questionsCompleted
        __typename
      }
      response
      status
      tags
      invitation
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      questionnaire {
        id
        createdAt
        deletedAt
        companyId
        name
        status
        groups
        customContent
        packageId
        editDisabled
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SurveysByGroupIdQueryVariables,
  APITypes.SurveysByGroupIdQuery
>;
export const surveysByQuestionnaireId = /* GraphQL */ `query SurveysByQuestionnaireId(
  $questionnaireId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  surveysByQuestionnaireId(
    questionnaireId: $questionnaireId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      groupId
      companyId
      questionnaireId
      individualId
      owner
      key
      variantCode
      dispatchedAt
      finishedAt
      deletedAt
      totals {
        questions
        questionsCompleted
        __typename
      }
      response
      status
      tags
      invitation
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      questionnaire {
        id
        createdAt
        deletedAt
        companyId
        name
        status
        groups
        customContent
        packageId
        editDisabled
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SurveysByQuestionnaireIdQueryVariables,
  APITypes.SurveysByQuestionnaireIdQuery
>;
export const surveysByOwner = /* GraphQL */ `query SurveysByOwner(
  $owner: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  surveysByOwner(
    owner: $owner
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      groupId
      companyId
      questionnaireId
      individualId
      owner
      key
      variantCode
      dispatchedAt
      finishedAt
      deletedAt
      totals {
        questions
        questionsCompleted
        __typename
      }
      response
      status
      tags
      invitation
      individual {
        id
        createdAt
        deletedAt
        email
        companyId
        dateOfBirth
        firstName
        gender
        contactNumber
        preferredName
        familyName
        title
        role
        jobTitle
        religion
        ethnicDesignation
        joinDate
        accountStatus
        updatedAt
        __typename
      }
      questionnaire {
        id
        createdAt
        deletedAt
        companyId
        name
        status
        groups
        customContent
        packageId
        editDisabled
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SurveysByOwnerQueryVariables,
  APITypes.SurveysByOwnerQuery
>;
export const getSurveyGroup = /* GraphQL */ `query GetSurveyGroup($id: ID!) {
  getSurveyGroup(id: $id) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyGroupQueryVariables,
  APITypes.GetSurveyGroupQuery
>;
export const listSurveyGroups = /* GraphQL */ `query ListSurveyGroups(
  $id: ID
  $filter: ModelSurveyGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSurveyGroups(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      questionnaireId
      companyId
      packageId
      targetIndividualIds
      name
      variants {
        name
        variantCode
        __typename
      }
      status
      createdAt
      updatedAt
      releasedAt
      dispatchedAt
      deletedAt
      tags
      includeInStats
      surveys {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyGroupsQueryVariables,
  APITypes.ListSurveyGroupsQuery
>;
export const listSurveyGroupsByQuestionnaire = /* GraphQL */ `query ListSurveyGroupsByQuestionnaire(
  $questionnaireId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listSurveyGroupsByQuestionnaire(
    questionnaireId: $questionnaireId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionnaireId
      companyId
      packageId
      targetIndividualIds
      name
      variants {
        name
        variantCode
        __typename
      }
      status
      createdAt
      updatedAt
      releasedAt
      dispatchedAt
      deletedAt
      tags
      includeInStats
      surveys {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyGroupsByQuestionnaireQueryVariables,
  APITypes.ListSurveyGroupsByQuestionnaireQuery
>;
export const listSurveyGroupsByCompany = /* GraphQL */ `query ListSurveyGroupsByCompany(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listSurveyGroupsByCompany(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionnaireId
      companyId
      packageId
      targetIndividualIds
      name
      variants {
        name
        variantCode
        __typename
      }
      status
      createdAt
      updatedAt
      releasedAt
      dispatchedAt
      deletedAt
      tags
      includeInStats
      surveys {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyGroupsByCompanyQueryVariables,
  APITypes.ListSurveyGroupsByCompanyQuery
>;
export const listSurveyGroupsByPackage = /* GraphQL */ `query ListSurveyGroupsByPackage(
  $packageId: ID!
  $companyIdCreatedAt: ModelSurveyGroupByPackageCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listSurveyGroupsByPackage(
    packageId: $packageId
    companyIdCreatedAt: $companyIdCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionnaireId
      companyId
      packageId
      targetIndividualIds
      name
      variants {
        name
        variantCode
        __typename
      }
      status
      createdAt
      updatedAt
      releasedAt
      dispatchedAt
      deletedAt
      tags
      includeInStats
      surveys {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyGroupsByPackageQueryVariables,
  APITypes.ListSurveyGroupsByPackageQuery
>;
export const getTeamIndividual = /* GraphQL */ `query GetTeamIndividual(
  $companyId: ID!
  $teamId: CompanyTeamTypes!
  $individualId: ID!
) {
  getTeamIndividual(
    companyId: $companyId
    teamId: $teamId
    individualId: $individualId
  ) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamIndividualQueryVariables,
  APITypes.GetTeamIndividualQuery
>;
export const listTeamIndividuals = /* GraphQL */ `query ListTeamIndividuals(
  $companyId: ID
  $teamIdIndividualId: ModelTeamIndividualPrimaryCompositeKeyConditionInput
  $filter: ModelTeamIndividualFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTeamIndividuals(
    companyId: $companyId
    teamIdIndividualId: $teamIdIndividualId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      individualId
      teamId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamIndividualsQueryVariables,
  APITypes.ListTeamIndividualsQuery
>;
export const listIndividualTeams = /* GraphQL */ `query ListIndividualTeams(
  $individualId: ID!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualTeams(
    individualId: $individualId
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      companyId
      individualId
      teamId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualTeamsQueryVariables,
  APITypes.ListIndividualTeamsQuery
>;
