/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateBlockInput = {
  id?: string | null,
  variantCode: string,
  sectionId: string,
  groupId?: string | null,
  companyId: string,
  createdAt?: string | null,
  variant?: BlockType | null,
  data: string,
  displayOrder: number,
};

export enum BlockType {
  Question = "Question",
  Markdown = "Markdown",
}


export type ModelBlockConditionInput = {
  sectionId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  variant?: ModelBlockTypeInput | null,
  data?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  and?: Array< ModelBlockConditionInput | null > | null,
  or?: Array< ModelBlockConditionInput | null > | null,
  not?: ModelBlockConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBlockTypeInput = {
  eq?: BlockType | null,
  ne?: BlockType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Block = {
  __typename: "Block",
  id: string,
  variantCode: string,
  sectionId: string,
  groupId?: string | null,
  companyId: string,
  createdAt: string,
  variant?: BlockType | null,
  data: string,
  displayOrder: number,
  updatedAt: string,
};

export type UpdateBlockInput = {
  id: string,
  variantCode: string,
  sectionId?: string | null,
  groupId?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
  variant?: BlockType | null,
  data?: string | null,
  displayOrder?: number | null,
};

export type DeleteBlockInput = {
  id: string,
  variantCode: string,
};

export type CreateCognitoIdentityInput = {
  id?: string | null,
  cognitoId: string,
  userCreatedBy: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  displayName: string,
  email: string,
  defaultLanguage: LanguageCode,
  avatar?: S3ObjectInput | null,
  status?: UserAccountStatus | null,
};

export enum LanguageCode {
  en = "en",
  de = "de",
  us = "us",
}


export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export enum UserAccountStatus {
  Created = "Created",
  Invited = "Invited",
  Enabled = "Enabled",
  EnabledPasswordReset = "EnabledPasswordReset",
  DisabledPasswordReset = "DisabledPasswordReset",
}


export type ModelCognitoIdentityConditionInput = {
  cognitoId?: ModelIDInput | null,
  userCreatedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  defaultLanguage?: ModelLanguageCodeInput | null,
  status?: ModelUserAccountStatusInput | null,
  and?: Array< ModelCognitoIdentityConditionInput | null > | null,
  or?: Array< ModelCognitoIdentityConditionInput | null > | null,
  not?: ModelCognitoIdentityConditionInput | null,
};

export type ModelLanguageCodeInput = {
  eq?: LanguageCode | null,
  ne?: LanguageCode | null,
};

export type ModelUserAccountStatusInput = {
  eq?: UserAccountStatus | null,
  ne?: UserAccountStatus | null,
};

export type CognitoIdentity = {
  __typename: "CognitoIdentity",
  id: string,
  cognitoId: string,
  userCreatedBy: string,
  createdAt: string,
  updatedAt: string,
  displayName: string,
  email: string,
  defaultLanguage: LanguageCode,
  avatar?: S3Object | null,
  companies?: ModelCompanyUserConnection | null,
  status?: UserAccountStatus | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type ModelCompanyUserConnection = {
  __typename: "ModelCompanyUserConnection",
  items:  Array<CompanyUser | null >,
  nextToken?: string | null,
};

export type CompanyUser = {
  __typename: "CompanyUser",
  cognitoIdentityId: string,
  companyId: string,
  accountType: AccountType,
  user?: CognitoIdentity | null,
  company?: Company | null,
  createdAt: string,
  updatedAt: string,
};

export enum AccountType {
  Admin = "Admin",
  Assessor = "Assessor",
  BoardMember = "BoardMember",
  CompanySecretary = "CompanySecretary",
}


export type Company = {
  __typename: "Company",
  id: string,
  owner: string,
  parentId?: string | null,
  name: string,
  createdAt: string,
  deletedAt?: string | null,
  companyType?: CompanyType | null,
  address?: Address | null,
  phoneNumber?: string | null,
  registrationNumber?: string | null,
  numberOfEmployees?: string | null,
  brandLogo?: string | null,
  sector?: string | null,
  ownershipStructure?: string | null,
  country?: string | null,
  employeeCount?: string | null,
  marketCap?: string | null,
  configuration?: CompanyConfiguration | null,
  tags?: Array< string > | null,
  individuals?: ModelCompanyIndividualConnection | null,
  committees?: ModelCommitteeConnection | null,
  users?: ModelCompanyUserConnection | null,
  parent?: Company | null,
  updatedAt: string,
};

export enum CompanyType {
  Reseller = "Reseller",
}


export type Address = {
  __typename: "Address",
  line1?: string | null,
  line2?: string | null,
  city?: string | null,
  region?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

export type CompanyConfiguration = {
  __typename: "CompanyConfiguration",
  primaryColor?: string | null,
  secondaryColor?: string | null,
  questionnaire?: string | null,
  admin?: string | null,
  report?: string | null,
};

export type ModelCompanyIndividualConnection = {
  __typename: "ModelCompanyIndividualConnection",
  items:  Array<CompanyIndividual | null >,
  nextToken?: string | null,
};

export type CompanyIndividual = {
  __typename: "CompanyIndividual",
  createdAt: string,
  companyId: string,
  individualId: string,
  individual?: Individual | null,
  company?: Company | null,
  updatedAt: string,
};

export type Individual = {
  __typename: "Individual",
  id: string,
  createdAt: string,
  deletedAt?: string | null,
  email: string,
  companyId: string,
  dateOfBirth?: string | null,
  firstName?: string | null,
  gender?: string | null,
  contactNumber?: string | null,
  preferredName?: string | null,
  familyName?: string | null,
  title?: string | null,
  role?: UserType | null,
  jobTitle?: string | null,
  religion?: Religion | null,
  ethnicDesignation?: EthnicDesignation | null,
  joinDate?: string | null,
  accountStatus?: UserAccountStatus | null,
  appointments?: IndividualAppointments | null,
  ownedCompanies?: ModelCompanyConnection | null,
  companies?: ModelCompanyIndividualConnection | null,
  committees?: ModelCommitteeIndividualConnection | null,
  teams?: ModelTeamIndividualConnection | null,
  updatedAt: string,
};

export enum UserType {
  Chair = "Chair",
  DeputyChair = "DeputyChair",
  ViceChair = "ViceChair",
  SID = "SID",
  NED = "NED",
  AssociateNED = "AssociateNED",
  OtherNonExecutive = "OtherNonExecutive",
  MD = "MD",
  CEO = "CEO",
  CFO = "CFO",
  CIO = "CIO",
  COO = "COO",
  CMO = "CMO",
  CPO = "CPO",
  CSO = "CSO",
  CNO = "CNO",
  ShareholderRepresentative = "ShareholderRepresentative",
  LabourRepresentative = "LabourRepresentative",
  OtherExecutive = "OtherExecutive",
  InternalInvestor = "InternalInvestor",
  InternalUnion = "InternalUnion",
  InternalStakeholder = "InternalStakeholder",
  ExternalInvestor = "ExternalInvestor",
  ExternalUnion = "ExternalUnion",
  ExternalNGO = "ExternalNGO",
  ExternalStakeholder = "ExternalStakeholder",
  CompanySecretary = "CompanySecretary",
  Auditor = "Auditor",
  RemunerationConsultant = "RemunerationConsultant",
  ExecutiveSearchConsultant = "ExecutiveSearchConsultant",
}


export enum Religion {
  None = "None",
  Christian = "Christian",
  Buddhist = "Buddhist",
  Hindu = "Hindu",
  Jewish = "Jewish",
  Muslim = "Muslim",
  Sikh = "Sikh",
  Other = "Other",
}


export enum EthnicDesignation {
  EnglishWelshScottishNIBritish = "EnglishWelshScottishNIBritish",
  WhiteEuropean = "WhiteEuropean",
  WhiteAmerican = "WhiteAmerican",
  Irish = "Irish",
  OtherWhite = "OtherWhite",
  MixedEthnicityBritish = "MixedEthnicityBritish",
  MixedEthnicityEuropean = "MixedEthnicityEuropean",
  MixedEthnicityAmerican = "MixedEthnicityAmerican",
  WhiteBlackCaribbean = "WhiteBlackCaribbean",
  WhiteBlackAfrican = "WhiteBlackAfrican",
  WhiteAndAsian = "WhiteAndAsian",
  OtherMixed = "OtherMixed",
  AsianBritish = "AsianBritish",
  AsianEuropean = "AsianEuropean",
  AsianAmerican = "AsianAmerican",
  Indian = "Indian",
  Pakistani = "Pakistani",
  Bangladeshi = "Bangladeshi",
  Chinese = "Chinese",
  OtherAsianBackground = "OtherAsianBackground",
  BlackBritish = "BlackBritish",
  BlackEuropean = "BlackEuropean",
  BlackAmerican = "BlackAmerican",
  African = "African",
  Caribbean = "Caribbean",
  OtherBlackAfricanCaribbean = "OtherBlackAfricanCaribbean",
  Arab = "Arab",
  Other = "Other",
}


export type IndividualAppointments = {
  __typename: "IndividualAppointments",
  Chair: number,
  SID: number,
  NonExecutive: number,
  CommitteeChair: number,
  Other: number,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelCommitteeIndividualConnection = {
  __typename: "ModelCommitteeIndividualConnection",
  items:  Array<CommitteeIndividual | null >,
  nextToken?: string | null,
};

export type CommitteeIndividual = {
  __typename: "CommitteeIndividual",
  createdAt: string,
  companyId: string,
  committeeId: string,
  individualId: string,
  individual?: Individual | null,
  committee?: Committee | null,
  updatedAt: string,
};

export type Committee = {
  __typename: "Committee",
  id: string,
  companyId: string,
  name: string,
  tags?: Array< CommitteeTag | null > | null,
  individuals?: ModelCommitteeIndividualConnection | null,
  company?: Company | null,
  createdAt: string,
  updatedAt: string,
};

export enum CommitteeTag {
  Audit = "Audit",
  Remuneration = "Remuneration",
  Compensation = "Compensation",
  Nomination = "Nomination",
  Other = "Other",
}


export type ModelTeamIndividualConnection = {
  __typename: "ModelTeamIndividualConnection",
  items:  Array<TeamIndividual | null >,
  nextToken?: string | null,
};

export type TeamIndividual = {
  __typename: "TeamIndividual",
  companyId: string,
  individualId: string,
  teamId: CompanyTeamTypes,
  createdAt: string,
  updatedAt: string,
};

export enum CompanyTeamTypes {
  Board = "Board",
  Executive = "Executive",
  Stakeholder = "Stakeholder",
}


export type ModelCommitteeConnection = {
  __typename: "ModelCommitteeConnection",
  items:  Array<Committee | null >,
  nextToken?: string | null,
};

export type UpdateCognitoIdentityInput = {
  id: string,
  cognitoId?: string | null,
  userCreatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  displayName?: string | null,
  email?: string | null,
  defaultLanguage?: LanguageCode | null,
  avatar?: S3ObjectInput | null,
  status?: UserAccountStatus | null,
};

export type DeleteCognitoIdentityInput = {
  id: string,
};

export type CreateCommitteeInput = {
  id?: string | null,
  companyId: string,
  name: string,
  tags?: Array< CommitteeTag | null > | null,
};

export type ModelCommitteeConditionInput = {
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  tags?: ModelCommitteeTagListInput | null,
  and?: Array< ModelCommitteeConditionInput | null > | null,
  or?: Array< ModelCommitteeConditionInput | null > | null,
  not?: ModelCommitteeConditionInput | null,
};

export type ModelCommitteeTagListInput = {
  eq?: Array< CommitteeTag | null > | null,
  ne?: Array< CommitteeTag | null > | null,
  contains?: CommitteeTag | null,
  notContains?: CommitteeTag | null,
};

export type UpdateCommitteeInput = {
  id: string,
  companyId?: string | null,
  name?: string | null,
  tags?: Array< CommitteeTag | null > | null,
};

export type DeleteCommitteeInput = {
  id: string,
};

export type CreateCommitteeIndividualInput = {
  createdAt?: string | null,
  companyId: string,
  committeeId: string,
  individualId: string,
};

export type ModelCommitteeIndividualConditionInput = {
  createdAt?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelCommitteeIndividualConditionInput | null > | null,
  or?: Array< ModelCommitteeIndividualConditionInput | null > | null,
  not?: ModelCommitteeIndividualConditionInput | null,
};

export type UpdateCommitteeIndividualInput = {
  createdAt?: string | null,
  companyId?: string | null,
  committeeId: string,
  individualId: string,
};

export type DeleteCommitteeIndividualInput = {
  individualId: string,
  committeeId: string,
};

export type CreateCompanyInput = {
  id?: string | null,
  owner: string,
  parentId?: string | null,
  name: string,
  createdAt?: string | null,
  deletedAt?: string | null,
  companyType?: CompanyType | null,
  address?: AddressInput | null,
  phoneNumber?: string | null,
  registrationNumber?: string | null,
  numberOfEmployees?: string | null,
  brandLogo?: string | null,
  sector?: string | null,
  ownershipStructure?: string | null,
  country?: string | null,
  employeeCount?: string | null,
  marketCap?: string | null,
  configuration?: CompanyConfigurationInput | null,
  tags?: Array< string > | null,
};

export type AddressInput = {
  line1?: string | null,
  line2?: string | null,
  city?: string | null,
  region?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

export type CompanyConfigurationInput = {
  primaryColor?: string | null,
  secondaryColor?: string | null,
  questionnaire?: string | null,
  admin?: string | null,
  report?: string | null,
};

export type ModelCompanyConditionInput = {
  owner?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  companyType?: ModelCompanyTypeInput | null,
  phoneNumber?: ModelStringInput | null,
  registrationNumber?: ModelStringInput | null,
  numberOfEmployees?: ModelStringInput | null,
  brandLogo?: ModelStringInput | null,
  sector?: ModelStringInput | null,
  ownershipStructure?: ModelStringInput | null,
  country?: ModelStringInput | null,
  employeeCount?: ModelStringInput | null,
  marketCap?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
};

export type ModelCompanyTypeInput = {
  eq?: CompanyType | null,
  ne?: CompanyType | null,
};

export type UpdateCompanyInput = {
  id: string,
  owner?: string | null,
  parentId?: string | null,
  name?: string | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  companyType?: CompanyType | null,
  address?: AddressInput | null,
  phoneNumber?: string | null,
  registrationNumber?: string | null,
  numberOfEmployees?: string | null,
  brandLogo?: string | null,
  sector?: string | null,
  ownershipStructure?: string | null,
  country?: string | null,
  employeeCount?: string | null,
  marketCap?: string | null,
  configuration?: CompanyConfigurationInput | null,
  tags?: Array< string > | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CreateCompanyIndividualInput = {
  createdAt?: string | null,
  companyId: string,
  individualId: string,
};

export type ModelCompanyIndividualConditionInput = {
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCompanyIndividualConditionInput | null > | null,
  or?: Array< ModelCompanyIndividualConditionInput | null > | null,
  not?: ModelCompanyIndividualConditionInput | null,
};

export type UpdateCompanyIndividualInput = {
  createdAt?: string | null,
  companyId: string,
  individualId: string,
};

export type DeleteCompanyIndividualInput = {
  individualId: string,
  companyId: string,
};

export type CreateCompanyPackageInput = {
  companyId: string,
  packageId: string,
  inherited: boolean,
  public: boolean,
};

export type ModelCompanyPackageConditionInput = {
  inherited?: ModelBooleanInput | null,
  public?: ModelBooleanInput | null,
  and?: Array< ModelCompanyPackageConditionInput | null > | null,
  or?: Array< ModelCompanyPackageConditionInput | null > | null,
  not?: ModelCompanyPackageConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CompanyPackage = {
  __typename: "CompanyPackage",
  companyId: string,
  packageId: string,
  inherited: boolean,
  public: boolean,
  package?: Package | null,
  createdAt: string,
  updatedAt: string,
};

export type Package = {
  __typename: "Package",
  id: string,
  categories: Array< PackageCategories | null >,
  name: string,
  description?: string | null,
  variants: Array< string | null >,
  packageItems?: ModelPackageItemConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum PackageCategories {
  Board = "Board",
  Executive = "Executive",
  Stakeholder = "Stakeholder",
}


export type ModelPackageItemConnection = {
  __typename: "ModelPackageItemConnection",
  items:  Array<PackageItem | null >,
  nextToken?: string | null,
};

export type PackageItem = {
  __typename: "PackageItem",
  packageId: string,
  type: PackageItemTypes,
  key: string,
  content: string,
  package?: Package | null,
  createdAt: string,
  updatedAt: string,
};

export enum PackageItemTypes {
  Questionnaire = "Questionnaire",
  Role = "Role",
  KnowHow = "KnowHow",
  SampleReport = "SampleReport",
  ReportSummary = "ReportSummary",
  ReportConfiguration = "ReportConfiguration",
  WizardConfiguration = "WizardConfiguration",
  SurveyConfiguration = "SurveyConfiguration",
  Relations = "Relations",
  OptionalReportContent = "OptionalReportContent",
}


export type UpdateCompanyPackageInput = {
  companyId: string,
  packageId: string,
  inherited?: boolean | null,
  public?: boolean | null,
};

export type DeleteCompanyPackageInput = {
  companyId: string,
  packageId: string,
};

export type CreateCompanyRelationInput = {
  parentId: string,
  childId: string,
  relationship: CompanyRelationship,
};

export enum CompanyRelationship {
  Reseller = "Reseller",
}


export type ModelCompanyRelationConditionInput = {
  and?: Array< ModelCompanyRelationConditionInput | null > | null,
  or?: Array< ModelCompanyRelationConditionInput | null > | null,
  not?: ModelCompanyRelationConditionInput | null,
};

export type CompanyRelation = {
  __typename: "CompanyRelation",
  parentId: string,
  childId: string,
  relationship: CompanyRelationship,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCompanyRelationInput = {
  parentId: string,
  childId: string,
  relationship: CompanyRelationship,
};

export type DeleteCompanyRelationInput = {
  parentId: string,
  childId: string,
  relationship: CompanyRelationship,
};

export type CreateCompanyUserInput = {
  cognitoIdentityId: string,
  companyId: string,
  accountType: AccountType,
};

export type ModelCompanyUserConditionInput = {
  accountType?: ModelAccountTypeInput | null,
  and?: Array< ModelCompanyUserConditionInput | null > | null,
  or?: Array< ModelCompanyUserConditionInput | null > | null,
  not?: ModelCompanyUserConditionInput | null,
};

export type ModelAccountTypeInput = {
  eq?: AccountType | null,
  ne?: AccountType | null,
};

export type UpdateCompanyUserInput = {
  cognitoIdentityId: string,
  companyId: string,
  accountType?: AccountType | null,
};

export type DeleteCompanyUserInput = {
  cognitoIdentityId: string,
  companyId: string,
};

export type CreateCompanyUserPackageInput = {
  companyId: string,
  cognitoIdentityId: string,
  packageId: string,
};

export type ModelCompanyUserPackageConditionInput = {
  and?: Array< ModelCompanyUserPackageConditionInput | null > | null,
  or?: Array< ModelCompanyUserPackageConditionInput | null > | null,
  not?: ModelCompanyUserPackageConditionInput | null,
};

export type CompanyUserPackage = {
  __typename: "CompanyUserPackage",
  companyId: string,
  cognitoIdentityId: string,
  packageId: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCompanyUserPackageInput = {
  companyId: string,
  cognitoIdentityId: string,
  packageId: string,
};

export type DeleteCompanyUserPackageInput = {
  companyId: string,
  cognitoIdentityId: string,
  packageId: string,
};

export type CreateIndividualInput = {
  id?: string | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  email: string,
  companyId: string,
  dateOfBirth?: string | null,
  firstName?: string | null,
  gender?: string | null,
  contactNumber?: string | null,
  preferredName?: string | null,
  familyName?: string | null,
  title?: string | null,
  role?: UserType | null,
  jobTitle?: string | null,
  religion?: Religion | null,
  ethnicDesignation?: EthnicDesignation | null,
  joinDate?: string | null,
  accountStatus?: UserAccountStatus | null,
  appointments?: IndividualAppointmentsInput | null,
};

export type IndividualAppointmentsInput = {
  Chair: number,
  SID: number,
  NonExecutive: number,
  CommitteeChair: number,
  Other: number,
};

export type ModelIndividualConditionInput = {
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  dateOfBirth?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  preferredName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  role?: ModelUserTypeInput | null,
  jobTitle?: ModelStringInput | null,
  religion?: ModelReligionInput | null,
  ethnicDesignation?: ModelEthnicDesignationInput | null,
  joinDate?: ModelStringInput | null,
  accountStatus?: ModelUserAccountStatusInput | null,
  and?: Array< ModelIndividualConditionInput | null > | null,
  or?: Array< ModelIndividualConditionInput | null > | null,
  not?: ModelIndividualConditionInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelReligionInput = {
  eq?: Religion | null,
  ne?: Religion | null,
};

export type ModelEthnicDesignationInput = {
  eq?: EthnicDesignation | null,
  ne?: EthnicDesignation | null,
};

export type UpdateIndividualInput = {
  id: string,
  createdAt?: string | null,
  deletedAt?: string | null,
  email?: string | null,
  companyId?: string | null,
  dateOfBirth?: string | null,
  firstName?: string | null,
  gender?: string | null,
  contactNumber?: string | null,
  preferredName?: string | null,
  familyName?: string | null,
  title?: string | null,
  role?: UserType | null,
  jobTitle?: string | null,
  religion?: Religion | null,
  ethnicDesignation?: EthnicDesignation | null,
  joinDate?: string | null,
  accountStatus?: UserAccountStatus | null,
  appointments?: IndividualAppointmentsInput | null,
};

export type DeleteIndividualInput = {
  id: string,
};

export type CreateMetricInput = {
  sourceId: string,
  action: MetricAction,
  period: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  data: string,
  events: Array< string | null >,
};

export enum MetricAction {
  UserCompanyCreate = "UserCompanyCreate",
  CompanySubCompanyCreate = "CompanySubCompanyCreate",
  ResellerSubCompanyCreate = "ResellerSubCompanyCreate",
  OrganisationSubCompanyCreate = "OrganisationSubCompanyCreate",
  CompanyUsers = "CompanyUsers",
  CompanyQuestionnaires = "CompanyQuestionnaires",
  PackageReports = "PackageReports",
}


export type ModelMetricConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  data?: ModelStringInput | null,
  events?: ModelIDInput | null,
  and?: Array< ModelMetricConditionInput | null > | null,
  or?: Array< ModelMetricConditionInput | null > | null,
  not?: ModelMetricConditionInput | null,
};

export type Metric = {
  __typename: "Metric",
  sourceId: string,
  action: MetricAction,
  period: string,
  createdAt: string,
  updatedAt: string,
  data: string,
  events: Array< string | null >,
};

export type UpdateMetricInput = {
  sourceId: string,
  action: MetricAction,
  period: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  data?: string | null,
  events?: Array< string | null > | null,
};

export type CreateMetricEventInput = {
  id?: string | null,
  action: MetricEventAction,
  companyId?: string | null,
  cognitoIdentityId?: string | null,
  createdAt?: string | null,
  data: string,
};

export enum MetricEventAction {
  UserAuth = "UserAuth",
  QuestionnaireCreate = "QuestionnaireCreate",
  QuestionnaireRelease = "QuestionnaireRelease",
  QuestionnaireDispatch = "QuestionnaireDispatch",
  CompanyCreate = "CompanyCreate",
  UserCreate = "UserCreate",
  UserUpdate = "UserUpdate",
  IndividualCreate = "IndividualCreate",
  CommitteeCreate = "CommitteeCreate",
  ReportGenerate = "ReportGenerate",
}


export type ModelMetricEventConditionInput = {
  action?: ModelMetricEventActionInput | null,
  companyId?: ModelIDInput | null,
  cognitoIdentityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  data?: ModelStringInput | null,
  and?: Array< ModelMetricEventConditionInput | null > | null,
  or?: Array< ModelMetricEventConditionInput | null > | null,
  not?: ModelMetricEventConditionInput | null,
};

export type ModelMetricEventActionInput = {
  eq?: MetricEventAction | null,
  ne?: MetricEventAction | null,
};

export type MetricEvent = {
  __typename: "MetricEvent",
  id: string,
  action: MetricEventAction,
  companyId?: string | null,
  cognitoIdentityId?: string | null,
  createdAt: string,
  data: string,
  updatedAt: string,
};

export type CreatePackageInput = {
  id?: string | null,
  categories: Array< PackageCategories | null >,
  name: string,
  description?: string | null,
  variants: Array< string | null >,
};

export type ModelPackageConditionInput = {
  categories?: ModelPackageCategoriesInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  variants?: ModelStringInput | null,
  and?: Array< ModelPackageConditionInput | null > | null,
  or?: Array< ModelPackageConditionInput | null > | null,
  not?: ModelPackageConditionInput | null,
};

export type ModelPackageCategoriesInput = {
  eq?: PackageCategories | null,
  ne?: PackageCategories | null,
};

export type UpdatePackageInput = {
  id: string,
  categories?: Array< PackageCategories | null > | null,
  name?: string | null,
  description?: string | null,
  variants?: Array< string | null > | null,
};

export type DeletePackageInput = {
  id: string,
};

export type CreatePackageItemInput = {
  packageId: string,
  type: PackageItemTypes,
  key: string,
  content: string,
};

export type ModelPackageItemConditionInput = {
  content?: ModelStringInput | null,
  and?: Array< ModelPackageItemConditionInput | null > | null,
  or?: Array< ModelPackageItemConditionInput | null > | null,
  not?: ModelPackageItemConditionInput | null,
};

export type UpdatePackageItemInput = {
  packageId: string,
  type: PackageItemTypes,
  key: string,
  content?: string | null,
};

export type DeletePackageItemInput = {
  packageId: string,
  type: PackageItemTypes,
  key: string,
};

export type CreateQuestionnaireInput = {
  id?: string | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  companyId: string,
  name: string,
  status?: QuestionnaireStatus | null,
  groups?: string | null,
  customContent?: string | null,
  variants?: Array< QuestionnaireVariantInput | null > | null,
  packageId?: string | null,
  editDisabled?: boolean | null,
};

export enum QuestionnaireStatus {
  NEW = "NEW",
  RELEASED = "RELEASED",
  DISPATCHED = "DISPATCHED",
}


export type QuestionnaireVariantInput = {
  name: string,
  variantCode: string,
};

export type ModelQuestionnaireConditionInput = {
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelQuestionnaireStatusInput | null,
  groups?: ModelStringInput | null,
  customContent?: ModelStringInput | null,
  packageId?: ModelIDInput | null,
  editDisabled?: ModelBooleanInput | null,
  and?: Array< ModelQuestionnaireConditionInput | null > | null,
  or?: Array< ModelQuestionnaireConditionInput | null > | null,
  not?: ModelQuestionnaireConditionInput | null,
};

export type ModelQuestionnaireStatusInput = {
  eq?: QuestionnaireStatus | null,
  ne?: QuestionnaireStatus | null,
};

export type Questionnaire = {
  __typename: "Questionnaire",
  id: string,
  createdAt: string,
  deletedAt?: string | null,
  companyId: string,
  name: string,
  status?: QuestionnaireStatus | null,
  groups?: string | null,
  customContent?: string | null,
  variants?:  Array<QuestionnaireVariant | null > | null,
  packageId?: string | null,
  editDisabled?: boolean | null,
  company?: Company | null,
  sections?: ModelSectionConnection | null,
  updatedAt: string,
};

export type QuestionnaireVariant = {
  __typename: "QuestionnaireVariant",
  name: string,
  variantCode: string,
};

export type ModelSectionConnection = {
  __typename: "ModelSectionConnection",
  items:  Array<Section | null >,
  nextToken?: string | null,
};

export type Section = {
  __typename: "Section",
  id: string,
  masterSectionId?: string | null,
  variantCode: string,
  questionnaireId: string,
  companyId: string,
  createdAt: string,
  name: string,
  slug?: string | null,
  displayOrder: number,
  blocks?: ModelBlockConnection | null,
  updatedAt: string,
};

export type ModelBlockConnection = {
  __typename: "ModelBlockConnection",
  items:  Array<Block | null >,
  nextToken?: string | null,
};

export type UpdateQuestionnaireInput = {
  id: string,
  createdAt?: string | null,
  deletedAt?: string | null,
  companyId?: string | null,
  name?: string | null,
  status?: QuestionnaireStatus | null,
  groups?: string | null,
  customContent?: string | null,
  variants?: Array< QuestionnaireVariantInput | null > | null,
  packageId?: string | null,
  editDisabled?: boolean | null,
};

export type DeleteQuestionnaireInput = {
  id: string,
};

export type CreateReportInput = {
  id?: string | null,
  companyId: string,
  surveyGroupId: string,
  reportGroupId?: string | null,
  comparisonSurveyGroupId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  variantCode?: string | null,
  owner?: string | null,
  summary?: string | null,
  focusAreas?: string | null,
  configuration?: string | null,
};

export type ModelReportConditionInput = {
  companyId?: ModelIDInput | null,
  surveyGroupId?: ModelIDInput | null,
  reportGroupId?: ModelIDInput | null,
  comparisonSurveyGroupId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  variantCode?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  focusAreas?: ModelStringInput | null,
  configuration?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  companyId: string,
  surveyGroupId: string,
  reportGroupId?: string | null,
  comparisonSurveyGroupId?: string | null,
  createdAt: string,
  updatedAt: string,
  variantCode?: string | null,
  owner?: string | null,
  summary?: string | null,
  focusAreas?: string | null,
  configuration?: string | null,
  sections?: ModelReportSectionConnection | null,
  documents?: ModelReportDocumentConnection | null,
};

export type ModelReportSectionConnection = {
  __typename: "ModelReportSectionConnection",
  items:  Array<ReportSection | null >,
  nextToken?: string | null,
};

export type ReportSection = {
  __typename: "ReportSection",
  id: string,
  companyId: string,
  reportId: string,
  sectionId: string,
  variantCode?: string | null,
  summary?: string | null,
  introduction?: string | null,
  subSectionIntroductions?:  Array<SubSectionIntroduction | null > | null,
  results?: string | null,
  report?: Report | null,
  createdAt: string,
  updatedAt: string,
};

export type SubSectionIntroduction = {
  __typename: "SubSectionIntroduction",
  slug?: string | null,
  value?: string | null,
  reviewedAt?: string | null,
};

export type ModelReportDocumentConnection = {
  __typename: "ModelReportDocumentConnection",
  items:  Array<ReportDocument | null >,
  nextToken?: string | null,
};

export type ReportDocument = {
  __typename: "ReportDocument",
  id: string,
  reportId?: string | null,
  requesterId: string,
  companyId: string,
  targetIndividualId?: string | null,
  variantCode?: string | null,
  surveyGroupId: string,
  comparisonSurveyGroupId?: string | null,
  createdAt: string,
  updatedAt: string,
  queuedAt?: string | null,
  deletedAt?: string | null,
  startedProcessingAt?: string | null,
  finishedAt?: string | null,
  failedAt?: string | null,
  key?: string | null,
  template?: ReportTemplate | null,
  report?: Report | null,
};

export enum ReportTemplate {
  Default = "Default",
  ResponseStatus = "ResponseStatus",
}


export type UpdateReportInput = {
  id: string,
  companyId?: string | null,
  surveyGroupId?: string | null,
  reportGroupId?: string | null,
  comparisonSurveyGroupId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  variantCode?: string | null,
  owner?: string | null,
  summary?: string | null,
  focusAreas?: string | null,
  configuration?: string | null,
};

export type DeleteReportInput = {
  id: string,
};

export type CreateReportDocumentInput = {
  id?: string | null,
  reportId?: string | null,
  requesterId: string,
  companyId: string,
  targetIndividualId?: string | null,
  variantCode?: string | null,
  surveyGroupId: string,
  comparisonSurveyGroupId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  queuedAt?: string | null,
  deletedAt?: string | null,
  startedProcessingAt?: string | null,
  finishedAt?: string | null,
  failedAt?: string | null,
  key?: string | null,
  template?: ReportTemplate | null,
};

export type ModelReportDocumentConditionInput = {
  reportId?: ModelIDInput | null,
  requesterId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  targetIndividualId?: ModelIDInput | null,
  variantCode?: ModelStringInput | null,
  surveyGroupId?: ModelStringInput | null,
  comparisonSurveyGroupId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  queuedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  startedProcessingAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  failedAt?: ModelStringInput | null,
  key?: ModelStringInput | null,
  template?: ModelReportTemplateInput | null,
  and?: Array< ModelReportDocumentConditionInput | null > | null,
  or?: Array< ModelReportDocumentConditionInput | null > | null,
  not?: ModelReportDocumentConditionInput | null,
};

export type ModelReportTemplateInput = {
  eq?: ReportTemplate | null,
  ne?: ReportTemplate | null,
};

export type UpdateReportDocumentInput = {
  id: string,
  reportId?: string | null,
  requesterId?: string | null,
  companyId?: string | null,
  targetIndividualId?: string | null,
  variantCode?: string | null,
  surveyGroupId?: string | null,
  comparisonSurveyGroupId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  queuedAt?: string | null,
  deletedAt?: string | null,
  startedProcessingAt?: string | null,
  finishedAt?: string | null,
  failedAt?: string | null,
  key?: string | null,
  template?: ReportTemplate | null,
};

export type DeleteReportDocumentInput = {
  id: string,
};

export type CreateReportGroupInput = {
  id?: string | null,
  surveyGroupId: string,
  companyId: string,
  name: string,
  variants: Array< QuestionnaireVariantInput | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
  deletedAt?: string | null,
  configuration?: ReportGroupConfigurationInput | null,
  scores?: string | null,
  quartiles?: string | null,
};

export type ReportGroupConfigurationInput = {
  hideComparisonResponses?: boolean | null,
  combineComparisonResponses?: boolean | null,
  reportDocumentEstimates?: ReportDocumentEstimatesInput | null,
  scores?: ReportGroupScoresConfigurationInput | null,
};

export type ReportDocumentEstimatesInput = {
  generateCollectiveDuration?: number | null,
  generateGenericDuration?: number | null,
  generateIndividualDuration?: number | null,
  generateAllIndividualsDuration?: number | null,
  downloadIndividualsArchiveDuration?: number | null,
};

export type ReportGroupScoresConfigurationInput = {
  showV2Universe?: boolean | null,
  allowMultipleUniverse?: boolean | null,
};

export type ModelReportGroupConditionInput = {
  surveyGroupId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  scores?: ModelStringInput | null,
  quartiles?: ModelStringInput | null,
  and?: Array< ModelReportGroupConditionInput | null > | null,
  or?: Array< ModelReportGroupConditionInput | null > | null,
  not?: ModelReportGroupConditionInput | null,
};

export type ReportGroup = {
  __typename: "ReportGroup",
  id: string,
  surveyGroupId: string,
  companyId: string,
  name: string,
  variants:  Array<QuestionnaireVariant | null >,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string | null,
  configuration?: ReportGroupConfiguration | null,
  scores?: string | null,
  quartiles?: string | null,
  reports?: ModelReportConnection | null,
};

export type ReportGroupConfiguration = {
  __typename: "ReportGroupConfiguration",
  hideComparisonResponses?: boolean | null,
  combineComparisonResponses?: boolean | null,
  reportDocumentEstimates?: ReportDocumentEstimates | null,
  scores?: ReportGroupScoresConfiguration | null,
};

export type ReportDocumentEstimates = {
  __typename: "ReportDocumentEstimates",
  generateCollectiveDuration?: number | null,
  generateGenericDuration?: number | null,
  generateIndividualDuration?: number | null,
  generateAllIndividualsDuration?: number | null,
  downloadIndividualsArchiveDuration?: number | null,
};

export type ReportGroupScoresConfiguration = {
  __typename: "ReportGroupScoresConfiguration",
  showV2Universe?: boolean | null,
  allowMultipleUniverse?: boolean | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type UpdateReportGroupInput = {
  id: string,
  surveyGroupId?: string | null,
  companyId?: string | null,
  name?: string | null,
  variants?: Array< QuestionnaireVariantInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deletedAt?: string | null,
  configuration?: ReportGroupConfigurationInput | null,
  scores?: string | null,
  quartiles?: string | null,
};

export type DeleteReportGroupInput = {
  id: string,
};

export type CreateReportSectionInput = {
  id?: string | null,
  companyId: string,
  reportId: string,
  sectionId: string,
  variantCode?: string | null,
  summary?: string | null,
  introduction?: string | null,
  subSectionIntroductions?: Array< SubSectionIntroductionInput | null > | null,
  results?: string | null,
};

export type SubSectionIntroductionInput = {
  slug?: string | null,
  value?: string | null,
  reviewedAt?: string | null,
};

export type ModelReportSectionConditionInput = {
  companyId?: ModelIDInput | null,
  reportId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  variantCode?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  introduction?: ModelStringInput | null,
  results?: ModelStringInput | null,
  and?: Array< ModelReportSectionConditionInput | null > | null,
  or?: Array< ModelReportSectionConditionInput | null > | null,
  not?: ModelReportSectionConditionInput | null,
};

export type UpdateReportSectionInput = {
  id: string,
  companyId?: string | null,
  reportId?: string | null,
  sectionId?: string | null,
  variantCode?: string | null,
  summary?: string | null,
  introduction?: string | null,
  subSectionIntroductions?: Array< SubSectionIntroductionInput | null > | null,
  results?: string | null,
};

export type DeleteReportSectionInput = {
  id: string,
};

export type CreateSectionInput = {
  id?: string | null,
  masterSectionId?: string | null,
  variantCode: string,
  questionnaireId: string,
  companyId: string,
  createdAt?: string | null,
  name: string,
  slug?: string | null,
  displayOrder: number,
};

export type ModelSectionConditionInput = {
  masterSectionId?: ModelIDInput | null,
  questionnaireId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  and?: Array< ModelSectionConditionInput | null > | null,
  or?: Array< ModelSectionConditionInput | null > | null,
  not?: ModelSectionConditionInput | null,
};

export type UpdateSectionInput = {
  id: string,
  masterSectionId?: string | null,
  variantCode: string,
  questionnaireId?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
  name?: string | null,
  slug?: string | null,
  displayOrder?: number | null,
};

export type DeleteSectionInput = {
  id: string,
  variantCode: string,
};

export type CreateStatisticInput = {
  collection: string,
  category: string,
  companyId: string,
  results: Array< StatsResultInput | null >,
};

export type StatsResultInput = {
  key: string,
  name: string,
  value: number,
};

export type ModelStatisticConditionInput = {
  and?: Array< ModelStatisticConditionInput | null > | null,
  or?: Array< ModelStatisticConditionInput | null > | null,
  not?: ModelStatisticConditionInput | null,
};

export type Statistic = {
  __typename: "Statistic",
  collection: string,
  category: string,
  companyId: string,
  results:  Array<StatsResult | null >,
  company?: Company | null,
  createdAt: string,
  updatedAt: string,
};

export type StatsResult = {
  __typename: "StatsResult",
  key: string,
  name: string,
  value: number,
};

export type UpdateStatisticInput = {
  collection: string,
  category: string,
  companyId: string,
  results?: Array< StatsResultInput | null > | null,
};

export type DeleteStatisticInput = {
  collection: string,
  category: string,
  companyId: string,
};

export type CreateStatisticTotalInput = {
  collection: string,
  category: string,
  resultCount: number,
};

export type ModelStatisticTotalConditionInput = {
  resultCount?: ModelIntInput | null,
  and?: Array< ModelStatisticTotalConditionInput | null > | null,
  or?: Array< ModelStatisticTotalConditionInput | null > | null,
  not?: ModelStatisticTotalConditionInput | null,
};

export type StatisticTotal = {
  __typename: "StatisticTotal",
  collection: string,
  category: string,
  resultCount: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateStatisticTotalInput = {
  collection: string,
  category: string,
  resultCount?: number | null,
};

export type DeleteStatisticTotalInput = {
  collection: string,
  category: string,
};

export type CreateSurveyInput = {
  id?: string | null,
  groupId: string,
  companyId: string,
  questionnaireId: string,
  individualId: string,
  owner: string,
  key?: string | null,
  variantCode?: string | null,
  dispatchedAt?: string | null,
  finishedAt?: string | null,
  deletedAt?: string | null,
  totals?: TotalsInput | null,
  response?: string | null,
  status?: SurveyStatus | null,
  tags?: Array< string | null > | null,
  invitation?: string | null,
};

export type TotalsInput = {
  questions?: number | null,
  questionsCompleted?: number | null,
};

export enum SurveyStatus {
  NEW = "NEW",
  SENT = "SENT",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
}


export type ModelSurveyConditionInput = {
  groupId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  questionnaireId?: ModelIDInput | null,
  individualId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  key?: ModelStringInput | null,
  variantCode?: ModelStringInput | null,
  dispatchedAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  response?: ModelStringInput | null,
  status?: ModelSurveyStatusInput | null,
  tags?: ModelStringInput | null,
  invitation?: ModelStringInput | null,
  and?: Array< ModelSurveyConditionInput | null > | null,
  or?: Array< ModelSurveyConditionInput | null > | null,
  not?: ModelSurveyConditionInput | null,
};

export type ModelSurveyStatusInput = {
  eq?: SurveyStatus | null,
  ne?: SurveyStatus | null,
};

export type Survey = {
  __typename: "Survey",
  id: string,
  groupId: string,
  companyId: string,
  questionnaireId: string,
  individualId: string,
  owner: string,
  key?: string | null,
  variantCode?: string | null,
  dispatchedAt?: string | null,
  finishedAt?: string | null,
  deletedAt?: string | null,
  totals?: Totals | null,
  response?: string | null,
  status?: SurveyStatus | null,
  tags?: Array< string | null > | null,
  invitation?: string | null,
  individual?: Individual | null,
  questionnaire?: Questionnaire | null,
  createdAt: string,
  updatedAt: string,
};

export type Totals = {
  __typename: "Totals",
  questions?: number | null,
  questionsCompleted?: number | null,
};

export type UpdateSurveyInput = {
  id: string,
  groupId?: string | null,
  companyId?: string | null,
  questionnaireId?: string | null,
  individualId?: string | null,
  owner?: string | null,
  key?: string | null,
  variantCode?: string | null,
  dispatchedAt?: string | null,
  finishedAt?: string | null,
  deletedAt?: string | null,
  totals?: TotalsInput | null,
  response?: string | null,
  status?: SurveyStatus | null,
  tags?: Array< string | null > | null,
  invitation?: string | null,
};

export type DeleteSurveyInput = {
  id: string,
};

export type CreateSurveyGroupInput = {
  id?: string | null,
  questionnaireId: string,
  companyId: string,
  packageId?: string | null,
  targetIndividualIds?: Array< string | null > | null,
  name: string,
  variants: Array< QuestionnaireVariantInput | null >,
  status: SurveyGroupStatus,
  createdAt?: string | null,
  updatedAt?: string | null,
  releasedAt?: string | null,
  dispatchedAt?: string | null,
  deletedAt?: string | null,
  tags?: Array< string | null > | null,
  includeInStats?: boolean | null,
};

export enum SurveyGroupStatus {
  NEW = "NEW",
  RELEASED = "RELEASED",
  DISPATCHED = "DISPATCHED",
  FINISHED = "FINISHED",
}


export type ModelSurveyGroupConditionInput = {
  questionnaireId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  packageId?: ModelIDInput | null,
  targetIndividualIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelSurveyGroupStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  releasedAt?: ModelStringInput | null,
  dispatchedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  includeInStats?: ModelBooleanInput | null,
  and?: Array< ModelSurveyGroupConditionInput | null > | null,
  or?: Array< ModelSurveyGroupConditionInput | null > | null,
  not?: ModelSurveyGroupConditionInput | null,
};

export type ModelSurveyGroupStatusInput = {
  eq?: SurveyGroupStatus | null,
  ne?: SurveyGroupStatus | null,
};

export type SurveyGroup = {
  __typename: "SurveyGroup",
  id: string,
  questionnaireId: string,
  companyId: string,
  packageId?: string | null,
  targetIndividualIds?: Array< string | null > | null,
  name: string,
  variants:  Array<QuestionnaireVariant | null >,
  status: SurveyGroupStatus,
  createdAt: string,
  updatedAt: string,
  releasedAt?: string | null,
  dispatchedAt?: string | null,
  deletedAt?: string | null,
  tags?: Array< string | null > | null,
  includeInStats?: boolean | null,
  surveys?: ModelSurveyConnection | null,
};

export type ModelSurveyConnection = {
  __typename: "ModelSurveyConnection",
  items:  Array<Survey | null >,
  nextToken?: string | null,
};

export type UpdateSurveyGroupInput = {
  id: string,
  questionnaireId?: string | null,
  companyId?: string | null,
  packageId?: string | null,
  targetIndividualIds?: Array< string | null > | null,
  name?: string | null,
  variants?: Array< QuestionnaireVariantInput | null > | null,
  status?: SurveyGroupStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  releasedAt?: string | null,
  dispatchedAt?: string | null,
  deletedAt?: string | null,
  tags?: Array< string | null > | null,
  includeInStats?: boolean | null,
};

export type DeleteSurveyGroupInput = {
  id: string,
};

export type CreateTeamIndividualInput = {
  companyId: string,
  individualId: string,
  teamId: CompanyTeamTypes,
};

export type ModelTeamIndividualConditionInput = {
  and?: Array< ModelTeamIndividualConditionInput | null > | null,
  or?: Array< ModelTeamIndividualConditionInput | null > | null,
  not?: ModelTeamIndividualConditionInput | null,
};

export type UpdateTeamIndividualInput = {
  companyId: string,
  individualId: string,
  teamId: CompanyTeamTypes,
};

export type DeleteTeamIndividualInput = {
  companyId: string,
  teamId: CompanyTeamTypes,
  individualId: string,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBlockFilterInput = {
  id?: ModelIDInput | null,
  variantCode?: ModelStringInput | null,
  sectionId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  variant?: ModelBlockTypeInput | null,
  data?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  and?: Array< ModelBlockFilterInput | null > | null,
  or?: Array< ModelBlockFilterInput | null > | null,
  not?: ModelBlockFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCognitoIdentityFilterInput = {
  id?: ModelIDInput | null,
  cognitoId?: ModelIDInput | null,
  userCreatedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  defaultLanguage?: ModelLanguageCodeInput | null,
  status?: ModelUserAccountStatusInput | null,
  and?: Array< ModelCognitoIdentityFilterInput | null > | null,
  or?: Array< ModelCognitoIdentityFilterInput | null > | null,
  not?: ModelCognitoIdentityFilterInput | null,
};

export type ModelCognitoIdentityConnection = {
  __typename: "ModelCognitoIdentityConnection",
  items:  Array<CognitoIdentity | null >,
  nextToken?: string | null,
};

export type ModelCommitteeFilterInput = {
  id?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  tags?: ModelCommitteeTagListInput | null,
  and?: Array< ModelCommitteeFilterInput | null > | null,
  or?: Array< ModelCommitteeFilterInput | null > | null,
  not?: ModelCommitteeFilterInput | null,
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  companyType?: ModelCompanyTypeInput | null,
  phoneNumber?: ModelStringInput | null,
  registrationNumber?: ModelStringInput | null,
  numberOfEmployees?: ModelStringInput | null,
  brandLogo?: ModelStringInput | null,
  sector?: ModelStringInput | null,
  ownershipStructure?: ModelStringInput | null,
  country?: ModelStringInput | null,
  employeeCount?: ModelStringInput | null,
  marketCap?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCompanyIndividualFilterInput = {
  createdAt?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  individualId?: ModelIDInput | null,
  and?: Array< ModelCompanyIndividualFilterInput | null > | null,
  or?: Array< ModelCompanyIndividualFilterInput | null > | null,
  not?: ModelCompanyIndividualFilterInput | null,
};

export type ModelCompanyPackageFilterInput = {
  companyId?: ModelIDInput | null,
  packageId?: ModelIDInput | null,
  inherited?: ModelBooleanInput | null,
  public?: ModelBooleanInput | null,
  and?: Array< ModelCompanyPackageFilterInput | null > | null,
  or?: Array< ModelCompanyPackageFilterInput | null > | null,
  not?: ModelCompanyPackageFilterInput | null,
};

export type ModelCompanyPackageConnection = {
  __typename: "ModelCompanyPackageConnection",
  items:  Array<CompanyPackage | null >,
  nextToken?: string | null,
};

export type ModelCompanyRelationPrimaryCompositeKeyConditionInput = {
  eq?: ModelCompanyRelationPrimaryCompositeKeyInput | null,
  le?: ModelCompanyRelationPrimaryCompositeKeyInput | null,
  lt?: ModelCompanyRelationPrimaryCompositeKeyInput | null,
  ge?: ModelCompanyRelationPrimaryCompositeKeyInput | null,
  gt?: ModelCompanyRelationPrimaryCompositeKeyInput | null,
  between?: Array< ModelCompanyRelationPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelCompanyRelationPrimaryCompositeKeyInput | null,
};

export type ModelCompanyRelationPrimaryCompositeKeyInput = {
  childId?: string | null,
  relationship?: CompanyRelationship | null,
};

export type ModelCompanyRelationFilterInput = {
  parentId?: ModelIDInput | null,
  childId?: ModelIDInput | null,
  relationship?: ModelCompanyRelationshipInput | null,
  and?: Array< ModelCompanyRelationFilterInput | null > | null,
  or?: Array< ModelCompanyRelationFilterInput | null > | null,
  not?: ModelCompanyRelationFilterInput | null,
};

export type ModelCompanyRelationshipInput = {
  eq?: CompanyRelationship | null,
  ne?: CompanyRelationship | null,
};

export type ModelCompanyRelationConnection = {
  __typename: "ModelCompanyRelationConnection",
  items:  Array<CompanyRelation | null >,
  nextToken?: string | null,
};

export type ModelCompanyUserFilterInput = {
  cognitoIdentityId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  accountType?: ModelAccountTypeInput | null,
  and?: Array< ModelCompanyUserFilterInput | null > | null,
  or?: Array< ModelCompanyUserFilterInput | null > | null,
  not?: ModelCompanyUserFilterInput | null,
};

export type ModelCompanyUserPackagePrimaryCompositeKeyConditionInput = {
  eq?: ModelCompanyUserPackagePrimaryCompositeKeyInput | null,
  le?: ModelCompanyUserPackagePrimaryCompositeKeyInput | null,
  lt?: ModelCompanyUserPackagePrimaryCompositeKeyInput | null,
  ge?: ModelCompanyUserPackagePrimaryCompositeKeyInput | null,
  gt?: ModelCompanyUserPackagePrimaryCompositeKeyInput | null,
  between?: Array< ModelCompanyUserPackagePrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelCompanyUserPackagePrimaryCompositeKeyInput | null,
};

export type ModelCompanyUserPackagePrimaryCompositeKeyInput = {
  cognitoIdentityId?: string | null,
  packageId?: string | null,
};

export type ModelCompanyUserPackageFilterInput = {
  companyId?: ModelIDInput | null,
  cognitoIdentityId?: ModelIDInput | null,
  packageId?: ModelIDInput | null,
  and?: Array< ModelCompanyUserPackageFilterInput | null > | null,
  or?: Array< ModelCompanyUserPackageFilterInput | null > | null,
  not?: ModelCompanyUserPackageFilterInput | null,
};

export type ModelCompanyUserPackageConnection = {
  __typename: "ModelCompanyUserPackageConnection",
  items:  Array<CompanyUserPackage | null >,
  nextToken?: string | null,
};

export type DefaultContent = {
  __typename: "DefaultContent",
  id: string,
  type: ContentType,
  key: string,
  content: string,
  createdAt: string,
  updatedAt: string,
};

export enum ContentType {
  Questionnaire = "Questionnaire",
  Role = "Role",
  KnowHow = "KnowHow",
  Position = "Position",
  Statistics = "Statistics",
  SectionScores = "SectionScores",
}


export type ModelDefaultContentFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelContentTypeInput | null,
  key?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelDefaultContentFilterInput | null > | null,
  or?: Array< ModelDefaultContentFilterInput | null > | null,
  not?: ModelDefaultContentFilterInput | null,
};

export type ModelContentTypeInput = {
  eq?: ContentType | null,
  ne?: ContentType | null,
};

export type ModelDefaultContentConnection = {
  __typename: "ModelDefaultContentConnection",
  items:  Array<DefaultContent | null >,
  nextToken?: string | null,
};

export type ModelIndividualFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  dateOfBirth?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  preferredName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  role?: ModelUserTypeInput | null,
  jobTitle?: ModelStringInput | null,
  religion?: ModelReligionInput | null,
  ethnicDesignation?: ModelEthnicDesignationInput | null,
  joinDate?: ModelStringInput | null,
  accountStatus?: ModelUserAccountStatusInput | null,
  and?: Array< ModelIndividualFilterInput | null > | null,
  or?: Array< ModelIndividualFilterInput | null > | null,
  not?: ModelIndividualFilterInput | null,
};

export type ModelIndividualConnection = {
  __typename: "ModelIndividualConnection",
  items:  Array<Individual | null >,
  nextToken?: string | null,
};

export type ModelPackageFilterInput = {
  id?: ModelIDInput | null,
  categories?: ModelPackageCategoriesInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  variants?: ModelStringInput | null,
  and?: Array< ModelPackageFilterInput | null > | null,
  or?: Array< ModelPackageFilterInput | null > | null,
  not?: ModelPackageFilterInput | null,
};

export type ModelPackageConnection = {
  __typename: "ModelPackageConnection",
  items:  Array<Package | null >,
  nextToken?: string | null,
};

export type ModelPackageItemPrimaryCompositeKeyConditionInput = {
  eq?: ModelPackageItemPrimaryCompositeKeyInput | null,
  le?: ModelPackageItemPrimaryCompositeKeyInput | null,
  lt?: ModelPackageItemPrimaryCompositeKeyInput | null,
  ge?: ModelPackageItemPrimaryCompositeKeyInput | null,
  gt?: ModelPackageItemPrimaryCompositeKeyInput | null,
  between?: Array< ModelPackageItemPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelPackageItemPrimaryCompositeKeyInput | null,
};

export type ModelPackageItemPrimaryCompositeKeyInput = {
  type?: PackageItemTypes | null,
  key?: string | null,
};

export type ModelPackageItemFilterInput = {
  packageId?: ModelIDInput | null,
  type?: ModelPackageItemTypesInput | null,
  key?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelPackageItemFilterInput | null > | null,
  or?: Array< ModelPackageItemFilterInput | null > | null,
  not?: ModelPackageItemFilterInput | null,
};

export type ModelPackageItemTypesInput = {
  eq?: PackageItemTypes | null,
  ne?: PackageItemTypes | null,
};

export type ModelQuestionnaireFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelQuestionnaireStatusInput | null,
  groups?: ModelStringInput | null,
  customContent?: ModelStringInput | null,
  packageId?: ModelIDInput | null,
  editDisabled?: ModelBooleanInput | null,
  and?: Array< ModelQuestionnaireFilterInput | null > | null,
  or?: Array< ModelQuestionnaireFilterInput | null > | null,
  not?: ModelQuestionnaireFilterInput | null,
};

export type ModelQuestionnaireConnection = {
  __typename: "ModelQuestionnaireConnection",
  items:  Array<Questionnaire | null >,
  nextToken?: string | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  surveyGroupId?: ModelIDInput | null,
  reportGroupId?: ModelIDInput | null,
  comparisonSurveyGroupId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  variantCode?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  focusAreas?: ModelStringInput | null,
  configuration?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportDocumentFilterInput = {
  id?: ModelIDInput | null,
  reportId?: ModelIDInput | null,
  requesterId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  targetIndividualId?: ModelIDInput | null,
  variantCode?: ModelStringInput | null,
  surveyGroupId?: ModelStringInput | null,
  comparisonSurveyGroupId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  queuedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  startedProcessingAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  failedAt?: ModelStringInput | null,
  key?: ModelStringInput | null,
  template?: ModelReportTemplateInput | null,
  and?: Array< ModelReportDocumentFilterInput | null > | null,
  or?: Array< ModelReportDocumentFilterInput | null > | null,
  not?: ModelReportDocumentFilterInput | null,
};

export type ModelReportGroupFilterInput = {
  id?: ModelIDInput | null,
  surveyGroupId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  scores?: ModelStringInput | null,
  quartiles?: ModelStringInput | null,
  and?: Array< ModelReportGroupFilterInput | null > | null,
  or?: Array< ModelReportGroupFilterInput | null > | null,
  not?: ModelReportGroupFilterInput | null,
};

export type ModelReportGroupConnection = {
  __typename: "ModelReportGroupConnection",
  items:  Array<ReportGroup | null >,
  nextToken?: string | null,
};

export type ModelReportSectionFilterInput = {
  id?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  reportId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  variantCode?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  introduction?: ModelStringInput | null,
  results?: ModelStringInput | null,
  and?: Array< ModelReportSectionFilterInput | null > | null,
  or?: Array< ModelReportSectionFilterInput | null > | null,
  not?: ModelReportSectionFilterInput | null,
};

export type ModelSectionFilterInput = {
  id?: ModelIDInput | null,
  masterSectionId?: ModelIDInput | null,
  variantCode?: ModelStringInput | null,
  questionnaireId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  and?: Array< ModelSectionFilterInput | null > | null,
  or?: Array< ModelSectionFilterInput | null > | null,
  not?: ModelSectionFilterInput | null,
};

export type ModelStatisticPrimaryCompositeKeyConditionInput = {
  eq?: ModelStatisticPrimaryCompositeKeyInput | null,
  le?: ModelStatisticPrimaryCompositeKeyInput | null,
  lt?: ModelStatisticPrimaryCompositeKeyInput | null,
  ge?: ModelStatisticPrimaryCompositeKeyInput | null,
  gt?: ModelStatisticPrimaryCompositeKeyInput | null,
  between?: Array< ModelStatisticPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelStatisticPrimaryCompositeKeyInput | null,
};

export type ModelStatisticPrimaryCompositeKeyInput = {
  category?: string | null,
  companyId?: string | null,
};

export type ModelStatisticFilterInput = {
  collection?: ModelStringInput | null,
  category?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelStatisticFilterInput | null > | null,
  or?: Array< ModelStatisticFilterInput | null > | null,
  not?: ModelStatisticFilterInput | null,
};

export type ModelStatisticConnection = {
  __typename: "ModelStatisticConnection",
  items:  Array<Statistic | null >,
  nextToken?: string | null,
};

export type ModelStatisticTotalFilterInput = {
  collection?: ModelStringInput | null,
  category?: ModelStringInput | null,
  resultCount?: ModelIntInput | null,
  and?: Array< ModelStatisticTotalFilterInput | null > | null,
  or?: Array< ModelStatisticTotalFilterInput | null > | null,
  not?: ModelStatisticTotalFilterInput | null,
};

export type ModelStatisticTotalConnection = {
  __typename: "ModelStatisticTotalConnection",
  items:  Array<StatisticTotal | null >,
  nextToken?: string | null,
};

export type ModelSurveyFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  questionnaireId?: ModelIDInput | null,
  individualId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  key?: ModelStringInput | null,
  variantCode?: ModelStringInput | null,
  dispatchedAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  response?: ModelStringInput | null,
  status?: ModelSurveyStatusInput | null,
  tags?: ModelStringInput | null,
  invitation?: ModelStringInput | null,
  and?: Array< ModelSurveyFilterInput | null > | null,
  or?: Array< ModelSurveyFilterInput | null > | null,
  not?: ModelSurveyFilterInput | null,
};

export type ModelSurveyGroupFilterInput = {
  id?: ModelIDInput | null,
  questionnaireId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  packageId?: ModelIDInput | null,
  targetIndividualIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelSurveyGroupStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  releasedAt?: ModelStringInput | null,
  dispatchedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  includeInStats?: ModelBooleanInput | null,
  and?: Array< ModelSurveyGroupFilterInput | null > | null,
  or?: Array< ModelSurveyGroupFilterInput | null > | null,
  not?: ModelSurveyGroupFilterInput | null,
};

export type ModelSurveyGroupConnection = {
  __typename: "ModelSurveyGroupConnection",
  items:  Array<SurveyGroup | null >,
  nextToken?: string | null,
};

export type ModelSurveyGroupByPackageCompositeKeyConditionInput = {
  eq?: ModelSurveyGroupByPackageCompositeKeyInput | null,
  le?: ModelSurveyGroupByPackageCompositeKeyInput | null,
  lt?: ModelSurveyGroupByPackageCompositeKeyInput | null,
  ge?: ModelSurveyGroupByPackageCompositeKeyInput | null,
  gt?: ModelSurveyGroupByPackageCompositeKeyInput | null,
  between?: Array< ModelSurveyGroupByPackageCompositeKeyInput | null > | null,
  beginsWith?: ModelSurveyGroupByPackageCompositeKeyInput | null,
};

export type ModelSurveyGroupByPackageCompositeKeyInput = {
  companyId?: string | null,
  createdAt?: string | null,
};

export type ModelTeamIndividualPrimaryCompositeKeyConditionInput = {
  eq?: ModelTeamIndividualPrimaryCompositeKeyInput | null,
  le?: ModelTeamIndividualPrimaryCompositeKeyInput | null,
  lt?: ModelTeamIndividualPrimaryCompositeKeyInput | null,
  ge?: ModelTeamIndividualPrimaryCompositeKeyInput | null,
  gt?: ModelTeamIndividualPrimaryCompositeKeyInput | null,
  between?: Array< ModelTeamIndividualPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelTeamIndividualPrimaryCompositeKeyInput | null,
};

export type ModelTeamIndividualPrimaryCompositeKeyInput = {
  teamId?: CompanyTeamTypes | null,
  individualId?: string | null,
};

export type ModelTeamIndividualFilterInput = {
  companyId?: ModelIDInput | null,
  individualId?: ModelIDInput | null,
  teamId?: ModelCompanyTeamTypesInput | null,
  and?: Array< ModelTeamIndividualFilterInput | null > | null,
  or?: Array< ModelTeamIndividualFilterInput | null > | null,
  not?: ModelTeamIndividualFilterInput | null,
};

export type ModelCompanyTeamTypesInput = {
  eq?: CompanyTeamTypes | null,
  ne?: CompanyTeamTypes | null,
};

export type ModelSubscriptionBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  variantCode?: ModelSubscriptionStringInput | null,
  sectionId?: ModelSubscriptionIDInput | null,
  groupId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  variant?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  displayOrder?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionBlockFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionCommitteeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommitteeFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommitteeFilterInput | null > | null,
};

export type ModelSubscriptionCommitteeIndividualFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  committeeId?: ModelSubscriptionIDInput | null,
  individualId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCommitteeIndividualFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommitteeIndividualFilterInput | null > | null,
};

export type ModelSubscriptionCompanyFilterInput = {
  owner?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  companyType?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  registrationNumber?: ModelSubscriptionStringInput | null,
  numberOfEmployees?: ModelSubscriptionStringInput | null,
  brandLogo?: ModelSubscriptionStringInput | null,
  sector?: ModelSubscriptionStringInput | null,
  ownershipStructure?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  employeeCount?: ModelSubscriptionStringInput | null,
  marketCap?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
};

export type ModelSubscriptionCompanyIndividualFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  individualId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCompanyIndividualFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyIndividualFilterInput | null > | null,
};

export type ModelSubscriptionCompanyRelationFilterInput = {
  relationship?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyRelationFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyRelationFilterInput | null > | null,
};

export type ModelSubscriptionPackageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  categories?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  variants?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPackageFilterInput | null > | null,
  or?: Array< ModelSubscriptionPackageFilterInput | null > | null,
};

export type ModelSubscriptionPackageItemFilterInput = {
  packageId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPackageItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionPackageItemFilterInput | null > | null,
};

export type ModelSubscriptionReportGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  surveyGroupId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  scores?: ModelSubscriptionStringInput | null,
  quartiles?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportGroupFilterInput | null > | null,
};

export type ModelSubscriptionReportSectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  reportId?: ModelSubscriptionIDInput | null,
  sectionId?: ModelSubscriptionIDInput | null,
  variantCode?: ModelSubscriptionStringInput | null,
  summary?: ModelSubscriptionStringInput | null,
  introduction?: ModelSubscriptionStringInput | null,
  results?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportSectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportSectionFilterInput | null > | null,
};

export type ModelSubscriptionSectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  masterSectionId?: ModelSubscriptionIDInput | null,
  variantCode?: ModelSubscriptionStringInput | null,
  questionnaireId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  displayOrder?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionSectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionSectionFilterInput | null > | null,
};

export type ModelSubscriptionStatisticFilterInput = {
  collection?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionStatisticFilterInput | null > | null,
  or?: Array< ModelSubscriptionStatisticFilterInput | null > | null,
};

export type ModelSubscriptionStatisticTotalFilterInput = {
  collection?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  resultCount?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionStatisticTotalFilterInput | null > | null,
  or?: Array< ModelSubscriptionStatisticTotalFilterInput | null > | null,
};

export type ModelSubscriptionSurveyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  groupId?: ModelSubscriptionIDInput | null,
  questionnaireId?: ModelSubscriptionIDInput | null,
  individualId?: ModelSubscriptionIDInput | null,
  key?: ModelSubscriptionStringInput | null,
  variantCode?: ModelSubscriptionStringInput | null,
  dispatchedAt?: ModelSubscriptionStringInput | null,
  finishedAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  response?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  invitation?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSurveyFilterInput | null > | null,
  or?: Array< ModelSubscriptionSurveyFilterInput | null > | null,
};

export type ModelSubscriptionSurveyGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  questionnaireId?: ModelSubscriptionIDInput | null,
  packageId?: ModelSubscriptionIDInput | null,
  targetIndividualIds?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  releasedAt?: ModelSubscriptionStringInput | null,
  dispatchedAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  includeInStats?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionSurveyGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionSurveyGroupFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTeamIndividualFilterInput = {
  individualId?: ModelSubscriptionIDInput | null,
  teamId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamIndividualFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamIndividualFilterInput | null > | null,
};

export type CreateBlockMutationVariables = {
  input: CreateBlockInput,
  condition?: ModelBlockConditionInput | null,
};

export type CreateBlockMutation = {
  createBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type UpdateBlockMutationVariables = {
  input: UpdateBlockInput,
  condition?: ModelBlockConditionInput | null,
};

export type UpdateBlockMutation = {
  updateBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type DeleteBlockMutationVariables = {
  input: DeleteBlockInput,
  condition?: ModelBlockConditionInput | null,
};

export type DeleteBlockMutation = {
  deleteBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type CreateCognitoIdentityMutationVariables = {
  input: CreateCognitoIdentityInput,
  condition?: ModelCognitoIdentityConditionInput | null,
};

export type CreateCognitoIdentityMutation = {
  createCognitoIdentity?:  {
    __typename: "CognitoIdentity",
    id: string,
    cognitoId: string,
    userCreatedBy: string,
    createdAt: string,
    updatedAt: string,
    displayName: string,
    email: string,
    defaultLanguage: LanguageCode,
    avatar?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    companies?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: UserAccountStatus | null,
  } | null,
};

export type UpdateCognitoIdentityMutationVariables = {
  input: UpdateCognitoIdentityInput,
  condition?: ModelCognitoIdentityConditionInput | null,
};

export type UpdateCognitoIdentityMutation = {
  updateCognitoIdentity?:  {
    __typename: "CognitoIdentity",
    id: string,
    cognitoId: string,
    userCreatedBy: string,
    createdAt: string,
    updatedAt: string,
    displayName: string,
    email: string,
    defaultLanguage: LanguageCode,
    avatar?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    companies?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: UserAccountStatus | null,
  } | null,
};

export type DeleteCognitoIdentityMutationVariables = {
  input: DeleteCognitoIdentityInput,
  condition?: ModelCognitoIdentityConditionInput | null,
};

export type DeleteCognitoIdentityMutation = {
  deleteCognitoIdentity?:  {
    __typename: "CognitoIdentity",
    id: string,
    cognitoId: string,
    userCreatedBy: string,
    createdAt: string,
    updatedAt: string,
    displayName: string,
    email: string,
    defaultLanguage: LanguageCode,
    avatar?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    companies?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: UserAccountStatus | null,
  } | null,
};

export type CreateCommitteeMutationVariables = {
  input: CreateCommitteeInput,
  condition?: ModelCommitteeConditionInput | null,
};

export type CreateCommitteeMutation = {
  createCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCommitteeMutationVariables = {
  input: UpdateCommitteeInput,
  condition?: ModelCommitteeConditionInput | null,
};

export type UpdateCommitteeMutation = {
  updateCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCommitteeMutationVariables = {
  input: DeleteCommitteeInput,
  condition?: ModelCommitteeConditionInput | null,
};

export type DeleteCommitteeMutation = {
  deleteCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCommitteeIndividualMutationVariables = {
  input: CreateCommitteeIndividualInput,
  condition?: ModelCommitteeIndividualConditionInput | null,
};

export type CreateCommitteeIndividualMutation = {
  createCommitteeIndividual?:  {
    __typename: "CommitteeIndividual",
    createdAt: string,
    companyId: string,
    committeeId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    committee?:  {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateCommitteeIndividualMutationVariables = {
  input: UpdateCommitteeIndividualInput,
  condition?: ModelCommitteeIndividualConditionInput | null,
};

export type UpdateCommitteeIndividualMutation = {
  updateCommitteeIndividual?:  {
    __typename: "CommitteeIndividual",
    createdAt: string,
    companyId: string,
    committeeId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    committee?:  {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteCommitteeIndividualMutationVariables = {
  input: DeleteCommitteeIndividualInput,
  condition?: ModelCommitteeIndividualConditionInput | null,
};

export type DeleteCommitteeIndividualMutation = {
  deleteCommitteeIndividual?:  {
    __typename: "CommitteeIndividual",
    createdAt: string,
    companyId: string,
    committeeId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    committee?:  {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateCompanyIndividualMutationVariables = {
  input: CreateCompanyIndividualInput,
  condition?: ModelCompanyIndividualConditionInput | null,
};

export type CreateCompanyIndividualMutation = {
  createCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyIndividualMutationVariables = {
  input: UpdateCompanyIndividualInput,
  condition?: ModelCompanyIndividualConditionInput | null,
};

export type UpdateCompanyIndividualMutation = {
  updateCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyIndividualMutationVariables = {
  input: DeleteCompanyIndividualInput,
  condition?: ModelCompanyIndividualConditionInput | null,
};

export type DeleteCompanyIndividualMutation = {
  deleteCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateCompanyPackageMutationVariables = {
  input: CreateCompanyPackageInput,
  condition?: ModelCompanyPackageConditionInput | null,
};

export type CreateCompanyPackageMutation = {
  createCompanyPackage?:  {
    __typename: "CompanyPackage",
    companyId: string,
    packageId: string,
    inherited: boolean,
    public: boolean,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyPackageMutationVariables = {
  input: UpdateCompanyPackageInput,
  condition?: ModelCompanyPackageConditionInput | null,
};

export type UpdateCompanyPackageMutation = {
  updateCompanyPackage?:  {
    __typename: "CompanyPackage",
    companyId: string,
    packageId: string,
    inherited: boolean,
    public: boolean,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyPackageMutationVariables = {
  input: DeleteCompanyPackageInput,
  condition?: ModelCompanyPackageConditionInput | null,
};

export type DeleteCompanyPackageMutation = {
  deleteCompanyPackage?:  {
    __typename: "CompanyPackage",
    companyId: string,
    packageId: string,
    inherited: boolean,
    public: boolean,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompanyRelationMutationVariables = {
  input: CreateCompanyRelationInput,
  condition?: ModelCompanyRelationConditionInput | null,
};

export type CreateCompanyRelationMutation = {
  createCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyRelationMutationVariables = {
  input: UpdateCompanyRelationInput,
  condition?: ModelCompanyRelationConditionInput | null,
};

export type UpdateCompanyRelationMutation = {
  updateCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyRelationMutationVariables = {
  input: DeleteCompanyRelationInput,
  condition?: ModelCompanyRelationConditionInput | null,
};

export type DeleteCompanyRelationMutation = {
  deleteCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompanyUserMutationVariables = {
  input: CreateCompanyUserInput,
  condition?: ModelCompanyUserConditionInput | null,
};

export type CreateCompanyUserMutation = {
  createCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyUserMutationVariables = {
  input: UpdateCompanyUserInput,
  condition?: ModelCompanyUserConditionInput | null,
};

export type UpdateCompanyUserMutation = {
  updateCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyUserMutationVariables = {
  input: DeleteCompanyUserInput,
  condition?: ModelCompanyUserConditionInput | null,
};

export type DeleteCompanyUserMutation = {
  deleteCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompanyUserPackageMutationVariables = {
  input: CreateCompanyUserPackageInput,
  condition?: ModelCompanyUserPackageConditionInput | null,
};

export type CreateCompanyUserPackageMutation = {
  createCompanyUserPackage?:  {
    __typename: "CompanyUserPackage",
    companyId: string,
    cognitoIdentityId: string,
    packageId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyUserPackageMutationVariables = {
  input: UpdateCompanyUserPackageInput,
  condition?: ModelCompanyUserPackageConditionInput | null,
};

export type UpdateCompanyUserPackageMutation = {
  updateCompanyUserPackage?:  {
    __typename: "CompanyUserPackage",
    companyId: string,
    cognitoIdentityId: string,
    packageId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyUserPackageMutationVariables = {
  input: DeleteCompanyUserPackageInput,
  condition?: ModelCompanyUserPackageConditionInput | null,
};

export type DeleteCompanyUserPackageMutation = {
  deleteCompanyUserPackage?:  {
    __typename: "CompanyUserPackage",
    companyId: string,
    cognitoIdentityId: string,
    packageId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIndividualMutationVariables = {
  input: CreateIndividualInput,
  condition?: ModelIndividualConditionInput | null,
};

export type CreateIndividualMutation = {
  createIndividual?:  {
    __typename: "Individual",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    email: string,
    companyId: string,
    dateOfBirth?: string | null,
    firstName?: string | null,
    gender?: string | null,
    contactNumber?: string | null,
    preferredName?: string | null,
    familyName?: string | null,
    title?: string | null,
    role?: UserType | null,
    jobTitle?: string | null,
    religion?: Religion | null,
    ethnicDesignation?: EthnicDesignation | null,
    joinDate?: string | null,
    accountStatus?: UserAccountStatus | null,
    appointments?:  {
      __typename: "IndividualAppointments",
      Chair: number,
      SID: number,
      NonExecutive: number,
      CommitteeChair: number,
      Other: number,
    } | null,
    ownedCompanies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    teams?:  {
      __typename: "ModelTeamIndividualConnection",
      items:  Array< {
        __typename: "TeamIndividual",
        companyId: string,
        individualId: string,
        teamId: CompanyTeamTypes,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateIndividualMutationVariables = {
  input: UpdateIndividualInput,
  condition?: ModelIndividualConditionInput | null,
};

export type UpdateIndividualMutation = {
  updateIndividual?:  {
    __typename: "Individual",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    email: string,
    companyId: string,
    dateOfBirth?: string | null,
    firstName?: string | null,
    gender?: string | null,
    contactNumber?: string | null,
    preferredName?: string | null,
    familyName?: string | null,
    title?: string | null,
    role?: UserType | null,
    jobTitle?: string | null,
    religion?: Religion | null,
    ethnicDesignation?: EthnicDesignation | null,
    joinDate?: string | null,
    accountStatus?: UserAccountStatus | null,
    appointments?:  {
      __typename: "IndividualAppointments",
      Chair: number,
      SID: number,
      NonExecutive: number,
      CommitteeChair: number,
      Other: number,
    } | null,
    ownedCompanies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    teams?:  {
      __typename: "ModelTeamIndividualConnection",
      items:  Array< {
        __typename: "TeamIndividual",
        companyId: string,
        individualId: string,
        teamId: CompanyTeamTypes,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteIndividualMutationVariables = {
  input: DeleteIndividualInput,
  condition?: ModelIndividualConditionInput | null,
};

export type DeleteIndividualMutation = {
  deleteIndividual?:  {
    __typename: "Individual",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    email: string,
    companyId: string,
    dateOfBirth?: string | null,
    firstName?: string | null,
    gender?: string | null,
    contactNumber?: string | null,
    preferredName?: string | null,
    familyName?: string | null,
    title?: string | null,
    role?: UserType | null,
    jobTitle?: string | null,
    religion?: Religion | null,
    ethnicDesignation?: EthnicDesignation | null,
    joinDate?: string | null,
    accountStatus?: UserAccountStatus | null,
    appointments?:  {
      __typename: "IndividualAppointments",
      Chair: number,
      SID: number,
      NonExecutive: number,
      CommitteeChair: number,
      Other: number,
    } | null,
    ownedCompanies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    teams?:  {
      __typename: "ModelTeamIndividualConnection",
      items:  Array< {
        __typename: "TeamIndividual",
        companyId: string,
        individualId: string,
        teamId: CompanyTeamTypes,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateMetricMutationVariables = {
  input: CreateMetricInput,
  condition?: ModelMetricConditionInput | null,
};

export type CreateMetricMutation = {
  createMetric?:  {
    __typename: "Metric",
    sourceId: string,
    action: MetricAction,
    period: string,
    createdAt: string,
    updatedAt: string,
    data: string,
    events: Array< string | null >,
  } | null,
};

export type UpdateMetricMutationVariables = {
  input: UpdateMetricInput,
  condition?: ModelMetricConditionInput | null,
};

export type UpdateMetricMutation = {
  updateMetric?:  {
    __typename: "Metric",
    sourceId: string,
    action: MetricAction,
    period: string,
    createdAt: string,
    updatedAt: string,
    data: string,
    events: Array< string | null >,
  } | null,
};

export type CreateMetricEventMutationVariables = {
  input: CreateMetricEventInput,
  condition?: ModelMetricEventConditionInput | null,
};

export type CreateMetricEventMutation = {
  createMetricEvent?:  {
    __typename: "MetricEvent",
    id: string,
    action: MetricEventAction,
    companyId?: string | null,
    cognitoIdentityId?: string | null,
    createdAt: string,
    data: string,
    updatedAt: string,
  } | null,
};

export type CreatePackageMutationVariables = {
  input: CreatePackageInput,
  condition?: ModelPackageConditionInput | null,
};

export type CreatePackageMutation = {
  createPackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePackageMutationVariables = {
  input: UpdatePackageInput,
  condition?: ModelPackageConditionInput | null,
};

export type UpdatePackageMutation = {
  updatePackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePackageMutationVariables = {
  input: DeletePackageInput,
  condition?: ModelPackageConditionInput | null,
};

export type DeletePackageMutation = {
  deletePackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePackageItemMutationVariables = {
  input: CreatePackageItemInput,
  condition?: ModelPackageItemConditionInput | null,
};

export type CreatePackageItemMutation = {
  createPackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePackageItemMutationVariables = {
  input: UpdatePackageItemInput,
  condition?: ModelPackageItemConditionInput | null,
};

export type UpdatePackageItemMutation = {
  updatePackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePackageItemMutationVariables = {
  input: DeletePackageItemInput,
  condition?: ModelPackageItemConditionInput | null,
};

export type DeletePackageItemMutation = {
  deletePackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionnaireMutationVariables = {
  input: CreateQuestionnaireInput,
  condition?: ModelQuestionnaireConditionInput | null,
};

export type CreateQuestionnaireMutation = {
  createQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionnaireMutationVariables = {
  input: UpdateQuestionnaireInput,
  condition?: ModelQuestionnaireConditionInput | null,
};

export type UpdateQuestionnaireMutation = {
  updateQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionnaireMutationVariables = {
  input: DeleteQuestionnaireInput,
  condition?: ModelQuestionnaireConditionInput | null,
};

export type DeleteQuestionnaireMutation = {
  deleteQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    companyId: string,
    surveyGroupId: string,
    reportGroupId?: string | null,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    variantCode?: string | null,
    owner?: string | null,
    summary?: string | null,
    focusAreas?: string | null,
    configuration?: string | null,
    sections?:  {
      __typename: "ModelReportSectionConnection",
      items:  Array< {
        __typename: "ReportSection",
        id: string,
        companyId: string,
        reportId: string,
        sectionId: string,
        variantCode?: string | null,
        summary?: string | null,
        introduction?: string | null,
        results?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelReportDocumentConnection",
      items:  Array< {
        __typename: "ReportDocument",
        id: string,
        reportId?: string | null,
        requesterId: string,
        companyId: string,
        targetIndividualId?: string | null,
        variantCode?: string | null,
        surveyGroupId: string,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        queuedAt?: string | null,
        deletedAt?: string | null,
        startedProcessingAt?: string | null,
        finishedAt?: string | null,
        failedAt?: string | null,
        key?: string | null,
        template?: ReportTemplate | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    companyId: string,
    surveyGroupId: string,
    reportGroupId?: string | null,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    variantCode?: string | null,
    owner?: string | null,
    summary?: string | null,
    focusAreas?: string | null,
    configuration?: string | null,
    sections?:  {
      __typename: "ModelReportSectionConnection",
      items:  Array< {
        __typename: "ReportSection",
        id: string,
        companyId: string,
        reportId: string,
        sectionId: string,
        variantCode?: string | null,
        summary?: string | null,
        introduction?: string | null,
        results?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelReportDocumentConnection",
      items:  Array< {
        __typename: "ReportDocument",
        id: string,
        reportId?: string | null,
        requesterId: string,
        companyId: string,
        targetIndividualId?: string | null,
        variantCode?: string | null,
        surveyGroupId: string,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        queuedAt?: string | null,
        deletedAt?: string | null,
        startedProcessingAt?: string | null,
        finishedAt?: string | null,
        failedAt?: string | null,
        key?: string | null,
        template?: ReportTemplate | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    companyId: string,
    surveyGroupId: string,
    reportGroupId?: string | null,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    variantCode?: string | null,
    owner?: string | null,
    summary?: string | null,
    focusAreas?: string | null,
    configuration?: string | null,
    sections?:  {
      __typename: "ModelReportSectionConnection",
      items:  Array< {
        __typename: "ReportSection",
        id: string,
        companyId: string,
        reportId: string,
        sectionId: string,
        variantCode?: string | null,
        summary?: string | null,
        introduction?: string | null,
        results?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelReportDocumentConnection",
      items:  Array< {
        __typename: "ReportDocument",
        id: string,
        reportId?: string | null,
        requesterId: string,
        companyId: string,
        targetIndividualId?: string | null,
        variantCode?: string | null,
        surveyGroupId: string,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        queuedAt?: string | null,
        deletedAt?: string | null,
        startedProcessingAt?: string | null,
        finishedAt?: string | null,
        failedAt?: string | null,
        key?: string | null,
        template?: ReportTemplate | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReportDocumentMutationVariables = {
  input: CreateReportDocumentInput,
  condition?: ModelReportDocumentConditionInput | null,
};

export type CreateReportDocumentMutation = {
  createReportDocument?:  {
    __typename: "ReportDocument",
    id: string,
    reportId?: string | null,
    requesterId: string,
    companyId: string,
    targetIndividualId?: string | null,
    variantCode?: string | null,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    queuedAt?: string | null,
    deletedAt?: string | null,
    startedProcessingAt?: string | null,
    finishedAt?: string | null,
    failedAt?: string | null,
    key?: string | null,
    template?: ReportTemplate | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReportDocumentMutationVariables = {
  input: UpdateReportDocumentInput,
  condition?: ModelReportDocumentConditionInput | null,
};

export type UpdateReportDocumentMutation = {
  updateReportDocument?:  {
    __typename: "ReportDocument",
    id: string,
    reportId?: string | null,
    requesterId: string,
    companyId: string,
    targetIndividualId?: string | null,
    variantCode?: string | null,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    queuedAt?: string | null,
    deletedAt?: string | null,
    startedProcessingAt?: string | null,
    finishedAt?: string | null,
    failedAt?: string | null,
    key?: string | null,
    template?: ReportTemplate | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReportDocumentMutationVariables = {
  input: DeleteReportDocumentInput,
  condition?: ModelReportDocumentConditionInput | null,
};

export type DeleteReportDocumentMutation = {
  deleteReportDocument?:  {
    __typename: "ReportDocument",
    id: string,
    reportId?: string | null,
    requesterId: string,
    companyId: string,
    targetIndividualId?: string | null,
    variantCode?: string | null,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    queuedAt?: string | null,
    deletedAt?: string | null,
    startedProcessingAt?: string | null,
    finishedAt?: string | null,
    failedAt?: string | null,
    key?: string | null,
    template?: ReportTemplate | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReportGroupMutationVariables = {
  input: CreateReportGroupInput,
  condition?: ModelReportGroupConditionInput | null,
};

export type CreateReportGroupMutation = {
  createReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateReportGroupMutationVariables = {
  input: UpdateReportGroupInput,
  condition?: ModelReportGroupConditionInput | null,
};

export type UpdateReportGroupMutation = {
  updateReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteReportGroupMutationVariables = {
  input: DeleteReportGroupInput,
  condition?: ModelReportGroupConditionInput | null,
};

export type DeleteReportGroupMutation = {
  deleteReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReportSectionMutationVariables = {
  input: CreateReportSectionInput,
  condition?: ModelReportSectionConditionInput | null,
};

export type CreateReportSectionMutation = {
  createReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportSectionMutationVariables = {
  input: UpdateReportSectionInput,
  condition?: ModelReportSectionConditionInput | null,
};

export type UpdateReportSectionMutation = {
  updateReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportSectionMutationVariables = {
  input: DeleteReportSectionInput,
  condition?: ModelReportSectionConditionInput | null,
};

export type DeleteReportSectionMutation = {
  deleteReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSectionMutationVariables = {
  input: CreateSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type CreateSectionMutation = {
  createSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateSectionMutationVariables = {
  input: UpdateSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type UpdateSectionMutation = {
  updateSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteSectionMutationVariables = {
  input: DeleteSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type DeleteSectionMutation = {
  deleteSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateStatisticMutationVariables = {
  input: CreateStatisticInput,
  condition?: ModelStatisticConditionInput | null,
};

export type CreateStatisticMutation = {
  createStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStatisticMutationVariables = {
  input: UpdateStatisticInput,
  condition?: ModelStatisticConditionInput | null,
};

export type UpdateStatisticMutation = {
  updateStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStatisticMutationVariables = {
  input: DeleteStatisticInput,
  condition?: ModelStatisticConditionInput | null,
};

export type DeleteStatisticMutation = {
  deleteStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStatisticTotalMutationVariables = {
  input: CreateStatisticTotalInput,
  condition?: ModelStatisticTotalConditionInput | null,
};

export type CreateStatisticTotalMutation = {
  createStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStatisticTotalMutationVariables = {
  input: UpdateStatisticTotalInput,
  condition?: ModelStatisticTotalConditionInput | null,
};

export type UpdateStatisticTotalMutation = {
  updateStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStatisticTotalMutationVariables = {
  input: DeleteStatisticTotalInput,
  condition?: ModelStatisticTotalConditionInput | null,
};

export type DeleteStatisticTotalMutation = {
  deleteStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSurveyMutationVariables = {
  input: CreateSurveyInput,
  condition?: ModelSurveyConditionInput | null,
};

export type CreateSurveyMutation = {
  createSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSurveyMutationVariables = {
  input: UpdateSurveyInput,
  condition?: ModelSurveyConditionInput | null,
};

export type UpdateSurveyMutation = {
  updateSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSurveyMutationVariables = {
  input: DeleteSurveyInput,
  condition?: ModelSurveyConditionInput | null,
};

export type DeleteSurveyMutation = {
  deleteSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSurveyGroupMutationVariables = {
  input: CreateSurveyGroupInput,
  condition?: ModelSurveyGroupConditionInput | null,
};

export type CreateSurveyGroupMutation = {
  createSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSurveyGroupMutationVariables = {
  input: UpdateSurveyGroupInput,
  condition?: ModelSurveyGroupConditionInput | null,
};

export type UpdateSurveyGroupMutation = {
  updateSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSurveyGroupMutationVariables = {
  input: DeleteSurveyGroupInput,
  condition?: ModelSurveyGroupConditionInput | null,
};

export type DeleteSurveyGroupMutation = {
  deleteSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTeamIndividualMutationVariables = {
  input: CreateTeamIndividualInput,
  condition?: ModelTeamIndividualConditionInput | null,
};

export type CreateTeamIndividualMutation = {
  createTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTeamIndividualMutationVariables = {
  input: UpdateTeamIndividualInput,
  condition?: ModelTeamIndividualConditionInput | null,
};

export type UpdateTeamIndividualMutation = {
  updateTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTeamIndividualMutationVariables = {
  input: DeleteTeamIndividualInput,
  condition?: ModelTeamIndividualConditionInput | null,
};

export type DeleteTeamIndividualMutation = {
  deleteTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetBlockQueryVariables = {
  id: string,
  variantCode: string,
};

export type GetBlockQuery = {
  getBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type ListBlocksQueryVariables = {
  id?: string | null,
  variantCode?: ModelStringKeyConditionInput | null,
  filter?: ModelBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBlocksQuery = {
  listBlocks?:  {
    __typename: "ModelBlockConnection",
    items:  Array< {
      __typename: "Block",
      id: string,
      variantCode: string,
      sectionId: string,
      groupId?: string | null,
      companyId: string,
      createdAt: string,
      variant?: BlockType | null,
      data: string,
      displayOrder: number,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCognitoIdentityQueryVariables = {
  id: string,
};

export type GetCognitoIdentityQuery = {
  getCognitoIdentity?:  {
    __typename: "CognitoIdentity",
    id: string,
    cognitoId: string,
    userCreatedBy: string,
    createdAt: string,
    updatedAt: string,
    displayName: string,
    email: string,
    defaultLanguage: LanguageCode,
    avatar?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    companies?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: UserAccountStatus | null,
  } | null,
};

export type ListCognitoIdentitiesQueryVariables = {
  filter?: ModelCognitoIdentityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCognitoIdentitiesQuery = {
  listCognitoIdentities?:  {
    __typename: "ModelCognitoIdentityConnection",
    items:  Array< {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByCognitoIdQueryVariables = {
  cognitoId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCognitoIdentityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByCognitoIdQuery = {
  getUserByCognitoId?:  {
    __typename: "ModelCognitoIdentityConnection",
    items:  Array< {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCognitoIdentityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelCognitoIdentityConnection",
    items:  Array< {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommitteeQueryVariables = {
  id: string,
};

export type GetCommitteeQuery = {
  getCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCommitteesQueryVariables = {
  filter?: ModelCommitteeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommitteesQuery = {
  listCommittees?:  {
    __typename: "ModelCommitteeConnection",
    items:  Array< {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommitteesByCompanyIdQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommitteeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommitteesByCompanyIdQuery = {
  listCommitteesByCompanyId?:  {
    __typename: "ModelCommitteeConnection",
    items:  Array< {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyByOwnerIdQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyByOwnerIdQuery = {
  companyByOwnerId?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyIndividualQueryVariables = {
  individualId: string,
  companyId: string,
};

export type GetCompanyIndividualQuery = {
  getCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListCompanyIndividualsQueryVariables = {
  individualId?: string | null,
  companyId?: ModelIDKeyConditionInput | null,
  filter?: ModelCompanyIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyIndividualsQuery = {
  listCompanyIndividuals?:  {
    __typename: "ModelCompanyIndividualConnection",
    items:  Array< {
      __typename: "CompanyIndividual",
      createdAt: string,
      companyId: string,
      individualId: string,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IndividualsByCompanyIdQueryVariables = {
  companyId: string,
  individualId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IndividualsByCompanyIdQuery = {
  individualsByCompanyId?:  {
    __typename: "ModelCompanyIndividualConnection",
    items:  Array< {
      __typename: "CompanyIndividual",
      createdAt: string,
      companyId: string,
      individualId: string,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IndividualsByIndividualIdQueryVariables = {
  individualId: string,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IndividualsByIndividualIdQuery = {
  individualsByIndividualId?:  {
    __typename: "ModelCompanyIndividualConnection",
    items:  Array< {
      __typename: "CompanyIndividual",
      createdAt: string,
      companyId: string,
      individualId: string,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyPackageQueryVariables = {
  companyId: string,
  packageId: string,
};

export type GetCompanyPackageQuery = {
  getCompanyPackage?:  {
    __typename: "CompanyPackage",
    companyId: string,
    packageId: string,
    inherited: boolean,
    public: boolean,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompanyPackagesQueryVariables = {
  companyId?: string | null,
  packageId?: ModelIDKeyConditionInput | null,
  filter?: ModelCompanyPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyPackagesQuery = {
  listCompanyPackages?:  {
    __typename: "ModelCompanyPackageConnection",
    items:  Array< {
      __typename: "CompanyPackage",
      companyId: string,
      packageId: string,
      inherited: boolean,
      public: boolean,
      package?:  {
        __typename: "Package",
        id: string,
        categories: Array< PackageCategories | null >,
        name: string,
        description?: string | null,
        variants: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyRelationQueryVariables = {
  parentId: string,
  childId: string,
  relationship: CompanyRelationship,
};

export type GetCompanyRelationQuery = {
  getCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompanyRelationsQueryVariables = {
  parentId?: string | null,
  childIdRelationship?: ModelCompanyRelationPrimaryCompositeKeyConditionInput | null,
  filter?: ModelCompanyRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyRelationsQuery = {
  listCompanyRelations?:  {
    __typename: "ModelCompanyRelationConnection",
    items:  Array< {
      __typename: "CompanyRelation",
      parentId: string,
      childId: string,
      relationship: CompanyRelationship,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyChildrenQueryVariables = {
  parentId: string,
  relationship?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCompanyChildrenQuery = {
  getCompanyChildren?:  {
    __typename: "ModelCompanyRelationConnection",
    items:  Array< {
      __typename: "CompanyRelation",
      parentId: string,
      childId: string,
      relationship: CompanyRelationship,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyParentsQueryVariables = {
  childId: string,
  relationship?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCompanyParentsQuery = {
  getCompanyParents?:  {
    __typename: "ModelCompanyRelationConnection",
    items:  Array< {
      __typename: "CompanyRelation",
      parentId: string,
      childId: string,
      relationship: CompanyRelationship,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyUserQueryVariables = {
  cognitoIdentityId: string,
  companyId: string,
};

export type GetCompanyUserQuery = {
  getCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompanyUsersQueryVariables = {
  cognitoIdentityId?: string | null,
  companyId?: ModelIDKeyConditionInput | null,
  filter?: ModelCompanyUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyUsersQuery = {
  listCompanyUsers?:  {
    __typename: "ModelCompanyUserConnection",
    items:  Array< {
      __typename: "CompanyUser",
      cognitoIdentityId: string,
      companyId: string,
      accountType: AccountType,
      user?:  {
        __typename: "CognitoIdentity",
        id: string,
        cognitoId: string,
        userCreatedBy: string,
        createdAt: string,
        updatedAt: string,
        displayName: string,
        email: string,
        defaultLanguage: LanguageCode,
        status?: UserAccountStatus | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyUserByCognitoIdentityIdQueryVariables = {
  cognitoIdentityId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyUserByCognitoIdentityIdQuery = {
  companyUserByCognitoIdentityId?:  {
    __typename: "ModelCompanyUserConnection",
    items:  Array< {
      __typename: "CompanyUser",
      cognitoIdentityId: string,
      companyId: string,
      accountType: AccountType,
      user?:  {
        __typename: "CognitoIdentity",
        id: string,
        cognitoId: string,
        userCreatedBy: string,
        createdAt: string,
        updatedAt: string,
        displayName: string,
        email: string,
        defaultLanguage: LanguageCode,
        status?: UserAccountStatus | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyUserByCompanyIdQueryVariables = {
  companyId: string,
  cognitoIdentityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyUserByCompanyIdQuery = {
  companyUserByCompanyId?:  {
    __typename: "ModelCompanyUserConnection",
    items:  Array< {
      __typename: "CompanyUser",
      cognitoIdentityId: string,
      companyId: string,
      accountType: AccountType,
      user?:  {
        __typename: "CognitoIdentity",
        id: string,
        cognitoId: string,
        userCreatedBy: string,
        createdAt: string,
        updatedAt: string,
        displayName: string,
        email: string,
        defaultLanguage: LanguageCode,
        status?: UserAccountStatus | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyUserPackageQueryVariables = {
  companyId: string,
  cognitoIdentityId: string,
  packageId: string,
};

export type GetCompanyUserPackageQuery = {
  getCompanyUserPackage?:  {
    __typename: "CompanyUserPackage",
    companyId: string,
    cognitoIdentityId: string,
    packageId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompanyUserPackagesQueryVariables = {
  companyId?: string | null,
  cognitoIdentityIdPackageId?: ModelCompanyUserPackagePrimaryCompositeKeyConditionInput | null,
  filter?: ModelCompanyUserPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyUserPackagesQuery = {
  listCompanyUserPackages?:  {
    __typename: "ModelCompanyUserPackageConnection",
    items:  Array< {
      __typename: "CompanyUserPackage",
      companyId: string,
      cognitoIdentityId: string,
      packageId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompanyUserPackagesByCompanyUserQueryVariables = {
  cognitoIdentityId: string,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyUserPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyUserPackagesByCompanyUserQuery = {
  listCompanyUserPackagesByCompanyUser?:  {
    __typename: "ModelCompanyUserPackageConnection",
    items:  Array< {
      __typename: "CompanyUserPackage",
      companyId: string,
      cognitoIdentityId: string,
      packageId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDefaultContentQueryVariables = {
  id: string,
};

export type GetDefaultContentQuery = {
  getDefaultContent?:  {
    __typename: "DefaultContent",
    id: string,
    type: ContentType,
    key: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDefaultContentsQueryVariables = {
  filter?: ModelDefaultContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDefaultContentsQuery = {
  listDefaultContents?:  {
    __typename: "ModelDefaultContentConnection",
    items:  Array< {
      __typename: "DefaultContent",
      id: string,
      type: ContentType,
      key: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DefaultContentByTypeQueryVariables = {
  type: ContentType,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDefaultContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DefaultContentByTypeQuery = {
  defaultContentByType?:  {
    __typename: "ModelDefaultContentConnection",
    items:  Array< {
      __typename: "DefaultContent",
      id: string,
      type: ContentType,
      key: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DefaultContentByIdentifierQueryVariables = {
  type: ContentType,
  key?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDefaultContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DefaultContentByIdentifierQuery = {
  defaultContentByIdentifier?:  {
    __typename: "ModelDefaultContentConnection",
    items:  Array< {
      __typename: "DefaultContent",
      id: string,
      type: ContentType,
      key: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIndividualQueryVariables = {
  id: string,
};

export type GetIndividualQuery = {
  getIndividual?:  {
    __typename: "Individual",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    email: string,
    companyId: string,
    dateOfBirth?: string | null,
    firstName?: string | null,
    gender?: string | null,
    contactNumber?: string | null,
    preferredName?: string | null,
    familyName?: string | null,
    title?: string | null,
    role?: UserType | null,
    jobTitle?: string | null,
    religion?: Religion | null,
    ethnicDesignation?: EthnicDesignation | null,
    joinDate?: string | null,
    accountStatus?: UserAccountStatus | null,
    appointments?:  {
      __typename: "IndividualAppointments",
      Chair: number,
      SID: number,
      NonExecutive: number,
      CommitteeChair: number,
      Other: number,
    } | null,
    ownedCompanies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    teams?:  {
      __typename: "ModelTeamIndividualConnection",
      items:  Array< {
        __typename: "TeamIndividual",
        companyId: string,
        individualId: string,
        teamId: CompanyTeamTypes,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListIndividualsQueryVariables = {
  filter?: ModelIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualsQuery = {
  listIndividuals?:  {
    __typename: "ModelIndividualConnection",
    items:  Array< {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIndividualByEmailQueryVariables = {
  email: string,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetIndividualByEmailQuery = {
  getIndividualByEmail?:  {
    __typename: "ModelIndividualConnection",
    items:  Array< {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPackageQueryVariables = {
  id: string,
};

export type GetPackageQuery = {
  getPackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPackagesQueryVariables = {
  filter?: ModelPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPackagesQuery = {
  listPackages?:  {
    __typename: "ModelPackageConnection",
    items:  Array< {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPackageItemQueryVariables = {
  packageId: string,
  type: PackageItemTypes,
  key: string,
};

export type GetPackageItemQuery = {
  getPackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPackageItemsQueryVariables = {
  packageId?: string | null,
  typeKey?: ModelPackageItemPrimaryCompositeKeyConditionInput | null,
  filter?: ModelPackageItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPackageItemsQuery = {
  listPackageItems?:  {
    __typename: "ModelPackageItemConnection",
    items:  Array< {
      __typename: "PackageItem",
      packageId: string,
      type: PackageItemTypes,
      key: string,
      content: string,
      package?:  {
        __typename: "Package",
        id: string,
        categories: Array< PackageCategories | null >,
        name: string,
        description?: string | null,
        variants: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PackageItemsByPackageIdQueryVariables = {
  packageId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPackageItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PackageItemsByPackageIdQuery = {
  packageItemsByPackageId?:  {
    __typename: "ModelPackageItemConnection",
    items:  Array< {
      __typename: "PackageItem",
      packageId: string,
      type: PackageItemTypes,
      key: string,
      content: string,
      package?:  {
        __typename: "Package",
        id: string,
        categories: Array< PackageCategories | null >,
        name: string,
        description?: string | null,
        variants: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionnaireQueryVariables = {
  id: string,
};

export type GetQuestionnaireQuery = {
  getQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListQuestionnairesQueryVariables = {
  filter?: ModelQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionnairesQuery = {
  listQuestionnaires?:  {
    __typename: "ModelQuestionnaireConnection",
    items:  Array< {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionnairesByCompanyIdQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionnairesByCompanyIdQuery = {
  questionnairesByCompanyId?:  {
    __typename: "ModelQuestionnaireConnection",
    items:  Array< {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    companyId: string,
    surveyGroupId: string,
    reportGroupId?: string | null,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    variantCode?: string | null,
    owner?: string | null,
    summary?: string | null,
    focusAreas?: string | null,
    configuration?: string | null,
    sections?:  {
      __typename: "ModelReportSectionConnection",
      items:  Array< {
        __typename: "ReportSection",
        id: string,
        companyId: string,
        reportId: string,
        sectionId: string,
        variantCode?: string | null,
        summary?: string | null,
        introduction?: string | null,
        results?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelReportDocumentConnection",
      items:  Array< {
        __typename: "ReportDocument",
        id: string,
        reportId?: string | null,
        requesterId: string,
        companyId: string,
        targetIndividualId?: string | null,
        variantCode?: string | null,
        surveyGroupId: string,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        queuedAt?: string | null,
        deletedAt?: string | null,
        startedProcessingAt?: string | null,
        finishedAt?: string | null,
        failedAt?: string | null,
        key?: string | null,
        template?: ReportTemplate | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportBySurveyGroupIdQueryVariables = {
  surveyGroupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportBySurveyGroupIdQuery = {
  reportBySurveyGroupId?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportsByReportGroupQueryVariables = {
  reportGroupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsByReportGroupQuery = {
  listReportsByReportGroup?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportDocumentQueryVariables = {
  id: string,
};

export type GetReportDocumentQuery = {
  getReportDocument?:  {
    __typename: "ReportDocument",
    id: string,
    reportId?: string | null,
    requesterId: string,
    companyId: string,
    targetIndividualId?: string | null,
    variantCode?: string | null,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    queuedAt?: string | null,
    deletedAt?: string | null,
    startedProcessingAt?: string | null,
    finishedAt?: string | null,
    failedAt?: string | null,
    key?: string | null,
    template?: ReportTemplate | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListReportDocumentsQueryVariables = {
  filter?: ModelReportDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportDocumentsQuery = {
  listReportDocuments?:  {
    __typename: "ModelReportDocumentConnection",
    items:  Array< {
      __typename: "ReportDocument",
      id: string,
      reportId?: string | null,
      requesterId: string,
      companyId: string,
      targetIndividualId?: string | null,
      variantCode?: string | null,
      surveyGroupId: string,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      queuedAt?: string | null,
      deletedAt?: string | null,
      startedProcessingAt?: string | null,
      finishedAt?: string | null,
      failedAt?: string | null,
      key?: string | null,
      template?: ReportTemplate | null,
      report?:  {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DocumentsByReportIdQueryVariables = {
  reportId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentsByReportIdQuery = {
  documentsByReportId?:  {
    __typename: "ModelReportDocumentConnection",
    items:  Array< {
      __typename: "ReportDocument",
      id: string,
      reportId?: string | null,
      requesterId: string,
      companyId: string,
      targetIndividualId?: string | null,
      variantCode?: string | null,
      surveyGroupId: string,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      queuedAt?: string | null,
      deletedAt?: string | null,
      startedProcessingAt?: string | null,
      finishedAt?: string | null,
      failedAt?: string | null,
      key?: string | null,
      template?: ReportTemplate | null,
      report?:  {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportDocumentsByDateQueryVariables = {
  surveyGroupId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportDocumentsByDateQuery = {
  reportDocumentsByDate?:  {
    __typename: "ModelReportDocumentConnection",
    items:  Array< {
      __typename: "ReportDocument",
      id: string,
      reportId?: string | null,
      requesterId: string,
      companyId: string,
      targetIndividualId?: string | null,
      variantCode?: string | null,
      surveyGroupId: string,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      queuedAt?: string | null,
      deletedAt?: string | null,
      startedProcessingAt?: string | null,
      finishedAt?: string | null,
      failedAt?: string | null,
      key?: string | null,
      template?: ReportTemplate | null,
      report?:  {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportGroupQueryVariables = {
  id: string,
};

export type GetReportGroupQuery = {
  getReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListReportGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelReportGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportGroupsQuery = {
  listReportGroups?:  {
    __typename: "ModelReportGroupConnection",
    items:  Array< {
      __typename: "ReportGroup",
      id: string,
      surveyGroupId: string,
      companyId: string,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      configuration?:  {
        __typename: "ReportGroupConfiguration",
        hideComparisonResponses?: boolean | null,
        combineComparisonResponses?: boolean | null,
      } | null,
      scores?: string | null,
      quartiles?: string | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportGroupsBySurveyGroupQueryVariables = {
  surveyGroupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportGroupsBySurveyGroupQuery = {
  listReportGroupsBySurveyGroup?:  {
    __typename: "ModelReportGroupConnection",
    items:  Array< {
      __typename: "ReportGroup",
      id: string,
      surveyGroupId: string,
      companyId: string,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      configuration?:  {
        __typename: "ReportGroupConfiguration",
        hideComparisonResponses?: boolean | null,
        combineComparisonResponses?: boolean | null,
      } | null,
      scores?: string | null,
      quartiles?: string | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportGroupsByCompanyQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportGroupsByCompanyQuery = {
  listReportGroupsByCompany?:  {
    __typename: "ModelReportGroupConnection",
    items:  Array< {
      __typename: "ReportGroup",
      id: string,
      surveyGroupId: string,
      companyId: string,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      configuration?:  {
        __typename: "ReportGroupConfiguration",
        hideComparisonResponses?: boolean | null,
        combineComparisonResponses?: boolean | null,
      } | null,
      scores?: string | null,
      quartiles?: string | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportSectionQueryVariables = {
  id: string,
};

export type GetReportSectionQuery = {
  getReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportSectionsQueryVariables = {
  filter?: ModelReportSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportSectionsQuery = {
  listReportSections?:  {
    __typename: "ModelReportSectionConnection",
    items:  Array< {
      __typename: "ReportSection",
      id: string,
      companyId: string,
      reportId: string,
      sectionId: string,
      variantCode?: string | null,
      summary?: string | null,
      introduction?: string | null,
      subSectionIntroductions?:  Array< {
        __typename: "SubSectionIntroduction",
        slug?: string | null,
        value?: string | null,
        reviewedAt?: string | null,
      } | null > | null,
      results?: string | null,
      report?:  {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportSectionsBySectionIdQueryVariables = {
  reportId: string,
  sectionId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportSectionsBySectionIdQuery = {
  reportSectionsBySectionId?:  {
    __typename: "ModelReportSectionConnection",
    items:  Array< {
      __typename: "ReportSection",
      id: string,
      companyId: string,
      reportId: string,
      sectionId: string,
      variantCode?: string | null,
      summary?: string | null,
      introduction?: string | null,
      subSectionIntroductions?:  Array< {
        __typename: "SubSectionIntroduction",
        slug?: string | null,
        value?: string | null,
        reviewedAt?: string | null,
      } | null > | null,
      results?: string | null,
      report?:  {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSectionQueryVariables = {
  id: string,
  variantCode: string,
};

export type GetSectionQuery = {
  getSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListSectionsQueryVariables = {
  id?: string | null,
  variantCode?: ModelStringKeyConditionInput | null,
  filter?: ModelSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSectionsQuery = {
  listSections?:  {
    __typename: "ModelSectionConnection",
    items:  Array< {
      __typename: "Section",
      id: string,
      masterSectionId?: string | null,
      variantCode: string,
      questionnaireId: string,
      companyId: string,
      createdAt: string,
      name: string,
      slug?: string | null,
      displayOrder: number,
      blocks?:  {
        __typename: "ModelBlockConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SectionsByQuestionnaireIdQueryVariables = {
  questionnaireId: string,
  variantCode?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SectionsByQuestionnaireIdQuery = {
  sectionsByQuestionnaireId?:  {
    __typename: "ModelSectionConnection",
    items:  Array< {
      __typename: "Section",
      id: string,
      masterSectionId?: string | null,
      variantCode: string,
      questionnaireId: string,
      companyId: string,
      createdAt: string,
      name: string,
      slug?: string | null,
      displayOrder: number,
      blocks?:  {
        __typename: "ModelBlockConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStatisticQueryVariables = {
  collection: string,
  category: string,
  companyId: string,
};

export type GetStatisticQuery = {
  getStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStatisticsQueryVariables = {
  collection?: string | null,
  categoryCompanyId?: ModelStatisticPrimaryCompositeKeyConditionInput | null,
  filter?: ModelStatisticFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStatisticsQuery = {
  listStatistics?:  {
    __typename: "ModelStatisticConnection",
    items:  Array< {
      __typename: "Statistic",
      collection: string,
      category: string,
      companyId: string,
      results:  Array< {
        __typename: "StatsResult",
        key: string,
        name: string,
        value: number,
      } | null >,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStatisticTotalQueryVariables = {
  collection: string,
  category: string,
};

export type GetStatisticTotalQuery = {
  getStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStatisticTotalsQueryVariables = {
  collection?: string | null,
  category?: ModelStringKeyConditionInput | null,
  filter?: ModelStatisticTotalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStatisticTotalsQuery = {
  listStatisticTotals?:  {
    __typename: "ModelStatisticTotalConnection",
    items:  Array< {
      __typename: "StatisticTotal",
      collection: string,
      category: string,
      resultCount: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSurveyQueryVariables = {
  id: string,
};

export type GetSurveyQuery = {
  getSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSurveysQueryVariables = {
  id?: string | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSurveysQuery = {
  listSurveys?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      owner: string,
      key?: string | null,
      variantCode?: string | null,
      dispatchedAt?: string | null,
      finishedAt?: string | null,
      deletedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      response?: string | null,
      status?: SurveyStatus | null,
      tags?: Array< string | null > | null,
      invitation?: string | null,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      questionnaire?:  {
        __typename: "Questionnaire",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        companyId: string,
        name: string,
        status?: QuestionnaireStatus | null,
        groups?: string | null,
        customContent?: string | null,
        packageId?: string | null,
        editDisabled?: boolean | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SurveysByGroupIdQueryVariables = {
  groupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SurveysByGroupIdQuery = {
  surveysByGroupId?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      owner: string,
      key?: string | null,
      variantCode?: string | null,
      dispatchedAt?: string | null,
      finishedAt?: string | null,
      deletedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      response?: string | null,
      status?: SurveyStatus | null,
      tags?: Array< string | null > | null,
      invitation?: string | null,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      questionnaire?:  {
        __typename: "Questionnaire",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        companyId: string,
        name: string,
        status?: QuestionnaireStatus | null,
        groups?: string | null,
        customContent?: string | null,
        packageId?: string | null,
        editDisabled?: boolean | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SurveysByQuestionnaireIdQueryVariables = {
  questionnaireId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SurveysByQuestionnaireIdQuery = {
  surveysByQuestionnaireId?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      owner: string,
      key?: string | null,
      variantCode?: string | null,
      dispatchedAt?: string | null,
      finishedAt?: string | null,
      deletedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      response?: string | null,
      status?: SurveyStatus | null,
      tags?: Array< string | null > | null,
      invitation?: string | null,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      questionnaire?:  {
        __typename: "Questionnaire",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        companyId: string,
        name: string,
        status?: QuestionnaireStatus | null,
        groups?: string | null,
        customContent?: string | null,
        packageId?: string | null,
        editDisabled?: boolean | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SurveysByOwnerQueryVariables = {
  owner: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SurveysByOwnerQuery = {
  surveysByOwner?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      owner: string,
      key?: string | null,
      variantCode?: string | null,
      dispatchedAt?: string | null,
      finishedAt?: string | null,
      deletedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      response?: string | null,
      status?: SurveyStatus | null,
      tags?: Array< string | null > | null,
      invitation?: string | null,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        updatedAt: string,
      } | null,
      questionnaire?:  {
        __typename: "Questionnaire",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        companyId: string,
        name: string,
        status?: QuestionnaireStatus | null,
        groups?: string | null,
        customContent?: string | null,
        packageId?: string | null,
        editDisabled?: boolean | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSurveyGroupQueryVariables = {
  id: string,
};

export type GetSurveyGroupQuery = {
  getSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSurveyGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelSurveyGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSurveyGroupsQuery = {
  listSurveyGroups?:  {
    __typename: "ModelSurveyGroupConnection",
    items:  Array< {
      __typename: "SurveyGroup",
      id: string,
      questionnaireId: string,
      companyId: string,
      packageId?: string | null,
      targetIndividualIds?: Array< string | null > | null,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      status: SurveyGroupStatus,
      createdAt: string,
      updatedAt: string,
      releasedAt?: string | null,
      dispatchedAt?: string | null,
      deletedAt?: string | null,
      tags?: Array< string | null > | null,
      includeInStats?: boolean | null,
      surveys?:  {
        __typename: "ModelSurveyConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSurveyGroupsByQuestionnaireQueryVariables = {
  questionnaireId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSurveyGroupsByQuestionnaireQuery = {
  listSurveyGroupsByQuestionnaire?:  {
    __typename: "ModelSurveyGroupConnection",
    items:  Array< {
      __typename: "SurveyGroup",
      id: string,
      questionnaireId: string,
      companyId: string,
      packageId?: string | null,
      targetIndividualIds?: Array< string | null > | null,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      status: SurveyGroupStatus,
      createdAt: string,
      updatedAt: string,
      releasedAt?: string | null,
      dispatchedAt?: string | null,
      deletedAt?: string | null,
      tags?: Array< string | null > | null,
      includeInStats?: boolean | null,
      surveys?:  {
        __typename: "ModelSurveyConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSurveyGroupsByCompanyQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSurveyGroupsByCompanyQuery = {
  listSurveyGroupsByCompany?:  {
    __typename: "ModelSurveyGroupConnection",
    items:  Array< {
      __typename: "SurveyGroup",
      id: string,
      questionnaireId: string,
      companyId: string,
      packageId?: string | null,
      targetIndividualIds?: Array< string | null > | null,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      status: SurveyGroupStatus,
      createdAt: string,
      updatedAt: string,
      releasedAt?: string | null,
      dispatchedAt?: string | null,
      deletedAt?: string | null,
      tags?: Array< string | null > | null,
      includeInStats?: boolean | null,
      surveys?:  {
        __typename: "ModelSurveyConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSurveyGroupsByPackageQueryVariables = {
  packageId: string,
  companyIdCreatedAt?: ModelSurveyGroupByPackageCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSurveyGroupsByPackageQuery = {
  listSurveyGroupsByPackage?:  {
    __typename: "ModelSurveyGroupConnection",
    items:  Array< {
      __typename: "SurveyGroup",
      id: string,
      questionnaireId: string,
      companyId: string,
      packageId?: string | null,
      targetIndividualIds?: Array< string | null > | null,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      status: SurveyGroupStatus,
      createdAt: string,
      updatedAt: string,
      releasedAt?: string | null,
      dispatchedAt?: string | null,
      deletedAt?: string | null,
      tags?: Array< string | null > | null,
      includeInStats?: boolean | null,
      surveys?:  {
        __typename: "ModelSurveyConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamIndividualQueryVariables = {
  companyId: string,
  teamId: CompanyTeamTypes,
  individualId: string,
};

export type GetTeamIndividualQuery = {
  getTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTeamIndividualsQueryVariables = {
  companyId?: string | null,
  teamIdIndividualId?: ModelTeamIndividualPrimaryCompositeKeyConditionInput | null,
  filter?: ModelTeamIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTeamIndividualsQuery = {
  listTeamIndividuals?:  {
    __typename: "ModelTeamIndividualConnection",
    items:  Array< {
      __typename: "TeamIndividual",
      companyId: string,
      individualId: string,
      teamId: CompanyTeamTypes,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIndividualTeamsQueryVariables = {
  individualId: string,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualTeamsQuery = {
  listIndividualTeams?:  {
    __typename: "ModelTeamIndividualConnection",
    items:  Array< {
      __typename: "TeamIndividual",
      companyId: string,
      individualId: string,
      teamId: CompanyTeamTypes,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateReportDocumentSubscriptionVariables = {
  companyId: string,
  requesterId?: string | null,
};

export type OnCreateReportDocumentSubscription = {
  onCreateReportDocument?:  {
    __typename: "ReportDocument",
    id: string,
    reportId?: string | null,
    requesterId: string,
    companyId: string,
    targetIndividualId?: string | null,
    variantCode?: string | null,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    queuedAt?: string | null,
    deletedAt?: string | null,
    startedProcessingAt?: string | null,
    finishedAt?: string | null,
    failedAt?: string | null,
    key?: string | null,
    template?: ReportTemplate | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReportDocumentSubscriptionVariables = {
  companyId: string,
  requesterId?: string | null,
};

export type OnUpdateReportDocumentSubscription = {
  onUpdateReportDocument?:  {
    __typename: "ReportDocument",
    id: string,
    reportId?: string | null,
    requesterId: string,
    companyId: string,
    targetIndividualId?: string | null,
    variantCode?: string | null,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    queuedAt?: string | null,
    deletedAt?: string | null,
    startedProcessingAt?: string | null,
    finishedAt?: string | null,
    failedAt?: string | null,
    key?: string | null,
    template?: ReportTemplate | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCompanyUserSubscriptionVariables = {
  cognitoIdentityId: string,
};

export type OnCreateCompanyUserSubscription = {
  onCreateCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompanyUserSubscriptionVariables = {
  cognitoIdentityId: string,
};

export type OnDeleteCompanyUserSubscription = {
  onDeleteCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      status?: UserAccountStatus | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionnaireSubscriptionVariables = {
  companyId: string,
};

export type OnCreateQuestionnaireSubscription = {
  onCreateQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionnaireSubscriptionVariables = {
  companyId: string,
};

export type OnUpdateQuestionnaireSubscription = {
  onUpdateQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionnaireSubscriptionVariables = {
  companyId: string,
};

export type OnDeleteQuestionnaireSubscription = {
  onDeleteQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    deletedAt?: string | null,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        masterSectionId?: string | null,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        slug?: string | null,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateBlockSubscriptionVariables = {
  filter?: ModelSubscriptionBlockFilterInput | null,
};

export type OnCreateBlockSubscription = {
  onCreateBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type OnUpdateBlockSubscriptionVariables = {
  filter?: ModelSubscriptionBlockFilterInput | null,
};

export type OnUpdateBlockSubscription = {
  onUpdateBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type OnDeleteBlockSubscriptionVariables = {
  filter?: ModelSubscriptionBlockFilterInput | null,
};

export type OnDeleteBlockSubscription = {
  onDeleteBlock?:  {
    __typename: "Block",
    id: string,
    variantCode: string,
    sectionId: string,
    groupId?: string | null,
    companyId: string,
    createdAt: string,
    variant?: BlockType | null,
    data: string,
    displayOrder: number,
    updatedAt: string,
  } | null,
};

export type OnCreateCommitteeSubscriptionVariables = {
  filter?: ModelSubscriptionCommitteeFilterInput | null,
};

export type OnCreateCommitteeSubscription = {
  onCreateCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommitteeSubscriptionVariables = {
  filter?: ModelSubscriptionCommitteeFilterInput | null,
};

export type OnUpdateCommitteeSubscription = {
  onUpdateCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommitteeSubscriptionVariables = {
  filter?: ModelSubscriptionCommitteeFilterInput | null,
};

export type OnDeleteCommitteeSubscription = {
  onDeleteCommittee?:  {
    __typename: "Committee",
    id: string,
    companyId: string,
    name: string,
    tags?: Array< CommitteeTag | null > | null,
    individuals?:  {
      __typename: "ModelCommitteeIndividualConnection",
      items:  Array< {
        __typename: "CommitteeIndividual",
        createdAt: string,
        companyId: string,
        committeeId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCommitteeIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionCommitteeIndividualFilterInput | null,
};

export type OnCreateCommitteeIndividualSubscription = {
  onCreateCommitteeIndividual?:  {
    __typename: "CommitteeIndividual",
    createdAt: string,
    companyId: string,
    committeeId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    committee?:  {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommitteeIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionCommitteeIndividualFilterInput | null,
};

export type OnUpdateCommitteeIndividualSubscription = {
  onUpdateCommitteeIndividual?:  {
    __typename: "CommitteeIndividual",
    createdAt: string,
    companyId: string,
    committeeId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    committee?:  {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommitteeIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionCommitteeIndividualFilterInput | null,
};

export type OnDeleteCommitteeIndividualSubscription = {
  onDeleteCommitteeIndividual?:  {
    __typename: "CommitteeIndividual",
    createdAt: string,
    companyId: string,
    committeeId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    committee?:  {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    companyType?: CompanyType | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    marketCap?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      admin?: string | null,
      report?: string | null,
    } | null,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        createdAt: string,
        companyId: string,
        individualId: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelCompanyUserConnection",
      items:  Array< {
        __typename: "CompanyUser",
        cognitoIdentityId: string,
        companyId: string,
        accountType: AccountType,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCompanyIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyIndividualFilterInput | null,
};

export type OnCreateCompanyIndividualSubscription = {
  onCreateCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCompanyIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyIndividualFilterInput | null,
};

export type OnUpdateCompanyIndividualSubscription = {
  onUpdateCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompanyIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyIndividualFilterInput | null,
};

export type OnDeleteCompanyIndividualSubscription = {
  onDeleteCompanyIndividual?:  {
    __typename: "CompanyIndividual",
    createdAt: string,
    companyId: string,
    individualId: string,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCompanyRelationSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyRelationFilterInput | null,
};

export type OnCreateCompanyRelationSubscription = {
  onCreateCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCompanyRelationSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyRelationFilterInput | null,
};

export type OnUpdateCompanyRelationSubscription = {
  onUpdateCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompanyRelationSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyRelationFilterInput | null,
};

export type OnDeleteCompanyRelationSubscription = {
  onDeleteCompanyRelation?:  {
    __typename: "CompanyRelation",
    parentId: string,
    childId: string,
    relationship: CompanyRelationship,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePackageSubscriptionVariables = {
  filter?: ModelSubscriptionPackageFilterInput | null,
};

export type OnCreatePackageSubscription = {
  onCreatePackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePackageSubscriptionVariables = {
  filter?: ModelSubscriptionPackageFilterInput | null,
};

export type OnUpdatePackageSubscription = {
  onUpdatePackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePackageSubscriptionVariables = {
  filter?: ModelSubscriptionPackageFilterInput | null,
};

export type OnDeletePackageSubscription = {
  onDeletePackage?:  {
    __typename: "Package",
    id: string,
    categories: Array< PackageCategories | null >,
    name: string,
    description?: string | null,
    variants: Array< string | null >,
    packageItems?:  {
      __typename: "ModelPackageItemConnection",
      items:  Array< {
        __typename: "PackageItem",
        packageId: string,
        type: PackageItemTypes,
        key: string,
        content: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePackageItemSubscriptionVariables = {
  filter?: ModelSubscriptionPackageItemFilterInput | null,
};

export type OnCreatePackageItemSubscription = {
  onCreatePackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePackageItemSubscriptionVariables = {
  filter?: ModelSubscriptionPackageItemFilterInput | null,
};

export type OnUpdatePackageItemSubscription = {
  onUpdatePackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePackageItemSubscriptionVariables = {
  filter?: ModelSubscriptionPackageItemFilterInput | null,
};

export type OnDeletePackageItemSubscription = {
  onDeletePackageItem?:  {
    __typename: "PackageItem",
    packageId: string,
    type: PackageItemTypes,
    key: string,
    content: string,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportGroupSubscriptionVariables = {
  filter?: ModelSubscriptionReportGroupFilterInput | null,
};

export type OnCreateReportGroupSubscription = {
  onCreateReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateReportGroupSubscriptionVariables = {
  filter?: ModelSubscriptionReportGroupFilterInput | null,
};

export type OnUpdateReportGroupSubscription = {
  onUpdateReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteReportGroupSubscriptionVariables = {
  filter?: ModelSubscriptionReportGroupFilterInput | null,
};

export type OnDeleteReportGroupSubscription = {
  onDeleteReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        generateAllIndividualsDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    scores?: string | null,
    quartiles?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        reportGroupId?: string | null,
        comparisonSurveyGroupId?: string | null,
        createdAt: string,
        updatedAt: string,
        variantCode?: string | null,
        owner?: string | null,
        summary?: string | null,
        focusAreas?: string | null,
        configuration?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateReportSectionSubscriptionVariables = {
  filter?: ModelSubscriptionReportSectionFilterInput | null,
};

export type OnCreateReportSectionSubscription = {
  onCreateReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSectionSubscriptionVariables = {
  filter?: ModelSubscriptionReportSectionFilterInput | null,
};

export type OnUpdateReportSectionSubscription = {
  onUpdateReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSectionSubscriptionVariables = {
  filter?: ModelSubscriptionReportSectionFilterInput | null,
};

export type OnDeleteReportSectionSubscription = {
  onDeleteReportSection?:  {
    __typename: "ReportSection",
    id: string,
    companyId: string,
    reportId: string,
    sectionId: string,
    variantCode?: string | null,
    summary?: string | null,
    introduction?: string | null,
    subSectionIntroductions?:  Array< {
      __typename: "SubSectionIntroduction",
      slug?: string | null,
      value?: string | null,
      reviewedAt?: string | null,
    } | null > | null,
    results?: string | null,
    report?:  {
      __typename: "Report",
      id: string,
      companyId: string,
      surveyGroupId: string,
      reportGroupId?: string | null,
      comparisonSurveyGroupId?: string | null,
      createdAt: string,
      updatedAt: string,
      variantCode?: string | null,
      owner?: string | null,
      summary?: string | null,
      focusAreas?: string | null,
      configuration?: string | null,
      sections?:  {
        __typename: "ModelReportSectionConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelReportDocumentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSectionSubscriptionVariables = {
  filter?: ModelSubscriptionSectionFilterInput | null,
};

export type OnCreateSectionSubscription = {
  onCreateSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateSectionSubscriptionVariables = {
  filter?: ModelSubscriptionSectionFilterInput | null,
};

export type OnUpdateSectionSubscription = {
  onUpdateSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteSectionSubscriptionVariables = {
  filter?: ModelSubscriptionSectionFilterInput | null,
};

export type OnDeleteSectionSubscription = {
  onDeleteSection?:  {
    __typename: "Section",
    id: string,
    masterSectionId?: string | null,
    variantCode: string,
    questionnaireId: string,
    companyId: string,
    createdAt: string,
    name: string,
    slug?: string | null,
    displayOrder: number,
    blocks?:  {
      __typename: "ModelBlockConnection",
      items:  Array< {
        __typename: "Block",
        id: string,
        variantCode: string,
        sectionId: string,
        groupId?: string | null,
        companyId: string,
        createdAt: string,
        variant?: BlockType | null,
        data: string,
        displayOrder: number,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateStatisticSubscriptionVariables = {
  filter?: ModelSubscriptionStatisticFilterInput | null,
};

export type OnCreateStatisticSubscription = {
  onCreateStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStatisticSubscriptionVariables = {
  filter?: ModelSubscriptionStatisticFilterInput | null,
};

export type OnUpdateStatisticSubscription = {
  onUpdateStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStatisticSubscriptionVariables = {
  filter?: ModelSubscriptionStatisticFilterInput | null,
};

export type OnDeleteStatisticSubscription = {
  onDeleteStatistic?:  {
    __typename: "Statistic",
    collection: string,
    category: string,
    companyId: string,
    results:  Array< {
      __typename: "StatsResult",
      key: string,
      name: string,
      value: number,
    } | null >,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      parentId?: string | null,
      name: string,
      createdAt: string,
      deletedAt?: string | null,
      companyType?: CompanyType | null,
      address?:  {
        __typename: "Address",
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        region?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      phoneNumber?: string | null,
      registrationNumber?: string | null,
      numberOfEmployees?: string | null,
      brandLogo?: string | null,
      sector?: string | null,
      ownershipStructure?: string | null,
      country?: string | null,
      employeeCount?: string | null,
      marketCap?: string | null,
      configuration?:  {
        __typename: "CompanyConfiguration",
        primaryColor?: string | null,
        secondaryColor?: string | null,
        questionnaire?: string | null,
        admin?: string | null,
        report?: string | null,
      } | null,
      tags?: Array< string > | null,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelCompanyUserConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStatisticTotalSubscriptionVariables = {
  filter?: ModelSubscriptionStatisticTotalFilterInput | null,
};

export type OnCreateStatisticTotalSubscription = {
  onCreateStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStatisticTotalSubscriptionVariables = {
  filter?: ModelSubscriptionStatisticTotalFilterInput | null,
};

export type OnUpdateStatisticTotalSubscription = {
  onUpdateStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStatisticTotalSubscriptionVariables = {
  filter?: ModelSubscriptionStatisticTotalFilterInput | null,
};

export type OnDeleteStatisticTotalSubscription = {
  onDeleteStatisticTotal?:  {
    __typename: "StatisticTotal",
    collection: string,
    category: string,
    resultCount: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSurveySubscriptionVariables = {
  filter?: ModelSubscriptionSurveyFilterInput | null,
  owner?: string | null,
};

export type OnCreateSurveySubscription = {
  onCreateSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSurveySubscriptionVariables = {
  filter?: ModelSubscriptionSurveyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSurveySubscription = {
  onUpdateSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSurveySubscriptionVariables = {
  filter?: ModelSubscriptionSurveyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSurveySubscription = {
  onDeleteSurvey?:  {
    __typename: "Survey",
    id: string,
    groupId: string,
    companyId: string,
    questionnaireId: string,
    individualId: string,
    owner: string,
    key?: string | null,
    variantCode?: string | null,
    dispatchedAt?: string | null,
    finishedAt?: string | null,
    deletedAt?: string | null,
    totals?:  {
      __typename: "Totals",
      questions?: number | null,
      questionsCompleted?: number | null,
    } | null,
    response?: string | null,
    status?: SurveyStatus | null,
    tags?: Array< string | null > | null,
    invitation?: string | null,
    individual?:  {
      __typename: "Individual",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      email: string,
      companyId: string,
      dateOfBirth?: string | null,
      firstName?: string | null,
      gender?: string | null,
      contactNumber?: string | null,
      preferredName?: string | null,
      familyName?: string | null,
      title?: string | null,
      role?: UserType | null,
      jobTitle?: string | null,
      religion?: Religion | null,
      ethnicDesignation?: EthnicDesignation | null,
      joinDate?: string | null,
      accountStatus?: UserAccountStatus | null,
      appointments?:  {
        __typename: "IndividualAppointments",
        Chair: number,
        SID: number,
        NonExecutive: number,
        CommitteeChair: number,
        Other: number,
      } | null,
      ownedCompanies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyIndividualConnection",
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeIndividualConnection",
        nextToken?: string | null,
      } | null,
      teams?:  {
        __typename: "ModelTeamIndividualConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    questionnaire?:  {
      __typename: "Questionnaire",
      id: string,
      createdAt: string,
      deletedAt?: string | null,
      companyId: string,
      name: string,
      status?: QuestionnaireStatus | null,
      groups?: string | null,
      customContent?: string | null,
      variants?:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null > | null,
      packageId?: string | null,
      editDisabled?: boolean | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        marketCap?: string | null,
        tags?: Array< string > | null,
        updatedAt: string,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSurveyGroupSubscriptionVariables = {
  filter?: ModelSubscriptionSurveyGroupFilterInput | null,
};

export type OnCreateSurveyGroupSubscription = {
  onCreateSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSurveyGroupSubscriptionVariables = {
  filter?: ModelSubscriptionSurveyGroupFilterInput | null,
};

export type OnUpdateSurveyGroupSubscription = {
  onUpdateSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSurveyGroupSubscriptionVariables = {
  filter?: ModelSubscriptionSurveyGroupFilterInput | null,
};

export type OnDeleteSurveyGroupSubscription = {
  onDeleteSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    includeInStats?: boolean | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        response?: string | null,
        status?: SurveyStatus | null,
        tags?: Array< string | null > | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTeamIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionTeamIndividualFilterInput | null,
};

export type OnCreateTeamIndividualSubscription = {
  onCreateTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTeamIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionTeamIndividualFilterInput | null,
};

export type OnUpdateTeamIndividualSubscription = {
  onUpdateTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTeamIndividualSubscriptionVariables = {
  filter?: ModelSubscriptionTeamIndividualFilterInput | null,
};

export type OnDeleteTeamIndividualSubscription = {
  onDeleteTeamIndividual?:  {
    __typename: "TeamIndividual",
    companyId: string,
    individualId: string,
    teamId: CompanyTeamTypes,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetFullCompanyQueryVariables = {
  id: string,
};

export type GetFullCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    parentId?: string | null,
    name: string,
    createdAt: string,
    deletedAt?: string | null,
    address?:  {
      __typename: "Address",
      line1?: string | null,
      line2?: string | null,
      city?: string | null,
      region?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    phoneNumber?: string | null,
    registrationNumber?: string | null,
    numberOfEmployees?: string | null,
    brandLogo?: string | null,
    sector?: string | null,
    ownershipStructure?: string | null,
    country?: string | null,
    employeeCount?: string | null,
    configuration?:  {
      __typename: "CompanyConfiguration",
      primaryColor?: string | null,
      secondaryColor?: string | null,
      questionnaire?: string | null,
      report?: string | null,
      admin?: string | null,
    } | null,
    updatedAt: string,
    tags?: Array< string > | null,
    individuals?:  {
      __typename: "ModelCompanyIndividualConnection",
      items:  Array< {
        __typename: "CompanyIndividual",
        individualId: string,
        companyId: string,
        individual?:  {
          __typename: "Individual",
          id: string,
          companyId: string,
          createdAt: string,
          preferredName?: string | null,
          firstName?: string | null,
          familyName?: string | null,
          title?: string | null,
          role?: UserType | null,
          teams?:  {
            __typename: "ModelTeamIndividualConnection",
            items:  Array< {
              __typename: "TeamIndividual",
              companyId: string,
              individualId: string,
              teamId: CompanyTeamTypes,
              createdAt: string,
            } | null >,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    committees?:  {
      __typename: "ModelCommitteeConnection",
      items:  Array< {
        __typename: "Committee",
        id: string,
        companyId: string,
        name: string,
        tags?: Array< CommitteeTag | null > | null,
        createdAt: string,
        individuals?:  {
          __typename: "ModelCommitteeIndividualConnection",
          items:  Array< {
            __typename: "CommitteeIndividual",
            individualId: string,
            companyId: string,
            createdAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type GetFullCompanyPackageQueryVariables = {
  companyId: string,
  packageId: string,
  type?: ModelPackageItemTypesInput | null,
  key?: ModelStringInput | null,
};

export type GetFullCompanyPackageQuery = {
  getCompanyPackage?:  {
    __typename: "CompanyPackage",
    companyId: string,
    packageId: string,
    inherited: boolean,
    public: boolean,
    package?:  {
      __typename: "Package",
      id: string,
      categories: Array< PackageCategories | null >,
      name: string,
      description?: string | null,
      variants: Array< string | null >,
      packageItems?:  {
        __typename: "ModelPackageItemConnection",
        items:  Array< {
          __typename: "PackageItem",
          packageId: string,
          type: PackageItemTypes,
          key: string,
          content: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetFullCompanyUserQueryVariables = {
  cognitoIdentityId: string,
  companyId: string,
};

export type GetFullCompanyUserQuery = {
  getCompanyUser?:  {
    __typename: "CompanyUser",
    cognitoIdentityId: string,
    companyId: string,
    accountType: AccountType,
    user?:  {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        items:  Array< {
          __typename: "CompanyUser",
          companyId: string,
          accountType: AccountType,
          company?:  {
            __typename: "Company",
            name: string,
            deletedAt?: string | null,
            createdAt: string,
            sector?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetFullReportQueryVariables = {
  id: string,
};

export type GetFullReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    companyId: string,
    surveyGroupId: string,
    comparisonSurveyGroupId?: string | null,
    createdAt: string,
    updatedAt: string,
    variantCode?: string | null,
    owner?: string | null,
    summary?: string | null,
    focusAreas?: string | null,
    configuration?: string | null,
    sections?:  {
      __typename: "ModelReportSectionConnection",
      items:  Array< {
        __typename: "ReportSection",
        id: string,
        companyId: string,
        reportId: string,
        sectionId: string,
        variantCode?: string | null,
        summary?: string | null,
        introduction?: string | null,
        subSectionIntroductions?:  Array< {
          __typename: "SubSectionIntroduction",
          slug?: string | null,
          value?: string | null,
          reviewedAt?: string | null,
        } | null > | null,
        results?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetFullReportGroupQueryVariables = {
  id: string,
};

export type GetFullReportGroupQuery = {
  getReportGroup?:  {
    __typename: "ReportGroup",
    id: string,
    surveyGroupId: string,
    companyId: string,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    configuration?:  {
      __typename: "ReportGroupConfiguration",
      hideComparisonResponses?: boolean | null,
      combineComparisonResponses?: boolean | null,
      reportDocumentEstimates?:  {
        __typename: "ReportDocumentEstimates",
        generateCollectiveDuration?: number | null,
        generateGenericDuration?: number | null,
        generateIndividualDuration?: number | null,
        downloadIndividualsArchiveDuration?: number | null,
      } | null,
      scores?:  {
        __typename: "ReportGroupScoresConfiguration",
        showV2Universe?: boolean | null,
        allowMultipleUniverse?: boolean | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    scores?: string | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        companyId: string,
        surveyGroupId: string,
        variantCode?: string | null,
        comparisonSurveyGroupId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type FullSurveysByOwnerQueryVariables = {
  owner: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FullSurveysByOwnerQuery = {
  surveysByOwner?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      owner: string,
      key?: string | null,
      variantCode?: string | null,
      dispatchedAt?: string | null,
      finishedAt?: string | null,
      deletedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      response?: string | null,
      status?: SurveyStatus | null,
      invitation?: string | null,
      createdAt: string,
      updatedAt: string,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        updatedAt: string,
      } | null,
      questionnaire?:  {
        __typename: "Questionnaire",
        id: string,
        createdAt: string,
        deletedAt?: string | null,
        companyId: string,
        name: string,
        status?: QuestionnaireStatus | null,
        groups?: string | null,
        customContent?: string | null,
        packageId?: string | null,
        variants?:  Array< {
          __typename: "QuestionnaireVariant",
          name: string,
          variantCode: string,
        } | null > | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFullSurveyGroupQueryVariables = {
  id: string,
};

export type GetFullSurveyGroupQuery = {
  getSurveyGroup?:  {
    __typename: "SurveyGroup",
    id: string,
    questionnaireId: string,
    companyId: string,
    packageId?: string | null,
    targetIndividualIds?: Array< string | null > | null,
    name: string,
    variants:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null >,
    status: SurveyGroupStatus,
    createdAt: string,
    updatedAt: string,
    releasedAt?: string | null,
    dispatchedAt?: string | null,
    deletedAt?: string | null,
    tags?: Array< string | null > | null,
    surveys?:  {
      __typename: "ModelSurveyConnection",
      nextToken?: string | null,
      items:  Array< {
        __typename: "Survey",
        id: string,
        groupId: string,
        companyId: string,
        questionnaireId: string,
        individualId: string,
        owner: string,
        key?: string | null,
        variantCode?: string | null,
        dispatchedAt?: string | null,
        finishedAt?: string | null,
        deletedAt?: string | null,
        totals?:  {
          __typename: "Totals",
          questions?: number | null,
          questionsCompleted?: number | null,
        } | null,
        status?: SurveyStatus | null,
        invitation?: string | null,
        createdAt: string,
        updatedAt: string,
        individual?:  {
          __typename: "Individual",
          id: string,
          companyId: string,
          firstName?: string | null,
          contactNumber?: string | null,
          preferredName?: string | null,
          familyName?: string | null,
          title?: string | null,
          role?: UserType | null,
          jobTitle?: string | null,
          accountStatus?: UserAccountStatus | null,
        } | null,
        questionnaire?:  {
          __typename: "Questionnaire",
          name: string,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type GetFullUserByCognitoIdQueryVariables = {
  cognitoId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCognitoIdentityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetFullUserByCognitoIdQuery = {
  getUserByCognitoId?:  {
    __typename: "ModelCognitoIdentityConnection",
    items:  Array< {
      __typename: "CognitoIdentity",
      id: string,
      cognitoId: string,
      userCreatedBy: string,
      createdAt: string,
      updatedAt: string,
      displayName: string,
      email: string,
      defaultLanguage: LanguageCode,
      avatar?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      companies?:  {
        __typename: "ModelCompanyUserConnection",
        items:  Array< {
          __typename: "CompanyUser",
          companyId: string,
          accountType: AccountType,
          company?:  {
            __typename: "Company",
            name: string,
            deletedAt?: string | null,
            createdAt: string,
            sector?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionnaireObjectQueryVariables = {
  questionnaireId: string,
};

export type GetQuestionnaireObjectQuery = {
  getQuestionnaire?:  {
    __typename: "Questionnaire",
    id: string,
    createdAt: string,
    companyId: string,
    name: string,
    status?: QuestionnaireStatus | null,
    groups?: string | null,
    customContent?: string | null,
    packageId?: string | null,
    editDisabled?: boolean | null,
    company?:  {
      __typename: "Company",
      name: string,
      individuals?:  {
        __typename: "ModelCompanyIndividualConnection",
        items:  Array< {
          __typename: "CompanyIndividual",
          individualId: string,
          individual?:  {
            __typename: "Individual",
            title?: string | null,
            preferredName?: string | null,
            familyName?: string | null,
            firstName?: string | null,
            role?: UserType | null,
            email: string,
            teams?:  {
              __typename: "ModelTeamIndividualConnection",
              items:  Array< {
                __typename: "TeamIndividual",
                companyId: string,
                individualId: string,
                teamId: CompanyTeamTypes,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      committees?:  {
        __typename: "ModelCommitteeConnection",
        items:  Array< {
          __typename: "Committee",
          id: string,
          name: string,
          tags?: Array< CommitteeTag | null > | null,
        } | null >,
      } | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        variantCode: string,
        questionnaireId: string,
        companyId: string,
        createdAt: string,
        name: string,
        updatedAt: string,
        slug?: string | null,
        displayOrder: number,
        blocks?:  {
          __typename: "ModelBlockConnection",
          items:  Array< {
            __typename: "Block",
            id: string,
            variantCode: string,
            sectionId: string,
            companyId: string,
            groupId?: string | null,
            variant?: BlockType | null,
            data: string,
            createdAt: string,
            updatedAt: string,
            displayOrder: number,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    variants?:  Array< {
      __typename: "QuestionnaireVariant",
      name: string,
      variantCode: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type ReportSectionBySectionQueryVariables = {
  reportId: string,
  sectionId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportSectionBySectionQuery = {
  reportSectionsBySectionId?:  {
    __typename: "ModelReportSectionConnection",
    items:  Array< {
      __typename: "ReportSection",
      id: string,
      companyId: string,
      reportId: string,
      sectionId: string,
      variantCode?: string | null,
      summary?: string | null,
      introduction?: string | null,
      subSectionIntroductions?:  Array< {
        __typename: "SubSectionIntroduction",
        slug?: string | null,
        value?: string | null,
        reviewedAt?: string | null,
      } | null > | null,
      results?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommitteesExtendedQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommitteeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommitteesExtendedQuery = {
  listCommitteesByCompanyId?:  {
    __typename: "ModelCommitteeConnection",
    items:  Array< {
      __typename: "Committee",
      id: string,
      companyId: string,
      name: string,
      tags?: Array< CommitteeTag | null > | null,
      createdAt: string,
      individuals?:  {
        __typename: "ModelCommitteeIndividualConnection",
        items:  Array< {
          __typename: "CommitteeIndividual",
          individualId: string,
          companyId: string,
          createdAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        createdAt: string,
        address?:  {
          __typename: "Address",
          line1?: string | null,
          line2?: string | null,
          city?: string | null,
          region?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFullCompanyIndividualsQueryVariables = {
  companyId: string,
  individualId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFullCompanyIndividualsQuery = {
  individualsByCompanyId?:  {
    __typename: "ModelCompanyIndividualConnection",
    items:  Array< {
      __typename: "CompanyIndividual",
      createdAt: string,
      companyId: string,
      individualId: string,
      individual?:  {
        __typename: "Individual",
        id: string,
        createdAt: string,
        email: string,
        companyId: string,
        dateOfBirth?: string | null,
        firstName?: string | null,
        gender?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        role?: UserType | null,
        jobTitle?: string | null,
        religion?: Religion | null,
        ethnicDesignation?: EthnicDesignation | null,
        joinDate?: string | null,
        accountStatus?: UserAccountStatus | null,
        appointments?:  {
          __typename: "IndividualAppointments",
          Chair: number,
          SID: number,
          NonExecutive: number,
          CommitteeChair: number,
          Other: number,
        } | null,
        updatedAt: string,
        teams?:  {
          __typename: "ModelTeamIndividualConnection",
          items:  Array< {
            __typename: "TeamIndividual",
            companyId: string,
            individualId: string,
            teamId: CompanyTeamTypes,
            createdAt: string,
          } | null >,
        } | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        parentId?: string | null,
        name: string,
        createdAt: string,
        deletedAt?: string | null,
        companyType?: CompanyType | null,
        phoneNumber?: string | null,
        registrationNumber?: string | null,
        numberOfEmployees?: string | null,
        brandLogo?: string | null,
        sector?: string | null,
        ownershipStructure?: string | null,
        country?: string | null,
        employeeCount?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFullCompanyPackagesQueryVariables = {
  companyId?: string | null,
  packageId?: ModelIDKeyConditionInput | null,
  filter?: ModelCompanyPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFullCompanyPackagesQuery = {
  listCompanyPackages?:  {
    __typename: "ModelCompanyPackageConnection",
    items:  Array< {
      __typename: "CompanyPackage",
      companyId: string,
      packageId: string,
      inherited: boolean,
      public: boolean,
      package?:  {
        __typename: "Package",
        id: string,
        categories: Array< PackageCategories | null >,
        name: string,
        description?: string | null,
        variants: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        packageItems?:  {
          __typename: "ModelPackageItemConnection",
          items:  Array< {
            __typename: "PackageItem",
            packageId: string,
            type: PackageItemTypes,
            key: string,
            content: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFullReportGroupsBySurveyGroupQueryVariables = {
  surveyGroupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFullReportGroupsBySurveyGroupQuery = {
  listReportGroupsBySurveyGroup?:  {
    __typename: "ModelReportGroupConnection",
    items:  Array< {
      __typename: "ReportGroup",
      id: string,
      surveyGroupId: string,
      companyId: string,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      configuration?:  {
        __typename: "ReportGroupConfiguration",
        hideComparisonResponses?: boolean | null,
        combineComparisonResponses?: boolean | null,
        reportDocumentEstimates?:  {
          __typename: "ReportDocumentEstimates",
          generateCollectiveDuration?: number | null,
          generateGenericDuration?: number | null,
          generateIndividualDuration?: number | null,
          downloadIndividualsArchiveDuration?: number | null,
        } | null,
        scores?:  {
          __typename: "ReportGroupScoresConfiguration",
          showV2Universe?: boolean | null,
          allowMultipleUniverse?: boolean | null,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      scores?: string | null,
      reports?:  {
        __typename: "ModelReportConnection",
        items:  Array< {
          __typename: "Report",
          id: string,
          companyId: string,
          surveyGroupId: string,
          variantCode?: string | null,
          comparisonSurveyGroupId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFullSurveyGroupsByCompanyQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFullSurveyGroupsByCompanyQuery = {
  listSurveyGroupsByCompany?:  {
    __typename: "ModelSurveyGroupConnection",
    items:  Array< {
      __typename: "SurveyGroup",
      id: string,
      questionnaireId: string,
      companyId: string,
      packageId?: string | null,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      status: SurveyGroupStatus,
      createdAt: string,
      updatedAt: string,
      releasedAt?: string | null,
      dispatchedAt?: string | null,
      deletedAt?: string | null,
      tags?: Array< string | null > | null,
      surveys?:  {
        __typename: "ModelSurveyConnection",
        items:  Array< {
          __typename: "Survey",
          id: string,
          groupId: string,
          companyId: string,
          questionnaireId: string,
          individualId: string,
          finishedAt?: string | null,
          deletedAt?: string | null,
          totals?:  {
            __typename: "Totals",
            questions?: number | null,
            questionsCompleted?: number | null,
          } | null,
          status?: SurveyStatus | null,
          invitation?: string | null,
          createdAt: string,
          updatedAt: string,
          individual?:  {
            __typename: "Individual",
            id: string,
            companyId: string,
            firstName?: string | null,
            contactNumber?: string | null,
            preferredName?: string | null,
            familyName?: string | null,
            title?: string | null,
            accountStatus?: UserAccountStatus | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFullSurveyGroupsByQuestionnaireQueryVariables = {
  questionnaireId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFullSurveyGroupsByQuestionnaireQuery = {
  listSurveyGroupsByQuestionnaire?:  {
    __typename: "ModelSurveyGroupConnection",
    items:  Array< {
      __typename: "SurveyGroup",
      id: string,
      questionnaireId: string,
      companyId: string,
      packageId?: string | null,
      targetIndividualIds?: Array< string | null > | null,
      name: string,
      variants:  Array< {
        __typename: "QuestionnaireVariant",
        name: string,
        variantCode: string,
      } | null >,
      status: SurveyGroupStatus,
      createdAt: string,
      updatedAt: string,
      releasedAt?: string | null,
      dispatchedAt?: string | null,
      deletedAt?: string | null,
      tags?: Array< string | null > | null,
      surveys?:  {
        __typename: "ModelSurveyConnection",
        nextToken?: string | null,
        items:  Array< {
          __typename: "Survey",
          id: string,
          groupId: string,
          companyId: string,
          questionnaireId: string,
          individualId: string,
          owner: string,
          key?: string | null,
          finishedAt?: string | null,
          dispatchedAt?: string | null,
          deletedAt?: string | null,
          totals?:  {
            __typename: "Totals",
            questions?: number | null,
            questionsCompleted?: number | null,
          } | null,
          status?: SurveyStatus | null,
          invitation?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getQuestionnaireSurveysQueryVariables = {
  questionnaireId: string,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type getQuestionnaireSurveysQuery = {
  surveysByQuestionnaireId?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      finishedAt?: string | null,
      deletedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      status?: SurveyStatus | null,
      invitation?: string | null,
      createdAt: string,
      updatedAt: string,
      individual?:  {
        __typename: "Individual",
        id: string,
        companyId: string,
        firstName?: string | null,
        contactNumber?: string | null,
        preferredName?: string | null,
        familyName?: string | null,
        title?: string | null,
        accountStatus?: UserAccountStatus | null,
      } | null,
      questionnaire?:  {
        __typename: "Questionnaire",
        name: string,
        deletedAt?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getSurveysByQuestionnaireIdQueryVariables = {
  questionnaireId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type getSurveysByQuestionnaireIdQuery = {
  surveysByQuestionnaireId?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      groupId: string,
      companyId: string,
      questionnaireId: string,
      individualId: string,
      owner: string,
      key?: string | null,
      finishedAt?: string | null,
      dispatchedAt?: string | null,
      totals?:  {
        __typename: "Totals",
        questions?: number | null,
        questionsCompleted?: number | null,
      } | null,
      status?: SurveyStatus | null,
      invitation?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};
