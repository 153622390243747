<template>
  <div class="survey grow min-height-0 flex-basis-0 d-flex flex-column">
    <v-container class="flex-grow-1 d-flex flex-column align-center justify-center" v-if="pendingFetchPreview">
      <platform-spinner
        class="mx-auto"
        color="primary"
        :size="50"
        :width="5"
      />
    </v-container>
    <v-container
      v-else
      class="flex-grow-1 overflow-y-auto overflow-x-hidden d-flex flex-column"
      :class="{
        'pa-6': isMobile,
        'pa-8': !isMobile
      }"
    >
      <platform-modal
        v-model="showModal"
        icon="text-box"
        :width="400"
        :title="$t('modal.survey.uncertainQuestion.title')"
        @confirm="processSkipOrUncertain(DefaultQuestionAnswers.Uncertain)"
        @cancel="showModal = false"
      >
        <p>{{ $t('modal.survey.uncertainQuestion.message') }}</p>
      </platform-modal>
      <div class="flex-grow-0 text-center pb-3">
        <v-progress-linear
          v-if="totalQuestions"
          color="primary"
          class="questions-progress"
          :height="16"
          :value="surveyProgress"
        >
          <span class="white--text">
            <template v-if="showPctProgress">
              {{ surveyProgress }}%
            </template>
            <template v-else>
              <template v-if="currentQuestionNumber !== null">{{ currentQuestionNumber }} / </template>
              {{ totalQuestions }}
            </template>
          </span>
        </v-progress-linear>
      </div>
      <div class="flex-grow-1 d-flex flex-column">
        <v-row v-if="showSubmitStep" class="grow flex-column" justify="start">
          <h2 class="text-center pt-12">
            {{ $t('page.viewSurvey.surveyCompleted') }}
            <br />
            {{ $t('page.viewSurvey.submitTip') }}
          </h2>
        </v-row>
        <survey-block
          v-else-if="activeBlock"
          :block="activeBlock"
          v-model="activeBlockAnswer"
          :is-preview="isReadOnly"
          @next="next"
          @valid="(val) => isValid = val"
        />
      </div>
    </v-container>
    <div class="shrink">
      <v-toolbar
        :height="60"
      >
        <div class="d-flex flex-row align-center justify-space-between" :style="{ width: '100%' }">
          <div>
            <platform-button
              secondary
              default-case
              :disabled="!hasPrevQuestion || !isValid"
              :force-color="buttonOverrideColor"
              @click="prev"
            >
              {{ $t('page.viewSurvey.previous') }}
            </platform-button>
          </div>
          <div v-if="!isReadOnly">
            <platform-button
              secondary
              default-case
              :force-color="buttonOverrideColor"
              :disabled="showSubmitStep || !isValid"
              @click="handleUncertainAnswer"
            >
              {{ $t('page.viewSurvey.notKnown') }}
            </platform-button>
          </div>
          <div v-if="!isReadOnly">
            <platform-button
              secondary
              default-case
              :force-color="buttonOverrideColor"
              :disabled="showSubmitStep || !isValid"
              @click="skipOrUncertainQuestion = DefaultQuestionAnswers.Skipped"
            >
              {{ $t('page.viewSurvey.skip') }}
            </platform-button>
          </div>
          <div v-if="isReadOnly" class="d-flex flex-row align-center">
            <v-col
              v-if="showPreviewPerspectiveSelector"
              class="pr-xl-12 pr-lg-8 pr-sm-12 pr-0"
              :xl="8"
              :lg="7"
              :sm="6"
              :cols="4"
            >
              <platform-autocomplete
                v-model="individualId"
                v-tooltip="$t('page.viewSurvey.preview.perspectiveTooltip')"
                class="preview-select pointer-events--auto"
                avatars
                :items="companyIndividualOptions"
                :loading="pendingFetchPreview"
                :item-avatar-size="$vuetify.breakpoint.xs ? 24 : 36"
              />
            </v-col>
            <v-col
              class="pl-sm-3 pl-0"
              :xl="4"
              :lg="6"
              :sm="6"
              :cols="8"
            >
              <platform-button
                primary
                default-case
                :xSmall="$vuetify.breakpoint.xsOnly"
                @click="endPreview"
              >
                {{ $t('page.viewSurvey.stopPreviewing') }}
              </platform-button>
            </v-col>
          </div>
          <div class="text-right">
            <platform-button
              v-if="showSubmitStep"
              :disabled="isReadOnly"
              primary
              default-case
              class="mr-3"
              @click="showConfirmSubmitModal = true"
            >
              {{ $t('page.viewSurvey.submitAction') }}
            </platform-button>
            <platform-button
              :disabled="showSubmitStep || !isValid"
              default-case
              secondary
              :force-color="buttonOverrideColor"
              @click="next"
            >
              {{ $t('page.viewSurvey.next') }}
            </platform-button>
          </div>
        </div>
      </v-toolbar>
    </div>
    <confirmation-modal
      v-if="showConfirmSubmitModal"
      entity-type="questionnaire"
      action="submit"
      :entity="questionnaire"
      :message="$t('page.viewSurvey.submitConfirmation')"
      @confirm="submit"
      @cancel="showConfirmSubmitModal = false"
    />
  </div>
</template>

<script lang="ts">
import { i18n } from '@/plugins/i18n'
import {
  SurveyBlockIndex,
  SurveyConfiguration
} from '@/types/Survey'
import API from '@/services/API'
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { Block, Questionnaire, Section, SurveyStatus } from '@/API'
import { PlatformEntityTypes, PlatformSurvey } from '@/types/Platform'
import { updateSurvey } from '@/graphql/mutations'
import { precacheImage } from '@/helpers/forms'
import SurveyBlock from '@/components/questionnaire/survey/blocks/Block.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import {
  BlockPresetResponse,
  DefaultQuestionnaireVariant
} from '@betterboards/shared/helpers/entities/Questionnaire/index'
import PlatformModal from '@/components/PlatformModal.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformQuestionnaire } from '@betterboards/shared/types/Platform'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { fetchSurveyConfiguration } from '@/helpers/packages'
import { filterTeamIndividuals } from '@/helpers/team'
import { CompanyTeamTypes } from '@betterboards/shared/types/API'
import PlatformAutocomplete, { PlatformSelectItem } from '@/components/shared/inputs/PlatformAutocomplete.vue'

export default Vue.extend({
  name: 'ViewSurvey',
  components: {
    PlatformAutocomplete,
    PlatformSpinner,
    PlatformModal,
    ConfirmationModal,
    SurveyBlock
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isSample: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    answers: [] as any[],
    showPctProgress: true,
    skipOrUncertainQuestion: <BlockPresetResponse | undefined>undefined,
    reviewing: false,
    isValid: true,
    showConfirmSubmitModal: false,
    previousLocale: <string | undefined>undefined,
    DefaultQuestionAnswers: BlockPresetResponse,
    showModal: false,
    individualId: <string>'',
    pendingFetchPreview: <boolean>false,
    previewQuestionnaire: <PlatformQuestionnaire | undefined>undefined,
    surveyConfiguration: <SurveyConfiguration | undefined>undefined
  }),
  watch: {
    skipOrUncertainQuestion (answer: BlockPresetResponse | undefined) {
      if (!answer) {
        return
      }
      setTimeout(() => {
        if (this.showModal) {
          return
        }
        this.processSkipOrUncertain(answer)
      }, 300)
    },
    async survey (val) {
      if (!val) {
        return
      }
      if (val.finishedAt) {
        this.$router.push({
          name: 'Dashboard'
        })
        return
      }
      this.setSurveyProgress(val)
      const company = await this.fetchCompanyById(this.survey.companyId)
      await this.selectCompany(company)
    },
    sectionIndex () {
      this.saveSurveyProgress()
      this.isValid = true // Reset validity on question change
      if (this.showSubmitStep) {
        this.$store.commit('Survey/setShowSubmitStep', false)
      }
    },
    blockIndex () {
      this.saveSurveyProgress()
      this.isValid = true // Reset validity on question change
      if (this.showSubmitStep) {
        this.$store.commit('Survey/setShowSubmitStep', false)
      }
    },
    activeBlock () {
      this.checkImageCache()
    },
    async individualId () {
      await this.setPreviewSurveyPerspective()
      this.setQuestionIndex({
        blockIndex: 0,
        sectionIndex: 0
      })
    }
  },
  beforeDestroy () {
    this.$store.commit('Survey/reset')
    if (this.previousLocale) {
      i18n.locale = this.previousLocale
    }
  },
  async mounted () {
    if (this.isPreview) {
      this.previewQuestionnaire = await this.$store.dispatch('Questionnaire/fetchQuestionnaireById', this.$route.params.surveyId)

      const packageId: string | null | undefined = this.previewQuestionnaire?.packageId
      if (packageId === null || packageId === undefined) {
        console.error('Preview questionnaire missing package ID')
        return
      }

      this.surveyConfiguration = await fetchSurveyConfiguration(this.selectedCompany.id, packageId)

      await this.setPreviewSurveyPerspective()
    } else if (this.isSample) {
      this.pendingFetchPreview = true

      let surveyQuestionnaire: PlatformSurvey
      try {
        surveyQuestionnaire = await API.post('backendfunctions', '/questionnaire/null/release', {
          body: {
            action: 'preview',
            variantCode: DefaultQuestionnaireVariant,
            individualId: undefined,
            sample: true,
            companyId: this.selectedCompany.id
          }
        })
      } catch (err) {
        this.$toasted.error(this.$t('page.questionnaires.release.previewError') as string)
        console.error('Failed to preview questionnaire:', { questionnaire: this.previewQuestionnaire, err })
        this.pendingFetchPreview = false
        throw err
      }

      this.$store.commit('Survey/setSurvey', {
        questionnaire: surveyQuestionnaire,
        id: surveyQuestionnaire.id,
        questionnaireId: surveyQuestionnaire.id,
        companyId: this.selectedCompany?.id
      })

      this.pendingFetchPreview = false
    } else {
      this.$store.dispatch('Survey/setup', this.$route.params.surveyId)
        .then(() => {
          if (this.survey?.variantCode) {
            this.previousLocale = i18n.locale
            i18n.locale = this.survey.variantCode
          }
        })
    }
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapState('Company', ['selectedCompany']),
    ...mapState('Questionnaire', { questionnaire: 'selected' }),
    ...mapState('Survey', [
      'survey',
      'sectionIndex',
      'blockIndex'
    ]),
    ...mapState('Individual', { companyIndividuals: 'list' }),
    isMobile (): boolean {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isReadOnly (): boolean {
      return this.isPreview || this.isSample
    },
    questionnaire (): Questionnaire | null {
      if (!this.survey) {
        return null
      }

      return this.survey.questionnaire
    },
    sections (): Section[] {
      if (!this.questionnaire?.sections?.items) {
        return []
      }

      return this.questionnaire.sections.items.filter(section => !!section) as Section[]
    },
    totalQuestions (): number {
      return this.sections.reduce(
        (numQuestions, section) => numQuestions + (section.blocks?.items?.length ?? 0),
        0
      )
    },
    currentQuestionNumber (): number {
      let questionNumber = 1

      for (let s = 0; s < this.sectionIndex; s++) {
        questionNumber += this.sections[s].blocks?.items?.length ?? 0
      }

      return questionNumber + this.blockIndex
    },
    showSubmitStep (): boolean {
      return this.$store.state.Survey.showSubmitStep
    },
    activeSection (): Section {
      return this.sections[this.sectionIndex]
    },
    activeSectionBlocks (): Block[] {
      if (!this.activeSection) {
        return []
      }

      return this.activeSection.blocks?.items?.filter(block => !!block) as Block[] ?? []
    },
    activeBlock (): Block {
      return this.activeSectionBlocks[this.blockIndex]
    },
    prevQuestionIndex (): SurveyBlockIndex | undefined {
      if (this.activeSectionBlocks[this.blockIndex - 1]) {
        // Previous Question is in current Section
        return {
          sectionIndex: this.sectionIndex,
          blockIndex: this.blockIndex - 1
        }
      }
      // Return index to the last Block in the closest previous Section that has any Blocks
      return this.sections.reduceRight(
        (prevBlockIndex: SurveyBlockIndex | undefined, section, sectionIndex) => {
          const sectionBlockCount: number | undefined = section?.blocks?.items?.length
          if (prevBlockIndex || sectionIndex >= this.sectionIndex || !sectionBlockCount) {
            return prevBlockIndex
          }
          return {
            sectionIndex,
            blockIndex: sectionBlockCount - 1
          }
        },
        undefined
      )
    },
    nextQuestionIndex (): SurveyBlockIndex | undefined {
      if (this.activeSectionBlocks[this.blockIndex + 1]) {
        // Next Question is in current Section
        return {
          sectionIndex: this.sectionIndex,
          blockIndex: this.blockIndex + 1
        }
      }
      // Return index to the first Block in the closest next Section that has any Blocks
      return this.sections.reduce(
        (nextBlockIndex: SurveyBlockIndex | undefined, section, sectionIndex) => {
          if (nextBlockIndex || sectionIndex <= this.sectionIndex || !section?.blocks?.items?.length) {
            return nextBlockIndex
          }
          return {
            sectionIndex,
            blockIndex: 0
          }
        },
        undefined
      )
    },
    hasPrevQuestion (): boolean {
      return this.prevQuestionIndex !== undefined || this.showSubmitStep
    },
    hasNextQuestion (): boolean {
      return this.nextQuestionIndex !== undefined
    },
    surveyProgress (): number {
      if (this.showSubmitStep || (this.currentQuestionNumber === this.totalQuestions)) {
        return 100
      }
      return Math.floor(100 * this.currentQuestionNumber / this.totalQuestions)
    },
    questionnaireConfig (): any {
      if (!this.selectedCompany?.configuration?.questionnaire) {
        return
      }
      return typeof this.selectedCompany.configuration.questionnaire === 'string'
        ? JSON.parse(this.selectedCompany.configuration.questionnaire)
        : this.selectedCompany.configuration.questionnaire
    },
    buttonOverrideColor (): string | undefined {
      return this.questionnaireConfig?.actions?.buttonColor
    },
    activeBlockAnswer: {
      get (): any | null {
        if (!this.activeSection) {
          return null
        }
        const answer = this.answers.find((answer) => answer.sectionId === this.activeSection.id && answer.blockId === this.activeBlock.id)
        return answer?.value ?? null
      },
      set (value: any): void {
        const answerIndex = this.answers.findIndex((answer) => answer.sectionId === this.activeSection.id && answer.blockId === this.activeBlock.id)
        if (answerIndex === -1) {
          this.answers.push({
            sectionId: this.activeSection.id,
            blockId: this.activeBlock.id,
            value
          })
          return
        }
        this.answers[answerIndex].value = value
      }
    },
    requiresUndecidedConfirmation (): boolean {
      return !this.activeBlockAnswer ||
        this.activeBlockAnswer === this.DefaultQuestionAnswers.Uncertain ||
        this.activeBlockAnswer === this.DefaultQuestionAnswers.Skipped
    },
    companyIndividualOptions (): PlatformSelectItem[] | undefined {
      if (!this.companyIndividuals) {
        console.warn('No individuals to view from perspective')
        return undefined
      }

      const targetTeams: CompanyTeamTypes[] | undefined = this.surveyConfiguration?.targetTeams
      let filteredIndividuals = this.companyIndividuals
      if (targetTeams) {
        filteredIndividuals = filterTeamIndividuals(this.companyIndividuals, targetTeams)
      }

      return [
        {
          text: 'General',
          value: ''
        },
        ...filteredIndividuals.map((individual: PlatformIndividual): PlatformSelectItem => ({
          text: individual.preferredName,
          subText: this.$t(`shared.individual.roles.${individual.role as string}`) as string,
          value: individual.id,
          entityType: PlatformEntityTypes.Individual,
          avatarEntity: individual
        }))
      ]
    },
    showPreviewPerspectiveSelector () : boolean {
      return this.companyIndividuals.length && !this.isSample
    }
  },
  methods: {
    ...mapMutations('Survey', ['setQuestionIndex']),
    ...mapActions('Questionnaire', ['selectQuestionnaire']),
    ...mapActions('Company', ['clearSelectedCompany', 'selectCompany', 'fetchCompanyById']),
    saveSurveyProgress () {
      if (this.isReadOnly) {
        return
      }
      this.$store.dispatch('Survey/updateSurvey', {
        id: this.survey.id,
        response: this.answers.length ? JSON.stringify(this.answers) : undefined,
        totals: {
          ...this.survey.totals,
          questionsCompleted: this.currentQuestionNumber
        }
      })
        .catch((err) => {
          console.error('Caught an error while trying to submit survey:', err)
          this.$toasted.error('There was an error trying to submit your survey. Please try again or contact support.')
        })
    },
    setSurveyProgress (survey: PlatformSurvey) {
      if (this.isPreview) {
        return
      }

      const {
        totals,
        response
      } = survey

      if (response?.length) {
        this.answers.splice(0, this.answers.length)
        this.answers.push(...response)
      }

      let currentBlockIndex = 0
      let blockIndex = 0
      let sectionIndex = 0

      if (totals.questionsCompleted || !this.sections[sectionIndex].blocks?.items.length) {
        for (let s = 0; s <= this.sections.length; s++) {
          const section = this.sections[s]
          if (!section?.blocks?.items?.length) {
            continue
          }
          if (section.blocks.items.length) {
            sectionIndex = s
            if (totals.questionsCompleted && currentBlockIndex + section.blocks.items.length >= totals.questionsCompleted) {
              blockIndex = totals.questionsCompleted - currentBlockIndex - 1
              break
            }
          }
          currentBlockIndex += section.blocks.items.length
        }
      }

      if (!sectionIndex && !blockIndex) {
        return
      }
      this.setQuestionIndex({
        blockIndex,
        sectionIndex
      })
    },
    prev () {
      if (this.showSubmitStep) {
        this.$store.commit('Survey/setShowSubmitStep', false)
        return
      }
      this.setQuestionIndex(this.prevQuestionIndex)
    },
    next (): void {
      if (!this.hasNextQuestion) {
        this.saveSurveyProgress()
        this.$store.commit('Survey/setShowSubmitStep', true)
        return
      }
      this.setQuestionIndex(this.nextQuestionIndex)
    },
    async submit () {
      if (this.isPreview) {
        return
      }
      try {
        await API.graphql({
          query: updateSurvey,
          variables: {
            input: {
              id: this.survey.id,
              finishedAt: (new Date().toISOString()),
              status: SurveyStatus.FINISHED,
              response: JSON.stringify(this.answers)
            }
          }
        })
      } catch (err) {
        console.error('Caught an error while trying to submit survey:', err)
        this.$toasted.error('There was an error trying to submit your survey. Please try again or contact support.')
        return
      }
      this.$toasted.success(this.$t('page.viewSurvey.submitted') as string)
      this.$router.push({
        name: 'Dashboard'
      })
    },
    endPreview () {
      if (this.isSample) {
        this.$router.push({
          name: 'Dashboard'
        })
        return
      }
      this.$router.push({
        name: 'QuestionnaireEdit',
        params: {
          id: this.questionnaire?.id as string
        }
      })
    },
    checkImageCache () {
      /**
       * Attempt to pre-cache images for all company BMs
       *  (no checks required as precacheImage ignores if the image is already cached)
       */
      const individuals = this.survey?.questionnaire?.company?.individuals?.items
      if (Array.isArray(individuals) && this.survey?.companyId) {
        individuals.forEach((individual) => {
          precacheImage('userAvatar', individual.individualId, this.survey.companyId)
        })
      }
    },
    handleUncertainAnswer (): void {
      if (this.requiresUndecidedConfirmation) {
        this.skipOrUncertainQuestion = this.DefaultQuestionAnswers.Uncertain
      } else {
        this.showModal = true
      }
    },
    processSkipOrUncertain (answer: BlockPresetResponse | undefined): void {
      if (!this.activeBlockAnswer || answer === BlockPresetResponse.Uncertain) {
        this.activeBlockAnswer = answer
      }
      this.showModal = false
      this.next()

      this.$nextTick(() => {
        this.skipOrUncertainQuestion = undefined
      })
    },
    async setPreviewSurveyPerspective (): Promise<void> {
      if (!this.previewQuestionnaire) {
        this.$toasted.error(this.$t('page.questionnaires.release.previewError') as string) // update error message
        console.warn('Questionnaire not set, failed to preview.') // update error message
        return
      }

      this.pendingFetchPreview = true
      let surveyQuestionnaire
      try {
        surveyQuestionnaire = await API.post('backendfunctions', `/questionnaire/${this.previewQuestionnaire.id}/release`, {
          body: {
            action: 'preview',
            variantCode: this.previewQuestionnaire.variants[0].variantCode,
            individualId: this.individualId !== '' ? this.individualId : undefined
          }
        })
      } catch (err) {
        this.$toasted.error(this.$t('page.questionnaires.release.previewError') as string)
        console.error('Failed to preview questionnaire:', { questionnaire: this.previewQuestionnaire, err })
        this.pendingFetchPreview = false
        throw err
      }

      this.$store.commit('Survey/setSurvey', {
        questionnaire: surveyQuestionnaire,
        id: surveyQuestionnaire.id,
        questionnaireId: surveyQuestionnaire.id,
        companyId: this.selectedCompany?.id
      })

      this.pendingFetchPreview = false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '/src/scss/mixins';

.questions-progress {
  $progress-bar-height: 16px;

  max-width: 800px;
  margin: 0 auto;
  border-radius: $progress-bar-height / 2;
  font-size: $progress-bar-height * 0.8;
  line-height: $progress-bar-height;
}

.preview-select::v-deep {
  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-input__append-inner {
    align-self: center;
  }
}

.preview-select {
  @media #{map-get($display-breakpoints, 'xl-only')} {
    width: 400px;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    width: 350px;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    width: 200px;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    width: 125px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 65px;
  }
}
</style>
